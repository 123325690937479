import React, { Component } from 'react';
//Material UI Components
import { Typography, Avatar } from '@mui/material';

import './ReceivingChatMessage.scss';

class ReceivingChatMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    formattedTime(timestamp) {
        return new Date(timestamp).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
    }

    render() {
        return (
            <div className="receivingChatMessage-container">
                <Avatar className="receivingChatMessage-chat-image" alt="avatar" src={process.env.REACT_APP_IMAGE_URL + this.props.driver.imagePath} />
                <div className="receivingChatMessage-speech-bubble">
                    <Typography variant="body1" className="receivingChatMessage-TextField r2r-typography-bold">{this.props.driver.firstName} {this.props.driver.lastName}</Typography>
                    <Typography variant="body1" className="receivingChatMessage-TextField">{this.props.item.message}</Typography>
                    <div className="receivingChatMessage-time-date-box">
                        <Typography className="" variant="body2">{this.formattedTime(this.props.item.timestamp)} Uhr</Typography>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReceivingChatMessage;
