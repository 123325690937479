import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GpsFixed, } from '@mui/icons-material/';
import { Radio, RadioGroup, FormControlLabel, Button, Typography } from '@mui/material/';
import withStyles from '@mui/styles/withStyles';
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { checkRequiredFields } from '../../util/validation_helper.js';
import { CustomAutocomplete, CustomAppBar, CustomGrid, CustomDateTimePicker, LoadingTransparentBlocked } from '../index';
import { reverseGeocode } from "../../util/googleApi_helper.js";
import './SearchRide.scss';
import { getNextRides, getReceivedRatings } from '../../API/REST/index.js';
import MovingOffer from './MovingOffer.jsx';
import { formattedDate } from '../../util/formatting_helper.js';

const styles = theme => ({
    multilineColor: {
        color: 'white'
    },
    cssLabel: {
        color: 'white'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'white !important',
    },
});

var ridesOldTimestamp = 0;
class SearchRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPosition: "",
            checkedTime: "departure",
            startPoint: {
                address: ""
            },
            endPoint: {
                address: ""
            },
            placeDeparture: {},
            placeArrival: {},
            departureArrivalTimestamp: new Date().getTime() + 10000,
            selectedDateOutward: new Date().getTime() + 600000,
            errors: {
                startAddress: false,
                endAddress: false,
                selectedTime: false
            },
            currentLocation: {
                departure: {
                    disableGetPosition: false
                },
                arrival: {
                    disableGetPosition: false
                }
            },
            rides: []
        }
        this.handleNavigateToShowResults = this.handleNavigateToShowResults.bind(this)
    }

    componentDidMount() {
        this.props.setBottomNavState(1)
        this.handleFillElement();
        this.getData()
    }


    async calculate(rides) {
        try {
            let data = Promise.all(rides.map(async ride => {
                ride.rating = await this.getRatings(ride.userId._id)
                return ride
            }))
            return data
        } catch (error) {
            console.error(error);
            // this.setState({ rides: [] })
        }
    }

    async getRatings(userid) {
        try {
            const data = await getReceivedRatings(userid)
            switch (data.response.code) {
                case 200:
                    if (data.result.data.length === 0) {
                        return 0
                    } else {
                        var totalRating = 0
                        data.result.data.forEach(rating => {
                            totalRating = totalRating + rating.stars
                        })
                        return (totalRating / data.result.data.length).toFixed(2)
                    }
                default:
                    return 0
            }

        } catch (error) {
            console.error(error);
            // this.setState({ rides: [] })
        }
    }

    getData = async () => {
        try {
            const data = await getNextRides(this.props.userid)
            switch (data.response.code) {
                case 200:
                    if (data.result.data === null) {
                        this.setState({
                            rides: [],
                            loading: false
                        });
                    } else {
                        let r = await this.calculate(data.result.data)
                        this.setState({
                            rides: r,
                            loading: false
                        });
                    }
                    break;

                default:
                    this.setState({ rides: [] })
                    break;
            }
        } catch (error) {
            console.error(error);
            this.setState({ rides: [] })
        }
    }

    handleFillElement() {
        if (this.props.match.params.departureFullAdress !== "0") {
            this.setState({
                startPoint: {
                    ...this.state.startPoint,
                    address: this.props.match.params.departureFullAdress
                },
                endPoint: {
                    ...this.state.startPoint,
                    address: this.props.match.params.arrivalFullAdress
                },
                selectedDateOutward: new Date(parseInt(this.props.match.params.departureArrivalTimestamp)),
            });
        }
    }

    handleDateChange = date => {
        try {
            this.setState({
                selectedDateOutward: date,
                departureArrivalTimestamp: date.getTime()
            });
        } catch (error) {
            console.error(error)
            this.setState({
                selectedDateOutward: new Date().getTime(),
                departureArrivalTimestamp: new Date().getTime(),
                errors: {
                    ...this.state.errors,
                    selectedTime: true
                }
            })
        }

    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleNavigateToShowResults = (e) => {

        let validationObject = [
            {
                type: "STRING",
                value: this.state.startPoint.address,
                errorIndex: 0
            },
            {
                type: "STRING",
                value: this.state.endPoint.address,
                errorIndex: 1
            },
            {
                type: "TIMESTAMP",
                value: new Date(this.state.selectedDateOutward).getTime(),
                errorIndex: 2,
            }]
        checkRequiredFields(validationObject)
            .then((result) => {
                if (result.errorAvailable === false) {
                    this.props.history.push(
                        '/showResults/departureLat=' + this.state.startPoint.addressLat +
                        '&departureLng=' + this.state.startPoint.addressLng +
                        '&departureStreet=' + this.state.placeDeparture.street +
                        '&departureHouseNumber=' + this.state.placeDeparture.houseNumber +
                        '&departurePostalCode=' + this.state.placeDeparture.postalCode +
                        '&departureCity=' + this.state.placeDeparture.city +
                        '&arrivalLat=' + this.state.endPoint.addressLat +
                        '&arrivalLng=' + this.state.endPoint.addressLng +
                        '&arrivalStreet=' + this.state.placeArrival.street +
                        '&arrivalHouseNumber=' + this.state.placeArrival.houseNumber +
                        '&arrivalPostalCode=' + this.state.placeArrival.postalCode +
                        '&arrivalCity=' + this.state.placeArrival.city +
                        '&departurearrival=' + this.state.checkedTime +
                        '&departureArrivalTimestamp=' + this.state.departureArrivalTimestamp +
                        '&departureFullAdress=' + this.state.placeDeparture.formattedAddress +
                        '&arrivalFullAdress=' + this.state.placeArrival.formattedAddress
                    );
                } else {
                    e.preventDefault()
                    this.setState({
                        errors: {
                            startAddress: result.errorArray[0],
                            endAddress: result.errorArray[1],
                            selectedTime: result.errorArray[2]
                        }
                    })
                };
            })
    }

    handleRadioTimeChange = event => {
        this.setState({
            checkedTime: event.target.value
        })

    };

    handleChangeStart = address => {
        this.setState({
            startPoint: {
                ...this.state.startPoint,
                address: address
            }
        });
    };

    handleSelectStart = (address) => {

        geocodeByAddress(address.description)
            .then(data => getLatLng(data[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(
                    data => {
                        this.setState({
                            startPoint: {
                                ...this.state.startPoint,
                                address: address.description,
                                addressLat: latLng.lat,
                                addressLng: latLng.lng,
                            },
                            placeDeparture: {
                                ...this.state.placeDeparture,
                                formattedAddress: data[5],
                                city: data[2],
                                postalCode: data[4],
                                houseNumber: data[0],
                                street: data[1],
                                country: data[3],
                                state: "",
                            }
                        })
                    }
                )
            }).catch(error => console.error('Error', error));
    };

    handleLocateUserStart = (latLng, data) => {

        this.setState({
            startPoint: {
                ...this.state.startPoint,
                address: data[5],
                addressLat: latLng.lat,
                addressLng: latLng.lng,
            },
            placeDeparture: {
                ...this.state.placeDeparture,
                formattedAddress: data[5],
                city: data[2],
                postalCode: data[4],
                houseNumber: data[0],
                street: data[1],
                country: data[3],
                state: "",
            }
        })
        // reverseGeocode(latlng.lat, latlng.lng).then(data => {

        // this.handleSelectStart({ description: data[5] })
        // })
    }

    handleLocateUserEnd = (latlng) => {
        reverseGeocode(latlng.lat, latlng.lng).then(data => {
            this.handleSelectEnd({ description: data[5] })
        })
    }

    handleChangeEnd = addressEnd => {
        this.setState({
            endPoint: {
                ...this.state.startPoint,
                address: addressEnd
            }
        });
    };

    handleSelectEnd = address => {
        geocodeByAddress(address.description)
            .then(data => getLatLng(data[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(
                    data => {
                        this.setState({
                            endPoint: {
                                ...this.state.endPoint,
                                address: address.description,
                                addressLat: latLng.lat,
                                addressLng: latLng.lng,
                            },
                            placeArrival: {
                                ...this.state.placeArrival,
                                formattedAddress: data[5],
                                city: data[2],
                                postalCode: data[4],
                                houseNumber: data[0],
                                street: data[1],
                                country: data[3],
                                state: "",
                            }
                        })
                    }
                )
            }).catch(error => console.error('Error', error));
    };

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Fahrt suchen">
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    /**
  * 
  * @param {*} date 
  * @param {*} index 
  */
    checkDateRides(date, index) {
        if (index === 0) {
            ridesOldTimestamp = 0
        }
        var currentDate = new Date(date);
        var lastDate = new Date(ridesOldTimestamp);

        if (currentDate.getDate() === lastDate.getDate() && currentDate.getMonth() === lastDate.getMonth()) {
            ridesOldTimestamp = date;
            return false
        } else {
            ridesOldTimestamp = date;
            return true

        }
    }

    handleDateLabel(date) {

        var today = new Date();
        var currentDate = new Date(date);
        if (today.getDate() === currentDate.getDate() && today.getMonth() === currentDate.getMonth()) {
            return "Heute"
        } else {
            return formattedDate(date)
        }
    }

    render() {
        const { classes } = this.props;

        const renderRideCards = (ride, index) => {
            // this.state.rides.map((rideObject, key) => (
            const filteredRides = this.state.rides

            if (filteredRides.length > 0) {
                return filteredRides.map((ride, index) => {
                    if (this.checkDateRides(ride.departureTimestamp, index)) {
                        return (
                            <React.Fragment key={index}>
                                <Typography className="r2r-content-title" variant="h6" >{this.handleDateLabel(ride.departureTimestamp)}</Typography>
                                <MovingOffer className="showResults-card-spacing" selectedRide={ride} />
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <MovingOffer className="showResults-card-spacing" selectedRide={ride} />
                            </React.Fragment>
                        )
                    }
                })

            }
            return (<div className="r2r-display-flex showResults-align-vertical">
                <Typography variant="h6">
                    Leider wurden keine passenden Fahrten gefunden.
                </Typography>
            </div>)

        }

        const renderSearchRide = () => {
            return (
                <React.Fragment>
                    {/* <div className="r2r-container-header r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column"> */}
                    <div className=" r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column">
                        <CustomGrid className="r2r-container-header-margin">
                            <div className=" r2r-container-content-flex-column" >

                                <CustomAutocomplete
                                    id="startpunkt"
                                    className=""
                                    handleSelect={this.handleSelectStart}
                                    address={this.state.startPoint.address}
                                    label="Startpunkt"
                                    icon={<GpsFixed />}
                                    placeholder="Wähle einen Startpunkt!"
                                    required={true}
                                    error={this.state.errors.startAddress}
                                    locateUser={true}
                                    handleLocateUser={this.handleLocateUserStart}
                                    disableGetPosition={this.state.currentLocation.departure.disableGetPosition} />
                                <CustomAutocomplete
                                    id="endpunkt"
                                    className=""
                                    handleSelect={this.handleSelectEnd}
                                    address={this.state.endPoint.address}
                                    label="Endpunkt"
                                    icon={<GpsFixed />}
                                    placeholder="Wähle einen Endpunkt!"
                                    required={true}
                                    error={this.state.errors.endAddress}
                                    locateUser={false}
                                    handleLocateUser={this.handleLocateUserStart}
                                    disableGetPosition={this.state.currentLocation.departure.disableGetPosition} />

                                <div className="r2r-container-content-flex-column">
                                    <CustomDateTimePicker
                                        InputProps={{
                                            classes: {
                                                input: classes.multilineColor,
                                                notchedOutline: classes.notchedOutline,
                                            }
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel,
                                            },
                                        }}
                                        value={this.state.selectedDateOutward}
                                        onChange={() => this.handleDateChange}
                                        error={this.state.errors.selectedTime} />
                                    <div className="searchRide-radioGroup r2r-container-center-align">
                                        <RadioGroup className="r2r-container-center-align searchRide-radioLabel"
                                            value={this.state.checkedTime}
                                            defaultValue="departure"
                                            onChange={this.handleRadioTimeChange}
                                            row>
                                            <FormControlLabel
                                                value="departure"
                                                label="Abfahrt"
                                                labelPlacement="start"
                                                control={<Radio color="secondary" />} />
                                            <FormControlLabel
                                                value="arrival"
                                                label="Ankunft"
                                                labelPlacement="start"
                                                control={<Radio color="secondary" />} />
                                        </RadioGroup>
                                    </div>

                                </div>

                                <Button className="app-btn-secondary" onClick={this.handleNavigateToShowResults} >
                                    Suchen
                                </Button>

                            </div>
                        </CustomGrid>
                    </div >
                    <div className="" >
                        <CustomGrid>
                            <Typography variant='h6' style={{ textAlign: "center" }}>Die folgenden Fahrten finden in kürze statt</Typography>
                            {this.state.loading ?
                                <LoadingTransparentBlocked /> :
                                <React.Fragment>
                                    {this.state.rides.length > 0 ?
                                        renderRideCards() :
                                        <div className="r2r-display-flex showResults-align-vertical">
                                            <Typography variant="h6">
                                                Leider wurden keine passenden Fahrten gefunden.
                                            </Typography>
                                        </div>}
                                </React.Fragment>}

                        </CustomGrid>
                    </div>
                </React.Fragment>

            );
        }
        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {renderSearchRide()}
            </React.Fragment >
        );

    }
}

const mapStateToProps = (state) => {
    return {
        userid: state.user._id
    };
};
export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(withStyles(styles)(SearchRide));