import React from 'react'
import PropTypes from 'prop-types'
import SectionTypography from '../Global/sectionTypography/sectionTypography'
import { Typography } from '@mui/material'
import './VehicleSelectorComp.scss'

function VehicleSelectoreComp(props) {

    return (
        <div data-testid="vehicleSelectoreComp-div" className="r2r-contentContainer-element-margin">
            <SectionTypography> Mit welchem deiner Fahrzeuge willst du fahren?</SectionTypography>
            <div className="r2r-container-content-flex-row vehicleSelectorComp-horizontalContainer r2r-justify-center r2r-pointer">
                {props.vehicles.map((vehicle, key) => (
                    <div key={key} onClick={() => props.changeVehicle(vehicle._id)} className={vehicle._id === props.selectedVehicleId ? "r2r-container-content-flex-column vehicleSelectorComp-vehicleContainer r2r-container-center-align vehicleSelectorComp-carSelected" : "r2r-container-content-flex-column vehicleSelectorComp-vehicleContainer r2r-container-center-align"}>
                        <img className="vehicleSelectorComp-vehicleImage" alt="vehicle" src={vehicle.vehicleImageId ? process.env.REACT_APP_IMAGE_URL + vehicle.vehicleImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE} />
                        <div className="vehicleSelectorComp-vehicleTitel">
                            <Typography style={{ height: "auto", width: 50 }} className="vehicleSelectorComp-vehicleTitel " variant="subtitle2">
                                {vehicle.vehicleBrand + " " + vehicle.vehicleModel}
                            </Typography>
                        </div>
                    </div>))}
            </div>
        </div>
    )
}

VehicleSelectoreComp.propTypes = {
    vehicles: PropTypes.array.isRequired,
    changeVehicle: PropTypes.func.isRequired,
    selectedVehicleId: PropTypes.string.isRequired
}

export default VehicleSelectoreComp