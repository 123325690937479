import React from 'react';
import { connect } from 'react-redux'
import { Typography, Avatar, IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material/';
import { putStatusByUserId } from '../../API/REST/index'
import { REQUEST_ACCEPTED, REQUEST_REJECTED, sendNotification } from '../../util/notification_helper';
import PropTypes from 'prop-types'
import './Attendee.scss';

class Attendee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: this.props.attendee.status,
        }
    }

    /**
     * Mehhod triggers the API to change the status
     * @param {("ACCEPT"|"DENIED")} status - new status for this attendee
     */
    async putChangeStatus(status) {
        try {
            const data = await putStatusByUserId(this.props.rideId, this.props.attendee.user._id, status)
            switch (data.response.code) {
                case 200:
                    if (status === "ACCEPT") {
                        sendNotification([this.props.attendee], REQUEST_ACCEPTED, { rideid: this.props.rideId });
                    } else {
                        sendNotification([this.props.attendee], REQUEST_REJECTED, { rideid: this.props.rideId });
                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Changes the status of the attendee
     * @param {("ACCEPT"|"DENIED")} status - new status for this attendee
     */
    changeStateStatus = (status) => {
        this.setState({ status: status })
        this.putChangeStatus(status)
    }

    render() {
        return (
            <div className="r2r-container-content-flex-row attendee-space-between">
                <div className="r2r-display-flex attendee-flex-outer">
                    <div className="attendee-flex-pic">
                        <Avatar className="attendee-flex-pic" alt="avatar" src={this.props.attendee.user.profilImageId ? process.env.REACT_APP_IMAGE_URL + this.props.attendee.user.profilImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />
                    </div>
                    <Typography variant="subtitle2" className="r2r-typography-bold">{this.props.attendee.user.firstName} {this.props.attendee.user.lastName} </Typography>
                </div>

                {this.props.driverIsMe ?
                    <div>
                        <IconButton onClick={() => this.changeStateStatus("ACCEPT")} disabled={(this.props.ridestatus === "COMPLETED") || (this.props.departuretimestamp < new Date().getTime())} data-testid="test-button" size="large">
                            <Check color={this.state.status === "ACCEPT" ? "secondary" : "primary"} />
                        </IconButton>
                        <IconButton onClick={() => this.changeStateStatus("DENIED")} disabled={(this.props.ridestatus === "COMPLETED") || (this.props.departuretimestamp < new Date().getTime())} data-testid="test-button" size="large">
                            <Close color={this.state.status === "DENIED" ? "secondary" : "primary"} />
                        </IconButton>

                    </div> : null
                }
            </div>
        );
    }
}

Attendee.propTypes = {
    ridestatus: PropTypes.string.isRequired,
    attendee: PropTypes.object.isRequired,
    driverIsMe: PropTypes.bool.isRequired,
    rideId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
    return {
        keycloak: {
            token: state.keycloak.token
        },
        user: state.user
    };
};

export default connect(mapStateToProps, null)(Attendee);
