import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "feedback"


export async function sendFeedback(rating, comment, additionalInformation) {
    try {
        let data = {
            rating: rating,
            comment: comment,
            additional: additionalInformation
        }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }

}