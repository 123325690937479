import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Avatar, ListItemButton } from '@mui/material';
import { Commute, DirectionsCar } from '@mui/icons-material/';
import './NextRideElement.scss';

class NextRideElement extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <ListItemButton onClick={this.props.handleShowRide}>
                <div className="r2r-display-flex nextRideElement">
                    <div className="nextRideElement-flex-pic">
                        <Avatar className="" alt="avatar" src={process.env.REACT_APP_IMAGE_URL + this.props.imagePath} />
                    </div>
                    <div className="nextRideElement-width">
                        <div className="r2r-display-flex r2r-justify-spaceBetween nextRideElement-header-row">
                            {this.props.ownRide ?
                                <React.Fragment>
                                    <Typography id="title" data-testid="test-title" variant="subtitle1" className="r2r-typography-bold">Deine Fahrt</Typography>
                                    <DirectionsCar />
                                </React.Fragment> :
                                <React.Fragment>
                                    <Typography id="title" data-testid="test-title" variant="subtitle1" className="r2r-typography-bold">Bei {this.props.firstName} mitfahren</Typography>
                                    <Commute />
                                </React.Fragment>}
                        </div>
                        {/* <Divider light={true} /> */}
                        <div className="r2r-margin-top-bottom">
                            <Typography id="text-departure" data-testid="test-departure" variant="body1"><strong>Von:</strong> {this.props.departure}</Typography>
                            <Typography id="text-arrival" data-testid="test-arrival" variant="body1"><strong>Nach:</strong>  {this.props.arrival}</Typography>
                            {/* <Divider light={true} /> */}
                        </div>
                        <React.Fragment>
                            <Typography id="text-time" data-testid="test-time" variant="body2"><strong>Datum und Uhrzeit:</strong>  {new Date(this.props.departureTimestamp).toLocaleString('DE-de', { year: '2-digit', month: '2-digit', day: '2-digit' })} um {new Date(this.props.departureTimestamp).toLocaleString('DE-de', { hour: '2-digit', minute: '2-digit' })} Uhr</Typography>
                        </React.Fragment>
                    </div>
                </div>
            </ListItemButton>

        );
    }
}


NextRideElement.propTypes = {
    handleShowRide: PropTypes.func.isRequired,
    imagePath: PropTypes.string.isRequired,
    ownRide: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    departureTimestamp: PropTypes.number.isRequired
}

NextRideElement.defaultProps = {
    firstName: "",
}


export default NextRideElement;
