import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Avatar, Card, CardContent, Typography } from '@mui/material/';
import { StarRate, People, Train, SpaOutlined } from '@mui/icons-material/';
import { formattedTime } from '../../util/formatting_helper'

import './MovingOffer.scss';

class MovingOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isÖPVN: false,
            isExpanded: false,
        }
        this.handle = this.handle.bind(this)
    }

    componentDidMount() {

    }

    /**
     * Method handles the state of param "name" of the component
     * @param {String} name 
     */
    handleChange = name => event => {
        this.setValues({ [name]: event.target.value });
    };

    /**
     * Method handles the expanded state of the component
     */
    handleExpansion() {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    /**
     * Method initialize the redirection to the ride view
     * @param {String} rideId 
     */
    handle(rideId) {
        this.setState({
            selectedRide: rideId,
            redirect: true
        })
    }

    /**
     * Method calculates the average rating of the user
     */
    calculateRating() {
        if (this.props.selectedRide.userId.receivedRatings.length === 0) {
            return 0
        } else {
            var totalRating = 0
            for (var i = 0; i < this.props.selectedRide.userId.receivedRatings.length; i++) {
                totalRating = totalRating + this.props.selectedRide.userId.receivedRatings[i].stars
            }
            return Number((totalRating / this.props.selectedRide.userId.receivedRatings.length).toFixed(1))
        }
    }

    /**
     * Method counts the number of attendees that are accepted
     */
    checkParticipant() {
        var amountAttendee = 0
        for (var i = 0; i < this.props.selectedRide.attendee.length; i++) {
            if (this.props.selectedRide.attendee[i].status === "ACCEPT") {
                amountAttendee = amountAttendee + 1
            }
        }
        return amountAttendee
    }

    render() {
        if (this.state.redirect === true) {
            return (
                <Redirect to={{
                    pathname: '/ridedetails/rideId=' + this.props.selectedRide._id,
                    state: { rideId: this.props.selectedRide._id }
                }} />)
        }

        return (
            <Card className="movingOffer-card" onClick={() => this.handle(this.props.selectedRide._id)}>
                <CardContent>
                    <div className="">
                        {this.state.isÖPVN === false ?
                            <div className="r2r-display-flex movingOffer-firstLine">
                                <div className="r2r-display-flex">
                                    <div className="movingOffer-icon">
                                        <Avatar className="movingOffer-avatar" alt="avatar" src={this.props.selectedRide.userId.profilImageId ? process.env.REACT_APP_IMAGE_URL + this.props.selectedRide.userId.profilImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />
                                    </div>
                                    <div className="r2r-display-flex movingOffer-name">
                                        <Typography variant="subtitle1" className="r2r-typography-bold movingOffer-name-content">{this.props.selectedRide.userId.firstName + " " + this.props.selectedRide.userId.lastName} </Typography>
                                    </div>
                                </div>
                                <div className="r2r-display-flex">
                                    <div className="r2r-display-flex movingOffer-margin-laterally">
                                        {/* <StarRate color="secondary" /> <Typography variant="subtitle2" className="r2r-typography-bold">{this.calculateRating() + "/5"}</Typography> */}
                                        <StarRate color="secondary" /> <Typography variant="subtitle2" className="r2r-typography-bold">{this.props.selectedRide.rating + "/5"}</Typography>
                                    </div>
                                    <div className="r2r-display-flex mmovingOffer-margin-laterally">
                                        <People color="secondary" /> <Typography variant="subtitle2" className="r2r-typography-bold">{this.checkParticipant()}</Typography>
                                    </div>
                                    <div className="r2r-display-flex movingOffer-margin-laterally">
                                        <SpaOutlined className="movingOffer-savedEmission-icon" /> <Typography variant="subtitle2" className="r2r-typography-bold">{this.props.selectedRide.routeDetails.co2emissions}</Typography>
                                    </div>
                                </div>
                            </div>
                            : <div className="r2r-display-flex movingOffer-firstLine">
                                <div className="r2r-display-flex">
                                    <div className="movingOffer-icon">
                                        <Avatar className="movingOffer-avatar" alt="avatar" src="" ><Train /></Avatar>
                                    </div>
                                    <div className="r2r-display-flex movingOffer-name">
                                        Linie S5
                                    </div>
                                </div>
                            </div>}
                    </div>

                    <div className="r2r-movingOffer-flex-content">
                        <React.Fragment>
                            <div className="movingOffer-expansionPanelOuter"
                                aria-controls="panel1a-content"
                                id="panel1a-header" >
                                <div className="userRide-flex-ride-info r2r-display-flex">
                                    <div ref={this.myDiv}>

                                    </div>
                                    <div className="r2r-display-flex movingOffer-flex-ride-info-left">
                                        <Typography variant="body2" >{formattedTime(this.props.selectedRide.departureTimestamp)} Uhr </Typography>
                                    </div>

                                    <div id="svg_container" className="r2r-display-flex movingOffer-flex-ride-info-mid">
                                        <svg width="30px" height="100%" >
                                            <line x1="50%" y1="15%" x2="50%" y2="100%" stroke="black" strokeWidth="2" />
                                            <circle cx="50%" cy="15%" r="5" stroke="black" strokeWidth="2" fill="white" />
                                        </svg>
                                    </div>

                                    <div className="r2r-display-flex movingOffer-flex-ride-info-right">
                                        <div className="movingOffer-flex-ride-info-right-street">
                                            {/*this.props.selectedRide.startLocationStreet*/}
                                            <Typography id="movingOffer-departurePlace" data-testid="test-departurePlace" variant="body2" className="r2r-typography-bold">
                                                {this.props.selectedRide.placeDeparture.street} {this.props.selectedRide.placeDeparture.houseNumber === 0 ? null : this.props.selectedRide.placeDeparture.houseNumber}
                                                <br />
                                                {this.props.selectedRide.placeDeparture.postalCode + " " + this.props.selectedRide.placeDeparture.city}
                                            </Typography>
                                        </div>
                                        <div className="movingOffer-flex-ride-info-right-city">
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </React.Fragment>

                        {/* Ankunftsuhrzeit und Abfahrtsort anzeigen */}
                        <div className="userRide-flex-ride-info r2r-display-flex" onClick={() => this.handle(this.props.selectedRide._id)}>
                            <div className="r2r-display-flex movingOffer-flex-ride-info-left">
                                <Typography variant="body2" className="r2r-typography-bold">{formattedTime(this.props.selectedRide.arrivalTimestamp)} Uhr</Typography>
                            </div>

                            <div className="r2r-display-flex movingOffer-flex-ride-info-mid">
                                <svg width="30px" height="100%">
                                    <line x1="50%" y1="15%" x2="50%" y2="0%" stroke="black" strokeWidth="2" />
                                    <circle cx="50%" cy="15%" r="5" stroke="black" strokeWidth="2" fill="black" />
                                </svg>
                            </div>

                            <div className="r2r-display-flex movingOffer-flex-ride-info-right">
                                <div className="movingOffer-flex-ride-info-right-street">
                                    {/*this.props.selectedRide.endLocationStreet*/}
                                    <Typography id="movingOffer-arrivalPlace" data-testid="test-arrivalPlace" variant="body2" className="r2r-typography-bold">
                                        {this.props.selectedRide.placeArrival.street} {this.props.selectedRide.placeArrival.houseNumber === 0 ? null : this.props.selectedRide.placeArrival.houseNumber}
                                        <br />
                                        {this.props.selectedRide.placeArrival.postalCode + " " + this.props.selectedRide.placeArrival.city}
                                    </Typography>
                                </div>
                                <div className="movingOffer-flex-ride-info-right-city">
                                </div>
                            </div>

                        </div>
                    </div>

                </CardContent>
            </Card>
        );
    }
}

export default MovingOffer;
