import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

/**
 *  Method requests the API to get all informations about a chat 
 * @param {string} chatId - id or a chat
 * @returns {Object} result of the API request
 */
export async function getChatInfos(chatId) {
    try {

        let response = await _axios.get(process.env.REACT_APP_REST_URL + "chats/chatById/?id=" + chatId)

        return response.data
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method requests the API to get the chat id for a user of a ride
 * @param {*} rideId - id of a a ride
 * @param {*} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getChatId(rideId, userId) {
    try {
        const data = {
            rideId: rideId,
            userId: userId
        }

        let response = await _axios.post(process.env.REACT_APP_REST_URL + "chats/chatForRideView/", JSON.stringify(data))
        return response.data
    } catch (error) {
        console.error(error)
    }
}