/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Grid, DialogActions, Button, DialogContent, DialogTitle, Dialog, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Build, Menu } from '@mui/icons-material';
// eslint-disable-next-line no-unused-vars
import { EnvironmentalPanel, NextRidesPanel, ParkingSpotPanel, NotificationOverview, ScoreingPanel, LoadingTransparentBlocked, CustomGrid, CustomAppBar, PageAvatarHeader } from '../index.js'
import { setBottomNavState } from '../../Actions/Index.js'
import { udpateCustomizing } from '../../API/REST/index.js'
import './Dashboard.scss';
import { messages } from '../../util/toastMessages.js';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dialogOpen: false,
            panels: {
                EnvironmentalPanel: <EnvironmentalPanel />,
                ScoreingPanel: <ScoreingPanel />,
                NextRidesPanel: <NextRidesPanel />,
                //ParkingSpotPanel: <ParkingSpotPanel />
            }
        }
    }


    componentDidMount() {
        this.props.setBottomNavState(0)
        this.assignComponents()
        // this.getCurrentUser()
    }

    async assignComponents() {
        const componentStore = {
            EnvironmentalPanel: <EnvironmentalPanel />,
            ScoreingPanel: <ScoreingPanel />,
            NextRidesPanel: <NextRidesPanel />,
            //ParkingSpotPanel: <ParkingSpotPanel />
        }

        let resultObject = {}
        if (this.props.user.customizing.dashboardPanelArrangement.length > 0) {
            this.props.user.customizing.dashboardPanelArrangement.map(panel => {
                if (componentStore[panel]) {
                    resultObject[panel] = componentStore[panel]
                }
            })
            this.setState({
                panels: resultObject,
            })
        }
        this.setState({
            loading: false
        })
    }

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Dashboard">
                <div className="r2r-container-flex-grow" />
                {this.renderNotificationOverview()}
                <IconButton
                    color="inherit"
                    sx={{ display: { xs: 'none', sm: "inline-flex" } }}
                    onClick={() => this.setState({ dialogOpen: true })}
                    size="large">
                    <Build />
                </IconButton>
            </CustomAppBar>
        );
    }

    /**
     * Method return the "NotificationOverview" component
     */
    renderNotificationOverview() {
        const { history } = this.props;
        return (
            <NotificationOverview history={history} />
            // <NotificationOverview />
        )
    }

    renderPanels() {
        const components = Object.values(this.state.panels)
        return components.map((component, key) => {
            return (
                <Grid key={key} item xs={12} md={6} >
                    {component}
                </Grid>
            )
        })
    }

    /**
     * 
     * @param {string} name - Name of a state as a string value
     */
    handleClose = name => event => {
        this.setState({
            [name]: false
        })
    }

    replacePanelKey = (keyName) => {
        switch (keyName) {
            case "EnvironmentalPanel":
                return "Umweltbericht"
            case "ScoreingPanel":
                return "Leaderboard"
            case "NextRidesPanel":
                return "Anstehende Fahrten"
            case "ParkingSpotPanel":
                return "Parkplatzanzahl"
            default:
                return ""
        }
    }

    renderSortablePanels() {
        const panelKeys = Object.keys(this.state.panels)
        return panelKeys.map((element, key) => {
            return (
                <ListItem key={key} component={"div"} button id={element} className="draggable" onDragStart={(event) => this.onDragStart(event)} onDragEnd={(event) => this.onDragEnd(event)} draggable >
                    <ListItemIcon>
                        <Menu />
                    </ListItemIcon>
                    <ListItemText >{this.replacePanelKey(element)}</ListItemText >
                </ListItem>
            )
        })
    }

    changePanelArrangement = async () => {
        const panelsBeforeArrange = Object.entries(this.state.panels)
        let panelIds = []
        let arrangedObject = {}
        const panelNodes = document.querySelectorAll(".draggable")
        try {

            panelNodes.forEach(panelNode => {
                panelIds.push(panelNode.id)
            })

            panelIds.map((id) => {
                panelsBeforeArrange.map((panel) => {
                    if (panel[0] === id) {
                        arrangedObject[panel[0]] = panel[1]
                        return;
                    }
                })

            })
            const data = await udpateCustomizing(this.props.user._id, panelIds)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        panels: arrangedObject,
                        dialogOpen: false
                    })
                    break;
                default:
                    this.setState({
                        panels: panelsBeforeArrange,
                        dialogOpen: false
                    })
                    break;
            }

        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            console.error(error)
            this.setState({
                panels: panelsBeforeArrange,
                dialogOpen: false
            })
        }

    }

    onDragOver = (event) => {
        event.preventDefault()
        const container = document.querySelector(".container")
        const afterElement = this.getDragAfterElement(container, event.clientY)
        const draggable = document.querySelector(".dragging")

        if (afterElement === null) {
            container.appendChild(draggable)
        } else {
            container.insertBefore(draggable, afterElement)
        }
    }

    onDragStart = (event) => {
        const element = document.getElementById(event.target.id)
        element.classList.add("dragging")
    }

    onDragEnd = (event) => {
        event.preventDefault()
        const element = document.getElementById(event.target.id)
        element.classList.remove("dragging")
    }

    getDragAfterElement(container, y) {
        const draggableElements = [...container.querySelectorAll('.draggable:not(.dragging)')]

        return draggableElements.reduce((closest, child) => {
            const box = child.getBoundingClientRect()
            const offset = y - box.top - box.height / 2
            if (offset < 0 && offset > closest.offset) {
                return { offset: offset, element: child }
            } else {
                return closest
            }
        }, { offset: Number.NEGATIVE_INFINITY }).element
    }

    renderSortingDialog() {
        return (
            <Dialog className="dashboard-dialog"
                //  anchorEl={this.state.dialogOpen}
                open={this.state.dialogOpen} onClose={this.handleClose('dialogOpen')} aria-labelledby="simple-dialog-title" >
                <DialogTitle >Dashboard anpassen</DialogTitle>
                <DialogContent dividers>
                    <List component={"div"} className="dashboard-list container" onDragOver={(event) => this.onDragOver(event)}>
                        {this.renderSortablePanels()}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleClose('dialogOpen')}>
                        Abbrechen
                    </Button>
                    <Button color="primary" onClick={this.changePanelArrangement}>
                        Übernehmen
                    </Button>
                </DialogActions>
            </Dialog >
        )
    }

    render() {



        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {this.renderSortingDialog()}
                {this.state.loading ? <LoadingTransparentBlocked /> : null}
                <React.Fragment>
                    <PageAvatarHeader nameTypography={true} />
                    <CustomGrid>
                        <div className="r2r-container-content" data-testid="dashboard-container" >

                            <Grid container spacing={0}>
                                {this.renderPanels()}
                            </Grid>

                        </div>
                    </CustomGrid>
                </React.Fragment >

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id,
            profilImageId: state.user.profilImageId,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            customizing: state.user.customizing
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState })(Dashboard);