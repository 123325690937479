import React, { Component } from 'react';

//Material UI Components
import { Typography, Avatar } from '@mui/material';

import './SendingChatMessage.scss';

class SendingChatMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {}

            ;
    }

    componentDidMount() { }

    formattedTime(timestamp) {

        return new Date(timestamp).toLocaleTimeString('de-DE', {
            hour: '2-digit', minute: '2-digit'
        }

        )
    }

    render() {

        return (<div className="sendingChatMessage-box"> <div className="sendingChatMessage-container"> <div className="sendingChatMessage-speech-bubble"> <Typography variant="body1" className="sendingChatMessage-TextField r2r-typography-bold"> {
            this.props.member.firstName
        }

            {
                this.props.member.lastName
            }

        </Typography> <Typography variant="body1" className="sendingChatMessage-TextField"> {
            this.props.item.message
        }

            </Typography> <div className="sendingChatMessage-time-date-box"> <Typography className="" variant="body2"> {
                this.formattedTime(this.props.item.timestamp)
            }

                Uhr</Typography> </div> </div> <Avatar className="sendingChatMessage-chat-image" alt="avatar" src={
                    process.env.REACT_APP_IMAGE_URL + this.props.member.imagePath
                }

            /> </div> </div>)
    }
}

export default SendingChatMessage;