import React from "react";
import { Grid, Typography } from "@mui/material/";

const Unauthorized = () => {

    return (
        < Grid data-testid="unauthorized-grid" className="error404_container" container >
            <Grid className="error404_description" item xs={12} sm={12}>
                <Typography variant="h5" align="center">Oops! Diese Seite kann leider nicht angezeigt werden.</Typography>
                < Typography className="error404_body" variant="body1" align="center" >Es sieht so aus als wären Sie nicht berechtigt hier zu fahren. Bitte
                    melden Sie sich zuerst an.</Typography >
            </Grid >
            {/* <Grid className="error404_image" item xs={12} sm={6}>
                   <img src={image_404} alt="this is a dead_end" /> 
            </Grid> */}
        </Grid >
    );
}

export default (Unauthorized);
