import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material/';
import { VehicleCard, AddVehicleDialog, LoadingTransparentBlocked, CustomAppBar, CustomGrid, SectionTypography } from '../index';
import { getVehiclesFromUser, deleteVehicle, updateFavoriteVehicle, checkVehicleUsage } from '../../API/REST/index'
import { setBottomNavState, setAppBarState, setSnackbar } from '../../Actions/Index.js'
import './VehicleOverview.scss';
import { Redirect } from 'react-router-dom';
import { messages } from '../../util/toastMessages';

class VehicleOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicles: [],
            loading: false,
            loadingProgress: false,
            dialogOpen: false,
            vehicleDialogOptions: {},
            redirect: false
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(4)
        this.requestData()
    }

    async requestData() {
        await this.pullVehicle(this.props.user._id);

    }

    /**
     * Depending of the option the method set a secific state to open a individual dialog
     * @param {string} option - string number to check the condition path. The value must be one of the following values [ADD|EDIT]
     * @param {string} id - id of a vehicle object
     */
    handleVehicleDialogOpen = (option, id) => {
        if (option === "ADD") {
            this.setState({
                dialogOpen: true,
                vehicleDialogOptions: { executeButtonText: "speichern", toolbarText: "Fahrzeug anlegen" }
            });
        } else {
            const vehicle = this.checkVehicleById(id)
            this.setState({
                dialogOpen: true,
                vehicleDialogOptions: { executeButtonText: "ändern", toolbarText: "Fahrzeug ändern", vehicle: vehicle }
            });
        }
    }

    /**
     * Method requests all vehicles of a user by the userId and set it to the state
     * @param {string} userId - id of a user as a string
     */
    async pullVehicle(userId) {
        try {
            this.setState({
                loading: true
            })

            const data = await getVehiclesFromUser(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        vehicles: data.result.data,
                        loading: false,
                    });
                    break;
                default:
                    this.setState({
                        vehicles: [],
                        loading: false,
                    });
                    break;
            }
        } catch (error) {
            console.error(error)
            this.setState({
                vehicles: [],
                loading: false,
            });
        }
    }



    deleteVehicle = async (vehicleId) => {
        try {
            const data = await checkVehicleUsage(vehicleId)
            switch (data.response.code) {
                case 200:
                    if (data.result.data.length === 0) {
                        this.setState({ loadingProgress: true })
                        const data = await deleteVehicle(vehicleId)
                        switch (data.response.code) {
                            case 200:
                                this.setState({ loadingProgress: false })
                                this.handleVehicleClose();
                                this.props.setSnackbar("Fahrzeug erfolgreich gelöscht", true, "success")
                                this.pullVehicle(this.props.user._id);
                                break;
                            default:
                                this.setState({ loadingProgress: false })
                                this.handleVehicleClose();
                                this.props.setSnackbar(messages.error, true, "error")
                                console.error(data.result)
                                break;
                        }
                        this.handleVehicleClose();
                        this.pullVehicle(this.props.user._id);
                    } else {
                        this.props.setSnackbar("Das Fahrzeug wird in mindestens einem Fahrtangebot verwendet. Ändere die Fahrten und versuche es dann erneut.", true, "error")
                    }

                    break;
                default:
                    this.setState({ loadingProgress: false })
                    this.handleVehicleClose();
                    this.props.setSnackbar(messages.error, true, "error")
                    console.error(data.result)
                    break;
            }

        } catch (error) {
            console.error(error)
            this.props.setSnackbar(messages.error, true, "error")
        }
    }

    async putFavoriteVehicle(vehicleId, newValue) {
        try {
            const data = await updateFavoriteVehicle(vehicleId, newValue)
            switch (data.response.code) {
                case 200:
                    this.setState({ loadingProgress: false })
                    var vehicles = [...this.state.vehicles];
                    const index = vehicles.findIndex(vehicle => vehicle._id === vehicleId);
                    vehicles[index].favorite = newValue;
                    this.setState({ vehicles: vehicles });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    handleOnChangeFavorite = (id) => {
        this.state.vehicles.forEach(vehicle => {
            if (vehicle._id === id) {
                this.putFavoriteVehicle(vehicle._id, true);
            } else {
                this.putFavoriteVehicle(vehicle._id, false);
            }
        })
    }

    checkVehicleById(id) {
        return this.state.vehicles.find(vehicle => vehicle._id === id)
    }

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Meine Fahrzeuge" backIconEnabled={true} backHandler={() => { this.setState({ redirect: true }) }}>
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    handleVehicleClose = (pullVehicles) => {
        if (pullVehicles) {
            this.pullVehicle(this.props.user._id)
        }
        this.setState({ dialogOpen: false })
    }

    filterDialog = () => {
        if (this.state.dialogOpen) {
            return (<AddVehicleDialog open={this.state.dialogOpen} handleDialogClose={this.handleVehicleClose} options={this.state.vehicleDialogOptions} />)
        }
    }

    render() {
        const renderVehicleOverview = () => {
            return (
                <CustomGrid className={this.state.vehicles.length === 0 ? "r2r-display-flex" : null}>
                    <div className="r2r-container-content vehicleOverview-container-content">
                        {this.state.loadingProgress ? <LoadingTransparentBlocked /> : null}
                        <div className="app-fab">
                            <Fab color="secondary" aria-label="Filter" onClick={() => this.handleVehicleDialogOpen("ADD", "")}>
                                <Add />
                            </Fab>
                        </div>
                        {this.filterDialog()}
                        {this.state.vehicles.length > 0 ?
                            <React.Fragment>
                                <SectionTypography>Fahrzeugübersicht</SectionTypography>
                                {this.state.vehicles.map((item, key) => (
                                    <VehicleCard key={key} functional={true} vehicle={item} deleteVehicle={this.deleteVehicle} handleFilterDialogOpen={this.handleVehicleDialogOpen} handleFavoriteChange={this.handleOnChangeFavorite} />
                                ))}
                            </React.Fragment> :
                            <SectionTypography className="r2r-text-center">  Du hast noch kein Fahrzeug angelegt.</SectionTypography>}
                    </div >
                </CustomGrid >
            );
        }


        if (this.state.redirect === true) {
            return (<Redirect to={{
                pathname: "profil",
            }} />)
        }

        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {this.state.loading ?
                    <LoadingTransparentBlocked /> :
                    (renderVehicleOverview())
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keycloak: {
            token: state.keycloak.token
        },
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setAppBarState, setBottomNavState, setSnackbar })(VehicleOverview);