import React, { Component } from "react";
import { connect } from 'react-redux'
import { Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, Button } from "@mui/material";
import { LoadingTransparentBlocked } from '../Global/Loading'
import { updateProtectionGuidelines } from "../../API/REST/User/userRequests.js"
import { setDataProtection, setSnackbar } from './../../Actions/Index.js'
import "./DsgvoDialog.scss";
import KeycloakService from "../../util/KeycloakService";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DsgvoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogOpen: true,
            acceptDsgvo: false,
            disagreeOpen: false
        };
    }

    handleChange = () => event => {
        this.setState({
            acceptDsgvo: event.target.checked
        });
    };

    checkSend = () => {
        if (this.state.acceptDsgvo) {
            this.handleSend(true)
        } else {
            this.setState({
                disagreeOpen: true
            })
        }
    }

    logout = () => {
        KeycloakService.doLogout()
    }

    handleSend = async (acceptDsgvo) => {
        try {
            const data = await updateProtectionGuidelines(this.props.user._id, acceptDsgvo)
            switch (data.response.code) {
                case 200:
                    if (acceptDsgvo) {
                        this.setState({
                            disagreeOpen: false,
                            dialogOpen: false
                        })
                        setDataProtection(true)
                    } else {
                        this.logout()
                    }

                    break;

                default:
                    this.props.setSnackbar("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                    break;
            }
        }
        catch (error) {
            console.error(error)
        }

    }

    handleClose = name => event => {
        this.setState({
            [name]: false
        })
    }

    renderDisagreeDsgvo = () => {
        return (
            <Dialog

                open={this.state.disagreeOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose('disagreeOpen')}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description" >
                <DialogTitle id="alert-dialog-slide-title">Datenschutzerklärung ablehnen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Sind Sie sicher, dass Sie die Datenschutzrichtlinien nicht akzeptieren möchten? Sie können die Anwendung nicht weiter verwenden.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.handleClose('disagreeOpen')}>
                        Abbrechen
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => this.handleSend(false)}>
                        Abschicken
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleRedirect = (to) => {

    }

    render() {
        // const { dialogOpen } = this.state;

        if (this.state.loading) {
            return (
                <LoadingTransparentBlocked />
            );
        } else {
            return (
                <Dialog
                    data-testid="dsgvo-dialog"
                    // anchorEl={dialogOpen}
                    open={this.state.dialogOpen}
                    onClose={this.handleClose('dialogOpen')}
                    aria-labelledby="simple-dialog-title" >
                    {this.renderDisagreeDsgvo()}
                    <DialogTitle id="simple-dialog-title">Datenschutzerklärung</DialogTitle>
                    <DialogContent>

                        <div className="r2r-display-flex dsgvoDialog-content">
                            <React.Fragment>
                                Damit Sie die Anwendung verwenden können, müssen Sie die Datenschutzrichtlinien der Hochschule Pforzheim bestätigen. <br />
                                Sollten Sie damit nicht einverstanden sein, können Sie die Anwendung nicht weiter verwenden und Ihr Profil mit allen hinterlegten Daten wird unwiederruflich gelöscht.
                            </React.Fragment>
                            <br />
                            <div className="r2r-display-flex dsgvoDialog-checkbox">
                                <div>
                                    <Checkbox onChange={this.handleChange()} value={this.state.acceptDsgvo} />
                                </div>
                                <div>
                                    {/*  eslint-disable-next-line react/jsx-no-target-blank */}
                                    Hiermit bestätigen ich, die <a href={"https://www.hs-pforzheim.de/kontakt/datenschutzerklaerung"} target="_blank"> Datenschutzerklärung </a> der Hochschule Pforzheim gelesen zu haben und das ich damit einverstanden bin.
                                </div>
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => this.checkSend(true)}>
                            Abschicken
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setDataProtection, setSnackbar })(DsgvoDialog);
