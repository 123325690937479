import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomAlertDialog(props) {
    return (
        <Dialog data-testid={props.dataTestId}
            //  anchorEl={props.anchorEl}
            TransitionComponent={Transition} open={props.open} onClose={props.onClose} aria-labelledby="alert-dialog">
            <DialogTitle data-testid="customAlertDialog-title" id="alert-dialog">{props.dialogTitle}</DialogTitle>
            <DialogContent data-testid="customAlertDialog-content" >

                {props.dialogContent}
            </DialogContent>
            <DialogActions >
                {props.dialogActions}

            </DialogActions>
        </Dialog>
    )
}

CustomAlertDialog.propTypes = {
    dialogTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CustomAlertDialog
