import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "issue"


export async function sendBugToJira(summary, bugProposal) {
    try {

        let data = {
            //rating: rating,
            summary: summary,
            bugProposal: bugProposal,
            //picture: picture,
            //browser_info: browser_info
        }

        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}


