/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { connect } from 'react-redux'

import { IconButton, Button, TextField, Dialog, DialogActions, Checkbox, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Rating } from '@mui/material';
import { RateReview } from "@mui/icons-material/";
import { sendFeedback } from "../../API/REST/index";
// eslint-disable-next-line no-unused-vars
import get_browser_info from "../../util/browser_helper";
import "./AppRating.scss"

function AppRating(props) {
    const [rating, setRating] = useState(3);
    const [feedback, setFeedback] = useState("");
    const [anonym, setAnonym] = useState(false);
    const [open, setOpen] = useState(false);
    const [openError] = useState(false);
    const [error] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setRating(3);
        //setSummary("");
        setAnonym(false);
        setFeedback("");
    };

    const prepareConclusion = () => {
        if (!anonym) {
            return ("")
        } else {
            return (
                "Name: \t" + props.user.firstName + " " + props.user.lastName + "\n" +
                "E-Mail: \t" + props.user.email + "\n"
            )
        }
    }

    const sendFeedbackToDB = () => {
        sendFeedback(rating, feedback, prepareConclusion());
        setOpen(false);
        setRating(3);
        //setSummary("");
        setAnonym(false);
        setFeedback("");
    };

    if (error !== "") {
        return (
            <React.Fragment>
                <Dialog
                    open={openError}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-feedback">
                    <DialogTitle id="alert-dialog-title">
                        {"Etwas ist schiefgelaufen..."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-feedback">
                            {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <IconButton
                sx={{ display: { xs: "none", sm: "inline-flex" } }}
                data-testid="apprating-button"
                color="inherit"
                onClick={handleClickOpen}
                size="large">
                <RateReview />
            </IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" >
                    Feedback senden
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText data-testid="apprating-dialogContent">
                        Ihnen gefällt die App? Wir freuen uns über eine Bewertung und ein kurzes Feedback von Ihnen.
                    </DialogContentText>
                    <div className={"appRating-starBox"} >
                        <Rating
                            name="simple-controlled"
                            value={rating}
                            size="large"
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                        />
                    </div>
                    <TextField
                        margin="dense"
                        id="feedback"
                        label="Ihr Feedback"
                        type="text"
                        fullWidth
                        value={feedback}
                        onChange={e => setFeedback(e.target.value)} />
                    <div className="appRating-checkbox">
                        <div >
                            <Checkbox
                                checked={anonym}
                                onChange={e => setAnonym(!anonym)}
                                value="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </div>
                        <div>E-Mail Adresse für Rückfragen sowie den Namen mit übermitteln?</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Abbrechen</Button>
                    <Button onClick={sendFeedbackToDB} color="primary">Absenden</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: {
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            email: state.user.email
        }
    };
};

export default connect(mapStateToProps, null)(AppRating);
