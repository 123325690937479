import React, { Component } from 'react';
import { Grid } from '@mui/material';

export default class CustomGrid extends Component {
    render() {
        const { children, className, ...rest } = this.props
        return (
            <Grid data-testid="customGrid-grid" {...rest} style={{ flexGrow: 1 }} container spacing={0}  >
                <Grid item xs={false} md={1} />
                <Grid className={className} item xs={12} md={10}>
                    {children}
                </Grid>
                <Grid item xs={false} md={1} />
            </Grid>
        )
    }
}
