import Keycloak from "keycloak-js";
import keycloakJson from '../config/keycloak.json';

// const _kc = new Keycloak(process.env.REACT_APP_NODE_ENV === "production" ? keycloakProd : keycloakDev);
const _kc = new Keycloak(keycloakJson);

const initKeycloak = (onAuthenticatedCB) => {
    _kc.init({ onLoad: "login-required", checkLoginIframe: false }).then(async authenticated => {
        onAuthenticatedCB()
    }).catch(error => {
        console.error("Failed to initialize", error);
        doLogin()
    });
}

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserEmail = () => _kc.tokenParsed?.email;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const KeycloakService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getUserEmail,
    getFirstName,
    getLastName,
    getToken,
    updateToken,
    getUsername,
    hasRole,
};

export default KeycloakService;