const userReducer = (state = {}, action) => {
    switch (action.type) {
        case "SETUSER":
            state = action.payload
            return state;
        case "SETDATAPROTECTION":
            state.dsgvoAccept = action.payload
            return state;
        default:
            return state;

    }
}

export default userReducer;