import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import SectionTypography from '../Global/sectionTypography/sectionTypography'

function RideDescriptionComp(props) {
    return (
        <div data-testid="ridedescriptioncomp-div" className="r2r-contentContainer-element-margin">
            <div className="r2r-container-content-flex-column ">
                <SectionTypography>Möchtest du deinen Mitfahrern etwas mitteilen?</SectionTypography>
                <div className="r2r-container-content-flex-row createRide-horizontalContainer">
                    <TextField
                        id="standard-multiline-flexible"
                        label="Beschreibung (optional)"
                        style={{ width: "100%" }}
                        multiline
                        margin="dense"
                        maxRows="4"
                        value={props.description}
                        onChange={props.onChange}
                    // className={classes.textField} 
                    />
                </div>
            </div>
        </div>
    )
}

RideDescriptionComp.propTypes = {
    onChange: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired
}

export default RideDescriptionComp