import React from 'react'
import PropTypes from 'prop-types'
import SectionTypography from '../Global/sectionTypography/sectionTypography'
import { ArrowLeft, ArrowRight, GroupAdd } from '@mui/icons-material'
import { Typography } from '@mui/material'

function OpenSeats(props) {
    return (
        <div data-testid="openseats-div" className="r2r-contentContainer-element-margin">
            <SectionTypography>Wie viele Personen nimmst du mit?</SectionTypography>
            <div className="createRide-container-content-flex-row-center">
                <GroupAdd style={{ height: 84, width: 84 }} color="secondary" />
                <ArrowLeft
                    className='r2r-pointer'
                    style={{ height: 48, width: 48 }}
                    onClick={props.onDecrement}
                    color="primary">
                    {" "}
                </ArrowLeft>
                <Typography variant="h4">{" "}{props.openSeats}{" "}
                </Typography>
                <ArrowRight
                    className='r2r-pointer'
                    style={{ height: 48, width: 48 }}
                    onClick={props.onIncrement}
                    color="primary">
                    {" "}
                </ArrowRight>
            </div>
        </div>
    )
}

OpenSeats.propTypes = {
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    openSeats: PropTypes.number.isRequired
}


export default OpenSeats