import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { ArrowRight, ArrowLeft, Cached, GpsFixed, LocationOn } from '@mui/icons-material/';
import { Typography, Switch, FormControlLabel, Button, Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CustomAutocomplete } from '../index'
import { LoadingTransparentBlocked } from '../Global/Loading'
import { getRideDetails, getVehiclesFromUser, putChangedRide } from '../../API/REST/index'
import { EDIT_RIDE, sendNotification } from '../../util/notification_helper';
import { checkRequiredFields } from '../../util/validation_helper.js'
import { connect } from 'react-redux'
import { reverseGeocode } from "../../util/googleApi_helper.js";
import { setBottomNavState, setAppBarState, setSnackbar } from '../../Actions/Index.js'
import SectionTypography from '../Global/sectionTypography/sectionTypography';
import VehicleSelectoreComp from '../CreateRide/VehicleSelectorComp';
import RideDescriptionComp from '../CreateRide/RideDescriptionComp';
import OpenSeats from '../CreateRide/OpenSeats';
import AdditionalRideInformation from '../CreateRide/AdditionalRideInformation';
import CustomAppBar from '../Global/CustomAppBar';
import './EditRide.scss';
import CustomDateTimePicker from '../Global/CustomDateTimePicker';

const styles = theme => ({
    multilineColor: {
        color: 'white'
    },

    cssLabel: {
        color: 'white'
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'white !important'
    },
});

class EditRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            rideId: this.props.match.params.rideId,
            userId: this.props.user._id,

            loading: true,
            user: this.props.user,
            vehicle: [],
            selectedVehicle: "",
            vehicleAvailable: true,
            checkedRoutine: false,
            checkedReturn: false,

            routineNumber: 1,
            startPoint: {
                address: ""
            },
            endPoint: {
                address: ""
            },

            ride: {
                vehicleSpace: 1,
                placeDeparture: {},
                placeArrival: {},
                departureLocation: {},
                arrivalLocation: {},
                routeDetails: {},
                filter: {
                    smoker: false,
                    allergy: false,
                    rideType: "",
                },
                status: "",
                vehicleId: "",
                rideDescription: "",
                attendee: [],
                departureTimestamp: "",
                arrivalTimestamp: "",
            },
            weeklyRepetitions: null,

            repeats: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },


            selectedDateOutward: "",
            selectedDateReturn: new Date(),
            amountRepeatRoutine: 1,
            disabled: true,
            errors: {
                startPlaces: false,
                endPlaces: false,
                vehicle: false,
                departureTime: false,
                dateReturn: false
            },
            currentLocation: {
                departure: {
                    disableGetPosition: false
                },
                arrival: {
                    disableGetPosition: false
                }
            }
        };
    }

    componentDidMount() {
        this.props.setBottomNavState(2)
        this.getVehicles(this.state.userId)
        this.getRideDetails(this.state.rideId)
    }

    async getVehicles(userID) {
        try {
            const data = await getVehiclesFromUser(userID)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        vehicle: data.result.data,

                    });
                    break;
                default:
                    this.setState({
                        vehicle: [],

                    });
                    break;
            }
        } catch (error) {
            console.error(error)
            this.setState({
                vehicle: [],

            });
        }
    }

    async getRideDetails(rideID) {
        try {
            const data = await getRideDetails(rideID)
            switch (data.response.code) {
                case 200:
                    if (data.result.attend === null) {
                        this.setState({
                            loading: false,
                        });
                    } else {
                        this.setState({
                            selectedDateOutward: new Date(data.result.data.departureTimestamp),
                            selectedDateReturn: new Date(data.result.data.departureTimestamp).getTime() + 600000,
                            ride: {
                                ...this.state.ride,
                                ...data.result.data,
                                vehicleId: data.result.data.vehicleId,
                                rideDescription: data.result.data.rideDescription,
                                status: "START",
                                userId: data.result.data.userId._id,
                                filter: data.result.data.filter,
                            },

                            startPoint: {
                                ...this.state.startPoint,
                                address: data.result.data.placeDeparture.formattedAddress,
                                addressLat: data.result.data.departureLocation.coordinates[1],
                                addressLng: data.result.data.departureLocation.coordinates[0]
                            },
                            endPoint: {
                                ...this.state.endPoint,
                                address: data.result.data.placeArrival.formattedAddress,
                                addressLat: data.result.data.arrivalLocation.coordinates[1],
                                addressLng: data.result.data.arrivalLocation.coordinates[0]
                            },

                            selectedVehicle: data.result.data.vehicleId._id,
                            loading: false,
                        })

                    }
                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Change repeats
    handleOnChangeDay = name => event => {
        if (this.state.repeats[name] === true) {
            this.setState({
                repeats: {
                    ...this.state.repeats,
                    [name]: false
                }
            })
        } else {
            this.setState({
                repeats: {
                    ...this.state.repeats,
                    [name]: true
                }
            })
        }
    };

    handleReturnOnChangeRoutine = () => {
        if (this.state.checkedRoutine === true) {

        } else { this.setState({ checkedReturn: false }) }
    }

    // date changes
    handleDateChange = date => {
        try {

            this.setState({
                selectedDateOutward: date,
                ride: {
                    ...this.state.ride,
                    departureTimestamp: date.getTime()
                }
            });

        } catch (error) {
            console.error(error)
            this.setState({
                selectedDateOutward: date,
                ride: {
                    ...this.state.ride,
                    departureTimestamp: date.getTime()
                },
                errors: {
                    ...this.state.errors,
                    departureTime: true
                }
            })
        }
    };

    // return ride
    handleDateChangeReturn = date => {
        try {
            if (date < this.state.selectedDateOutward) {
                this.setState({
                    selectedDateReturn: new Date().getTime() + 600000,
                    errors: {
                        ...this.state.errors,
                        dateReturn: true
                    }
                });
                return
            }
            if (date > this.state.selectedDateOutward) {
                this.setState({
                    selectedDateReturn: date,
                    errors: {
                        ...this.state.errors,
                        dateReturn: false
                    }
                });
                return
            }
            this.setState({
                selectedDateReturn: date,
            });
        } catch (error) {
            console.error(error)
            this.setState({
                selectedDateReturn: date,

                errors: {
                    ...this.state.errors,
                    dateReturn: true
                }
            })
        }
    };

    // checkbox routine
    handleCheckboxFilterChange = name => event => {
        this.setState({
            ride: {
                ...this.state.ride,
                filter: {
                    ...this.state.ride.filter,
                    [name]: event.target.checked
                }
            }
        });

    };

    // checkbox routine
    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });

        if (name === "checkedRoutine") {
            this.handleReturnOnChangeRoutine()
        } else { }

    };

    // smoking filter change
    handleCheckboxSmokingChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    // allergy filter change
    handleCheckboxAllergyChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    // increase number of seats of ride
    handleIncrement = () => {
        if (this.state.ride.vehicleSpace < 8) {
            this.setState({
                ride: {
                    ...this.state.ride,
                    vehicleSpace: this.state.ride.vehicleSpace + 1
                }
            });
        }
    };

    // decrease number of seats of ride
    handleDecrement = () => {
        if (this.state.ride.vehicleSpace !== 0) {
            this.setState({
                ride: {
                    ...this.state.ride,
                    vehicleSpace: this.state.ride.vehicleSpace - 1
                }
            });
        }
    };

    // increase number of repititions
    handleIncrementRepetitions = () => {
        if (this.state.weeklyRepetitions < 12) {
            this.setState({
                weeklyRepetitions: this.state.weeklyRepetitions + 1
            });
        }
    };

    // decrease number of repititions
    handleDecrementRepetitions = () => {
        if (this.state.weeklyRepetitions !== 1) {
            this.setState({
                weeklyRepetitions: this.state.weeklyRepetitions - 1
            });
        }
    };

    // change of routine
    handleChangeRoutine = event => {
        this.setState({ routineType: event.target.value });
    };

    handleChange = name => event => {
        this.setState({
            ...this.state,
            ride: {
                ...this.state.ride,
                [name]: event.target.value
            }
        });

    };

    // change of number of routines
    handleChangeRoutineNumber = event => {
        this.setState({ routineNumber: event.target.value });
    };


    handleOnUpdateClick = (e) => {

        let validationObject = [
            {
                type: "STRING",
                value: this.state.startPoint.address,
                errorIndex: 0
            },
            {
                type: "STRING",
                value: this.state.endPoint.address,
                errorIndex: 1
            },
            {
                type: "STRING",
                value: this.state.selectedVehicle,
                errorIndex: 2
            },
            {
                type: "TIMESTAMP",
                value: new Date(this.state.selectedDateOutward).getTime(),
                errorIndex: 3,
            }]
        checkRequiredFields(validationObject).then(data => {

            if (data.errorAvailable === false) {
                this.putUpdatedRide();
            } else {
                e.preventDefault();
                this.props.setSnackbar("Bitte alle Felder befüllen!", true, "error")
                this.setState({
                    errors: {
                        startPlaces: data.errorArray[0],
                        endPlaces: data.errorArray[1],
                        vehicle: data.errorArray[2],
                        departureTime: data.errorArray[3]
                    }
                });
            }
        });
    }

    // formatting data
    handleSelectStart = address => {
        geocodeByAddress(address.description)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(
                    result => {

                        this.setState({
                            startPoint: {
                                ...this.state.startPoint,
                                address: address.description,
                                addressLat: latLng.lat,
                                addressLng: latLng.lng,
                            },
                            ride: {
                                ...this.state.ride,
                                placeDeparture: {
                                    ...this.state.ride.placeDeparture,
                                    formattedAddress: result[5],
                                    city: result[2],
                                    postalCode: result[4],
                                    houseNumber: result[0],
                                    street: result[1],
                                    country: result[3],
                                    state: "",
                                }
                            }
                        })
                    }
                )
            }).catch(error => console.error('Error', error));
    };

    handleSelectEnd = address => {
        geocodeByAddress(address.description)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(
                    result => {
                        this.setState({
                            endPoint: {
                                ...this.state.endPoint,
                                address: address.description,
                                addressLat: latLng.lat,
                                addressLng: latLng.lng,
                            },
                            ride: {
                                ...this.state.ride,
                                placeArrival: {
                                    ...this.state.ride.placeArrival,
                                    formattedAddress: result[5],
                                    city: result[2],
                                    postalCode: result[4],
                                    houseNumber: result[0],
                                    street: result[1],
                                    country: result[3],
                                    state: "",
                                }
                            }

                        })
                    }
                )
            }).catch(error => console.error('Error', error));
    };


    // save updated data
    async putUpdatedRide() {
        try {
            this.setState({ loading: true })
            let changedData = {
                ...this.state.ride,
                vehicleId: this.state.selectedVehicle,
                departureLocation: {
                    type: "Point",
                    coordinates: [
                        this.state.startPoint.addressLng,
                        this.state.startPoint.addressLat
                    ]
                },
                arrivalLocation: {
                    type: "Point",
                    coordinates: [
                        this.state.endPoint.addressLng,
                        this.state.endPoint.addressLat
                    ]
                },
                arrivalTimestamp: 0,
                routeDetails: {},
                status: "START",
            }

            const data = await putChangedRide(this.state.rideId, changedData)
            switch (data.response.code) {
                case 200:
                    sendNotification(this.state.ride.attendee, EDIT_RIDE, { rideid: this.state.rideId });
                    this.setState({
                        loading: false,
                        redirect: true
                    })
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.error(error)
        }
    }


    // check selected vehicle
    checkSelectedVehicle() {
        this.state.vehicle.forEach(vehicle => {
            if (vehicle.favorite === true) {
                this.setState({ selectedVehicle: vehicle._id });
            }
        })
    };

    // change selected vehicle
    changeVehicle = (id) => {
        this.setState({

            selectedVehicle: id
        });
    }

    checkRequiredFields() {
        if (this.state.startPoint.address !== "" && this.state.endPoint.address !== "" && this.state.selectedVehicle !== "" && this.state.departureTimestamp !== "") {
            return true
        } else {
            return false
        }
    }

    renderAppBarHeader() {

        return (
            <CustomAppBar title="Fahrt bearbeiten" backIconEnabled={true} backHandler={() => { this.setState({ redirect: true }) }}>
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    render() {
        const renderDateTimePicker = (
            <div className="r2r-contentContainer-element-margin" >
                <SectionTypography> Wann fährst du los?</SectionTypography>
                <div className="r2r-container-content-flex-column">
                    <div className="r2r-container-content-flex-row r2r-container-center-align ">
                        <Typography className="createRide-flex-grow" variant="body1">
                            Hinfahrt
                        </Typography>
                        {/* <div className="createRide-flex-grow">
                            <div className="r2r-container-content-flex-row r2r-container-center-align ">

                                <FormControlLabel label="Rückfahrt"
                                    control={<Checkbox disabled={this.state.checkedRoutine === false ? false : true} checked={this.state.checkedReturn}
                                        onChange={this.handleCheckboxChange("checkedReturn")}
                                        value="checkedA" />} />
                            </div>
                        </div> */}
                    </div>

                    <div className={this.state.checkedReturn === true ? "r2r-container-content-flex-row" : ""}>
                        <div className="r2r-container-content-flex-column createRide-felxgrow">
                            <CustomDateTimePicker value={this.state.selectedDateOutward} onChange={() => this.handleDateChange} error={this.state.errors.departureTime} />

                        </div>
                        {/* {this.state.checkedReturn === true ? (
                            <div className="r2r-container-content-flex-column createRide-felxgrow">
                                <CustomDateTimePicker value={this.state.selectedDateReturn} onChange={() => this.handleDateChangeReturn} error={this.state.errors.dateReturn} />
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                                    <DatePicker
                                        views={['day']}
                                        label="Datum"
                                        value={this.state.selectedDateReturn}
                                        onChange={this.handleDateChangeReturn}
                                        renderInput={(params) => <TextField {...params} variant="standard" margin="normal" />}
                                        className="r2r-timepicker"
                                        cancelText="Schließen"
                                        disablePast={true} />
                                    <TimePicker
                                        label="Uhrzeit"
                                        value={this.state.selectedDateReturn}
                                        onChange={this.handleDateChangeReturn}
                                        className="r2r-timepicker"
                                        cancelLabel="Schließen"
                                        disablePast={true}
                                        renderInput={(params) => <TextField {...params} variant="standard" margin="normal" />}
                                        ampm={false} />
                                </LocalizationProvider > 
                            </div>
                        ) : null} */}
                    </div>
                </div>
            </div>
        )



        const renderEditRide = () => {

            return (
                <React.Fragment>
                    <div className="r2r-container-header r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column">
                        {/*Element 1: Start und Ziel */}

                        <CustomAutocomplete
                            id="startpunkt"
                            className="editRide-textField"
                            locateUser={true}
                            handleLocateUser={this.handleLocateUserStart}
                            disableGetPosition={this.state.currentLocation.departure.disableGetPosition}
                            error={this.state.errors.startPlaces}
                            placeholder="Wähle deinen Startpunkt!"
                            label="Startpunkt"
                            required={true}
                            icon={<GpsFixed color="secondary" />}
                            address={this.state.startPoint.address}
                            handleSelect={this.handleSelectStart} />
                        <CustomAutocomplete
                            id="endpoint"
                            className=""
                            locateUser={false}
                            handleLocateUser={this.handleLocateUserEnd}
                            disableGetPosition={this.state.currentLocation.arrival.disableGetPosition}
                            error={this.state.errors.endPlaces}
                            placeholder="Wähle dein Ziel!"
                            required={true}
                            label="Endpunkt"
                            icon={<LocationOn color="secondary" />}
                            address={this.state.endPoint.address}
                            handleSelect={this.handleSelectEnd} />
                    </div>

                    {/*Content */}
                    <div className="r2r-container-content">
                        {renderDateTimePicker}

                        <VehicleSelectoreComp vehicles={this.state.vehicle} changeVehicle={this.changeVehicle} selectedVehicleId={this.state.selectedVehicle} />
                        <OpenSeats openSeats={this.state.ride.vehicleSpace} onIncrement={this.handleIncrement} onDecrement={this.handleDecrement} />
                        <AdditionalRideInformation valueSmoking={this.state.ride.filter.smoker} valueAllergy={this.state.ride.filter.allergy} onChangeSmoking={this.handleCheckboxFilterChange("smoker")} onChangeAllergy={this.handleCheckboxFilterChange("allergy")} />
                        <RideDescriptionComp description={this.state.ride.rideDescription} onChange={this.handleChange("rideDescription")} />


                        <div id="Routine" className="">
                            <div className="">
                                <FormControlLabel label="Routinefahrt erstellen"
                                    control={
                                        <Switch
                                            checked={this.state.checkedRoutine}
                                            onChange={this.handleCheckboxChange('checkedRoutine')}
                                            value="checkedA" />} />
                            </div>
                            {this.state.checkedRoutine === true ?
                                <div className="r2r-container-content-flex-column">
                                    <div className="r2r-content-title">
                                        <Typography className=" editRide-title-center editRide-title-routine r2r-content-title" variant="h6">An welchen Tagen in der Woche soll deine Routine stattfinden?</Typography>
                                    </div>
                                    <div className="r2r-container-content-flex-row editRide-center-between">
                                        <Fab onClick={this.handleOnChangeDay('monday')} color={this.state.repeats.monday === false ? "primary" : "secondary"} variant="extended" size="small" >M</Fab>
                                        <Fab onClick={this.handleOnChangeDay('tuesday')} color={this.state.repeats.tuesday === false ? "primary" : "secondary"} variant="extended" size="small" >D</Fab>
                                        <Fab onClick={this.handleOnChangeDay('wednesday')} color={this.state.repeats.wednesday === false ? "primary" : "secondary"} variant="extended" size="small" >M</Fab>
                                        <Fab onClick={this.handleOnChangeDay('thursday')} color={this.state.repeats.thursday === false ? "primary" : "secondary"} variant="extended" size="small" >D</Fab>
                                        <Fab onClick={this.handleOnChangeDay('friday')} color={this.state.repeats.friday === false ? "primary" : "secondary"} variant="extended" size="small" >F</Fab>
                                        <Fab onClick={this.handleOnChangeDay('saturday')} color={this.state.repeats.saturday === false ? "primary" : "secondary"} variant="extended" size="small" >S</Fab>
                                        <Fab onClick={this.handleOnChangeDay('sunday')} color={this.state.repeats.sunday === false ? "primary" : "secondary"} variant="extended" size="small" >S</Fab>
                                    </div>
                                    <div className="r2r-contentContainer-element-margin">
                                        <div id="Seats" className="r2r-container-content-flex-column">
                                            <div className="r2r-content-title">
                                                <Typography className=" editRide-title-center editRide-title-routine r2r-content-title" variant="h6">Wie viele Wochen lang sollen sich deine Routinefahrten wiederholen?</Typography>
                                            </div>
                                            <div className="editRide-container-content-flex-row-center">
                                                <Cached style={{ height: 84, width: 84 }} color="secondary" />
                                                <ArrowLeft style={{ height: 48, width: 48 }} onClick={this.handleDecrementRepetitions} color="primary" >  </ArrowLeft>
                                                <Typography variant="h4"> {this.state.weeklyRepetitions} </Typography>
                                                <ArrowRight style={{ height: 48, width: 48 }} onClick={this.handleIncrementRepetitions} color="primary"> </ArrowRight>
                                            </div>
                                        </div>
                                    </div>

                                </div> : null}
                        </div>


                        {/*Element 4: Button */}
                        <div className="r2r-contentContainer-element-margin">
                            <Button onClick={this.handleOnUpdateClick} className="app-btn-secondary"> Fahrt ändern </Button>
                        </div>

                    </div >

                </React.Fragment >
            )

        }

        if (this.state.redirect) {
            return (<Redirect to="/rides" />)
        }



        return (
            <React.Fragment>
                {this.renderAppBarHeader()}

                {
                    this.state.loading ? <LoadingTransparentBlocked /> :
                        <React.Fragment>
                            {renderEditRide()}
                        </React.Fragment>
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, { setBottomNavState, setAppBarState, setSnackbar })(withStyles(styles)(EditRide));
