import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

/**
 * 
 * @param {*} dataObject 
 */
export async function addFeedback(sender, receiver, rideId) {
    try {
        let data = {
            sender: sender,
            receiver: receiver,
            rideId: rideId,
        }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + "feedback/", JSON.stringify(data))
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * 
 * @param {*} dataObject 
 */
export async function updateFeedback(ratingId, stars, comment, busline, datetime) {
    try {
        let data = {
            ratingId: ratingId,
            stars: stars,
            outstanding: false,
            comment: comment,
            busline: busline,
            datetime: datetime,
        }

        let response = await _axios.put(process.env.REACT_APP_REST_URL + "feedback/update", JSON.stringify(data))
        return response.data
    } catch (error) {
        console.error(error)
    }

}