import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter as Router } from "react-router-dom";
import { subscribeUser } from './subscription';
import { createStore } from 'redux';
import allReducers from './Reducers/index.js';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import KeycloakService from './util/KeycloakService';
import HttpService from './util/HttpService';

const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

const renderApp = () => ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>, document.getElementById('root'));


// renderApp()
KeycloakService.initKeycloak(renderApp)
HttpService.configure()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
subscribeUser()
