import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IssueTracker, CustomDesktopMenu } from '../index'

class CustomAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false
        }
    }

    render() {
        return (
            <AppBar position="sticky" color="primary">
                <Toolbar>
                    <CustomDesktopMenu className={"r2r-appbar-iconbutton"} sx={{ display: { lg: "inline-flex", md: 'none', sm: 'none', xs: 'none' } }} />
                    {this.props.backIconEnabled ?
                        <IconButton
                            className={"r2r-appbar-iconbutton"}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.props.backHandler}
                            edge="start"
                            sx={{ display: { lg: "none" } }}
                            size="large">
                            <ArrowBackIcon />
                        </IconButton> : null}
                    <Typography variant="h6" color="inherit">{this.props.title}</Typography>
                    {this.props.children}
                    <IssueTracker />
                </Toolbar>
            </AppBar >
        );
    }

}

CustomAppBar.propTypes = {
    backIconEnabled: PropTypes.bool,
    backHandler: PropTypes.func,
    title: PropTypes.string.isRequired
}


export default CustomAppBar
