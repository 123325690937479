import React, { Component } from 'react';
import { Typography, Checkbox, FormControlLabel, Card, Button, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { updateFeedback } from '../../API/REST/index.js'
import { LocalizationProvider, TimePicker, DatePicker } from "@mui/lab/";
import buslines from '../Global/constants.js';
import './Feedback.scss';
import Select from 'react-select';

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogOpen: this.props.isOpen,
            selectedOption: null,
            ratingValid: "",
            comment: "",
            commentValid: "",
            checked: false,
            busline: "",
            datetime: new Date().getTime(),
        }
    }

    checkCommentValid() {
        if (this.state.comment === "") {
            this.setState({ commentValid: false })
            this.handleSetFeedback();
        } else {
            this.setState({ commentValid: true })
            this.handleSetFeedback();
        }
    }

    handleSetFeedback() {
        if (this.state.comment !== "") {

            updateFeedback(this.props.ratingId, this.state.rating, this.state.comment)
            this.handleDialogClose();
        }
    }
    handleBusline = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };
    handleAnonym = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    // Change of date
    handleDateChange = date => {
        this.setState({
            selectedDateOutward: date,
            datetime: date.getTime()
        });
    };
    // Change of return date
    handleDateChangeReturn = date => {
        this.setState({ datetime: date });
    };

    render() {
        const { datetime } = this.state.datetime;
        const { selectedOption } = this.state;
        return (
            <Card data-testid="feedback-card" className="feedback-card">
                <Typography variant="h5" component="h2">
                    Rück- oder Fehlermeldung
                </Typography>
                <Select className="feedback-select"
                    value={selectedOption}
                    onChange={this.handleBusline}
                    options={buslines}
                />

                <TextField
                    error={this.state.commentValid === false ? true : false}
                    onChange={this.handleChange("comment")}
                    id="outlined-textarea"
                    label="Kommentar"
                    placeholder="Kommentar"
                    multiline
                    rows="10"
                    className="feedback-commentBox"
                    margin="normal"
                    variant="outlined"
                />


                <div className="r2r-container-content-flex-column">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Datum"
                            value={datetime}
                            onChange={this.handleDateChangeReturn}
                            className="r2r-timepicker"
                            renderInput={(params) => <TextField {...params} variant="standard" margin="normal" />}
                            format="dd/MM/yyyy" />
                        <TimePicker
                            label="Uhrzeit"
                            value={datetime}
                            onChange={this.handleDateChangeReturn}
                            className="r2r-timepicker"
                            renderInput={(params) => <TextField {...params} variant="standard" margin="normal" />}
                            ampm={false} />
                    </LocalizationProvider>

                </div>
                <FormControlLabel label="anonym bewerten"
                    control={
                        <Checkbox
                            checked={this.state.checked}
                            onChange={this.handleAnonym("checked")}
                            value="checked"
                            inputProps={{
                                "aria-label": "primary checkbox"
                            }}
                        />
                    }

                />
                <div className="r2r-contentContainer-element-margin">
                    <Button className="feedback-button" variant="contained" onClick={() => this.checkCommentValid()} color="secondary" >
                        Bewertung abgeben
                    </Button>
                </div>
            </Card>

        );
    }
}

export default Feedback;
