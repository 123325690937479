import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Typography, Dialog, IconButton, Badge, Slide, List, Divider } from '@mui/material';
import { Star, StarRate } from '@mui/icons-material/';
import OneRating from './OneRating.jsx';
import { LoadingTransparentBlocked, CustomGrid } from '../index.js';
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import { getReceivedRatings } from '../../API/REST/index'
import './ViewRating.scss';
import CustomDialogAppBar from '../Global/CustomDialogAppBar.jsx';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class ViewRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingExist: true,
            loading: false,
            ratings: [],
            ratingCount: 0,
            ratingDialogOpen: false
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(4)
    }

    /**
     * Requests the API to get the received Ratings of the user
     */
    async getReceivedRatings() {
        this.setState({ loading: true })
        const data = await getReceivedRatings(this.props.user._id)
        switch (data.response.code) {
            case 200:
                if (data.result.data === null) {
                    this.setState({
                        loading: false,
                    });
                    this.handleRatingOpenState(true)
                } else {
                    if (data.result.data.length === 0) {
                        this.setState({
                            ratingExist: false,
                            loading: false
                        })
                        this.handleRatingOpenState(true)
                    } else {
                        this.setState({
                            ratings: data.result.data,
                            loading: false,
                        });
                        this.handleRatingOpenState(true)
                    }
                }
                break;
            default:
                break;
        }

    }

    /**
     * Calculates the average rating
     */
    calculateRating() {
        if (this.state.ratings.length === 0) {
            return 0
        } else {
            var totalRating = 0
            for (var i = 0; i < this.state.ratings.length; i++) {
                totalRating = totalRating + this.state.ratings[i].stars
            }
            return Number((totalRating / this.state.ratings.length).toFixed(2))
        }
    }

    /**
     * Renders the appbar
     */
    renderAppBarHeader() {
        return (
            <CustomDialogAppBar title="Bewertungen" onClickHandler={() => this.handleRatingOpenState(false)} />
        )
    }

    /**
     * Set the open state
     * @param {boolean} state - value of the new state
     */
    handleRatingOpenState(state) {
        this.setState({
            ratingDialogOpen: state
        })
    }

    renderRatings = () => {
        return (this.state.ratings.map((rating, index) =>
            <React.Fragment key={rating}>
                <OneRating rating={rating} />
                {(index < this.state.ratings.length - 1) ? < Divider variant="inset" component="li" /> : null}
            </React.Fragment>
        ))
    }

    render() {
        const renderRating = () => {
            return (
                <React.Fragment>
                    <Dialog id="viewRating-dialog" data-testid="test-dialog" fullScreen open={this.state.ratingDialogOpen} onClose={() => this.handleRatingOpenState(false)} TransitionComponent={Transition}>
                        {this.renderAppBarHeader()}
                        <div className="viewRating-outer">
                            <div className="r2r-display-flex r2r-container-backgroundcolor viewRating-flex-upper ">
                                <CustomGrid className=" r2r-container-header-margin">
                                    <Box className="r2r-display-flex">
                                        <div className="viewRating-flex-upper-left">
                                            <Star color="secondary" style={{ height: 100, width: 100 }} />
                                        </div>
                                        <div className="r2r-display-flex viewRating-flex-upper-right">
                                            <div className="viewRating-flex-upper-right-top">
                                                <Typography variant="h4">{this.calculateRating()}/5</Typography>
                                            </div>
                                            <div className="viewRating-flex-upper-right-bottom ">
                                                <Typography variant="h6">{this.state.ratings.length} {this.state.ratings.length === 1 ? "Bewertung" : "Bewertungen"}</Typography>
                                            </div>
                                        </div>
                                    </Box>
                                </CustomGrid>
                            </div>

                            <CustomGrid>
                                {this.state.ratingExist ?
                                    <div className="r2r-container-content">
                                        <List>
                                            {this.renderRatings()}
                                        </List>

                                    </div> : <div className="r2r-container-content-flex-column r2r-container-center-align app-messageEmpty-container">
                                        <Typography className=" r2r-content-title viewRating-align-vertical" variant="h6">
                                            Es wurden noch keine Bewertungen abgegeben.
                                        </Typography>
                                    </div>}
                            </CustomGrid>
                        </div>
                    </Dialog>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingTransparentBlocked /> : null}
                {renderRating()}
                <IconButton
                    id="viewRating-iconbutton"
                    data-testid="test-iconbutton"
                    onClick={() => this.getReceivedRatings()}
                    color="inherit"
                    size="large">
                    <Badge badgeContent={this.state.ratingCount} color="secondary">
                        <StarRate />
                    </Badge>
                </IconButton >
            </React.Fragment >
        );

    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(ViewRating);
