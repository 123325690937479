import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { Typography, Avatar, ListItemAvatar, ListItemButton } from '@mui/material';
import { Commute, DirectionsCar, PendingOutlined, CheckCircleOutline } from '@mui/icons-material/';
import { LoadingTransparentBlocked } from '../Global/Loading'
import './HistoryElement.scss';
import { formattedDate, formattedTime } from '../../util/formatting_helper';
import { Redirect } from 'react-router-dom';

class RideHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            redirect: false
        }
    }

    render() {

        const handleListItemClicked = () => {
            console.log(this.props)
            if (this.props.ownRide) {
                this.setState({
                    redirect: true
                })
            }
        }

        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: '/ridedetails/rideId=' + this.props.rideId,
                    // state: { rideId: this.props.rideObject._id }
                }} />)
        }

        if (this.state.loading) {
            return (<LoadingTransparentBlocked />)
        } else {
            return (
                <ListItemButton data-testid="test-historyelement" disabled={!this.props.ownRide} className="" onClick={() => handleListItemClicked()}>
                    <ListItemAvatar>
                        <Avatar className="" alt="avatar" src={this.props.imagePath ? process.env.REACT_APP_IMAGE_URL + this.props.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />
                    </ListItemAvatar>
                    <div className="r2r-display-flex historyElement-width historyElement-content">
                        <div className="r2r-display-flex historyElement-header-row">
                            {this.props.ownRide ?
                                <React.Fragment>
                                    <Typography variant="subtitle1" className="r2r-typography-bold">Diese Fahrt hast du angeboten.</Typography>
                                    <DirectionsCar />
                                </React.Fragment> :
                                <React.Fragment>
                                    <Typography variant="subtitle1" className="r2r-typography-bold">Du bist bei {this.props.firstName} mitgefahren.</Typography>
                                    <Commute />
                                </React.Fragment>}
                        </div>

                        <Typography id="historyElement-departurePlace" data-testid="test-departurePlace" className="" variant="body1"><b>Von:</b> {this.props.departure}</Typography>
                        <Typography id="historyElement-arrivalPlace" data-testid="test-arrivalPlace" className="" variant="body1"><b>Nach:</b> {this.props.arrival}</Typography>
                        <Typography id="historyElement-departureTime" data-testid="test-departureTime" className="" variant="body2"><b>Datum und Uhrzeit:</b> {formattedDate(this.props.departureTimestamp)} um {formattedTime(this.props.departureTimestamp)} Uhr</Typography>
                        <Typography id="historyElement-status" data-testid="test-status" className="historyelement-status" variant="body2"><b>Status: </b> {this.props.status === "COMPLETED" ? <CheckCircleOutline fontSize="small" /> : <PendingOutlined fontSize="small" />}</Typography>
                    </div>
                </ListItemButton>
            );
        }
    }
}

RideHistory.propTypes = {
    ownRide: PropTypes.bool.isRequired,
    departure: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    imagePath: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
}

export default RideHistory;
