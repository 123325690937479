import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import { Dialog, Divider, IconButton, List, Slide, Typography } from '@mui/material';
import HistoryElement from './HistoryElement'
import { getRidesForRideHistory } from '../../API/REST/index.js'
import { CustomGrid, LoadingTransparentBlocked } from '../index.js';
import './RideHistory.scss'
import CustomDialogAppBar from '../Global/CustomDialogAppBar.jsx';
import { History } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class RideHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            allRides: [],
            dialogOpen: false
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(3)
    }

    /**
     * Method requests data that is necassary for this component
     */
    async getElements() {
        try {
            this.setState({ loading: true })
            const data = await getRidesForRideHistory(this.props.user._id)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        allRides: data.result.rides
                    })
                    this.state.allRides.sort((a, b) => { if (a.departureTimestamp < b.departureTimestamp) { return 1 } else { return (-1) } })
                    this.handleDialogOpen(true)
                    this.setState({ loading: false })
                    break;
                default:
                    this.setState({
                        allRides: []
                    })
                    this.handleDialogOpen(true)
                    this.setState({ loading: false })
                    break;
            }
        } catch (error) {
            console.error(error)
            this.setState({
                allRides: []
            })
            this.handleDialogOpen(true)
            this.setState({ loading: false })
        }
    }

    handleDialogOpen(state) {
        this.setState({
            dialogOpen: state
        })
    }

    /**
     * Add Historyelements with specific props to array depending if the ride is from the current user or not
     */
    renderElements() {
        return this.state.allRides.map((ride, index) => {
            if (ride.userId) {
                if (ride.userId._id === this.props.user._id) {
                    return (
                        <React.Fragment key={index}>
                            <HistoryElement key={ride} ownRide={true} status={ride.status} rideId={ride._id} departure={ride.placeDeparture.formattedAddress} arrival={ride.placeArrival.formattedAddress} imagePath={ride.userId.profilImageId ? this.props.user.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE} departureTimestamp={ride.departureTimestamp} />
                            {(index < this.state.allRides.length - 1) ? < Divider variant="inset" component="li" /> : null}
                        </React.Fragment>
                    )
                } else {
                    if (ride.userId) {
                        return (
                            <React.Fragment key={index}>
                                <HistoryElement key={ride} ownRide={false} status={ride.status} departure={ride.placeDeparture.formattedAddress} arrival={ride.placeArrival.formattedAddress} imagePath={ride.userId.profilImageId ? ride.userId.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE} firstName={ride.userId.firstName} lastName={ride.userId.lastName} departureTimestamp={ride.departureTimestamp} />
                                {(index < this.state.allRides.length - 1) ? < Divider variant="inset" component="li" /> : null}
                            </React.Fragment>)
                    } else {
                        return (

                            <React.Fragment key={index}>
                                <HistoryElement key={ride} ownRide={false} status={ride.status} departure={ride.placeDeparture.formattedAddress} arrival={ride.placeArrival.formattedAddress} imagePath={process.env.REACT_APP_DEFAULT_USER_IMAGE} firstName={"anonym"} lastName={""} departureTimestamp={ride.departureTimestamp} />
                                {(index < this.state.allRides.length - 1) ? < Divider variant="inset" component="li" /> : null}
                            </React.Fragment>)
                    }
                }
            } else {
                return (
                    <React.Fragment key={index}>
                        <HistoryElement key={ride} ownRide={false} status={ride.status} departure={ride.placeDeparture.formattedAddress} arrival={ride.placeArrival.formattedAddress} imagePath={process.env.REACT_APP_DEFAULT_USER_IMAGE} firstName={"anonym"} lastName={""} departureTimestamp={ride.departureTimestamp} />
                        {(index < this.state.allRides.length - 1) ? < Divider variant="inset" component="li" /> : null}
                    </React.Fragment>)
            }

        })
    }

    /**
     * Render the Appbar for this component
     */
    renderAppBarHeader() {
        return (
            <CustomDialogAppBar title="Fahrtverlauf" onClickHandler={() => this.handleDialogOpen(false)} />
        )
    }

    render() {
        const renderRideHistory = () => {
            return (
                <React.Fragment>
                    <Dialog fullScreen open={this.state.dialogOpen} onClose={() => this.handleDialogOpen(false)} TransitionComponent={Transition}>
                        {this.renderAppBarHeader()}

                        <CustomGrid >
                            {this.state.allRides.length === 0 ? (
                                <div className="r2r-display-flex rideHistory-text">
                                    <Typography className="" variant="h6" >Du hast noch keine historischen Fahrtereignisse gesammelt</Typography>
                                </div>) : (
                                <List>
                                    {this.renderElements()}
                                </List>
                            )}
                        </CustomGrid>
                    </Dialog>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingTransparentBlocked /> : null}
                {renderRideHistory()}

                <IconButton
                    onClick={() => this.getElements()}
                    data-testid="test-btn"
                    color="inherit"
                    size="large">
                    <History />
                </IconButton>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id,
            profilImageId: state.user.profilImageId
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(RideHistory);
