/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete';
import { LocationOn, GpsFixedOutlined } from '@mui/icons-material/';
// eslint-disable-next-line no-unused-vars
import { Typography, InputAdornment, TextField, IconButton, Grid } from '@mui/material/';
import withStyles from '@mui/styles/withStyles';
import { reverseGeocode } from "../../util/googleApi_helper.js";
import parse from 'autosuggest-highlight/parse';
//import throttle from 'lodash/throttle';

import './CustomAutocomplete.scss'

const styles = {
    multilineColor: {
        color: "white"
    },
    cssLabel: {
        color: "white"
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "white !important"
    }
};

function CustomAutocomplete(props) {
    const [input, setInput] = useState(props.address);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(props.address);
    const [loading, setLoading] = useState(false)

    const autocomplete = new window.google.maps.places.AutocompleteService()

    const handleChange = event => {
        fetchSuggestions(event.target.value)
    };

    const currentPosition = () => {
        if (!navigator.geolocation) {

        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                const latlng = {
                    lat: latitude,
                    lng: longitude
                }

                reverseGeocode(latitude, longitude).then(data => {

                    props.handleLocateUser(latlng, data)
                    setSelectedOption(data[5])
                })
                // props.handleLocateUser(latlng, currentPosition)
            }, (error) => { console.error(error) }, { enableHighAccuracy: true })
        }
    }

    const fetchSuggestions = (value) => {

        if (input === null) {
            setOptions([])
        } else {
            if (value !== "") {
                autocomplete.getPlacePredictions({ input: value, componentRestrictions: { country: 'de' } }, results => {
                    setOptions(results || [])
                    setLoading(false);
                })
            }
        }
    }

    const { classes } = props;
    return (
        <div className="testAutocomplete-flex">
            <Autocomplete
                id={props.id}
                style={{ color: "red" }}
                className="testAutocomplete-width"
                getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                freeSolo
                value={selectedOption}
                renderInput={params => (
                    <TextField
                        {...params}
                        className={props.className}
                        required={props.required}
                        error={props.error}
                        label={props.label}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        placeholder={props.placeholder}

                    />
                )}
                onChange={(event, newValue) => {

                    if (newValue !== null) {
                        props.handleSelect(newValue)
                        setSelectedOption(newValue.description)
                    } else {
                        props.handleSelect("")
                        setSelectedOption("")
                    }
                }}
                renderOption={(props, option) => {
                    // console.log(option)
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOn />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
            {props.locateUser ?
                <div className="MuiFormControl-marginNormal testAutocomplete-margin" >
                    <IconButton
                        disabled={props.disableGetPosition}
                        onClick={currentPosition}
                        size="large">
                        <GpsFixedOutlined />
                    </IconButton>
                </div>
                : null}
        </div>
    );
}

CustomAutocomplete.propTypes = {

}

export default withStyles(styles)(CustomAutocomplete)
