import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Typography, Card, CardContent, CardHeader, Avatar } from '@mui/material';
import { getRanking } from '../../API/REST/index'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMedal } from '@fortawesome/free-solid-svg-icons'
import './ScoreingPanel.scss';
import { LoadingTransparentBlocked, SectionTypography } from "../index";

class ScoreingPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            loading: true
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        await this.requestData();
    }

    async requestData() {
        try {
            let data = await getRanking()
            switch (data.response.code) {
                case 200: {
                    this.setState({
                        loading: false,
                        rankingUser: data.result.data || []
                    });
                    break;
                }
                default: {
                    this.setState({
                        loading: false,
                        rankingUser: []
                    });
                }
            }

        } catch (error) {
            this.setState({
                loading: false,
                rankingUser: []
            });
            // console.error(error)
        }

    }


    renderRankingentry() {
        //const grades = [<Grade className="scoringPanel-iconsize  scoringPanel-icongold" />, <Grade className="scoringPanel-iconsize scoringPanel-iconsilver" />, <Grade className="scoringPanel-iconsize scoringPanel-iconbrown" />]
        // const grades = [<FontAwesomeIcon className="scoringPanel-iconsize scoringPanel-icongold" icon={faMedal} />, <FontAwesomeIcon className="scoringPanel-iconsize scoringPanel-iconsilver" icon={faMedal} />, <FontAwesomeIcon className="scoringPanel-iconsize scoringPanel-iconbrown" icon={faMedal} />]
        const styles = ["scoringPanel-iconsize scoringPanel-icongold", "scoringPanel-iconsize scoringPanel-iconsilver", "scoringPanel-iconsize scoringPanel-iconbrown"]
        const variants = ["h4", "h4", "h4"];


        return this.state.rankingUser.map((user, index) => {
            return (
                <div key={index} id={index} className="r2r-display-flex r2r-justify-center scoringPanel-entry">
                    {/* <div className="r2r-display-flex scoringPanel-iconcontainer ">
                        {grades[index]}
                    </div> */}
                    <Avatar className={styles[index]} alt="Remy Sharp" src={user.profilImageId ? process.env.REACT_APP_IMAGE_URL + user.profilImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />

                    <Typography data-testid='scoreValue' className="scoringPanel-score-text" variant={variants[index]}>{Math.trunc(user.stats.points)} Punkte</Typography>
                </div>
            )
        })
    }

    render() {
        if (this.state.loading) {
            return <LoadingTransparentBlocked />
        }
        return (
            <div id={this.props.id} className="r2r-contentContainer-element-margin">
                <Card className="r2r-dashboard-card-margin">
                    <CardHeader title={<SectionTypography>Leaderboard</SectionTypography>} />
                    <CardContent  >
                        <div className="r2r-display-flex r2r-justify-center scoringPanel">
                            {this.renderRankingentry()}
                        </div>
                    </CardContent>
                </Card>
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, null)(ScoreingPanel);
