import HttpService from '../../util/HttpService'
let _axios = HttpService.getAxiosClient()

export async function createTransaction(receivers, sender, purposeRide) {
    try {
        const data = {
            receivers,
            sender,
            purposeRide
        }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + "transaction/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function checkTransaction(receiver) {
    try {
        const data = {
            userId: receiver
        }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + "transaction/check", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function confTransaction(_id, confirmed) {
    try {
        const data = {
            _id,
            confirmed
        }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + "transaction/conf", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}