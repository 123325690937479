import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "notification"

/**
 * 
 * @param {*} dataObject 
 */
export async function getAllNotifications(data) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/getAllNotifications", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * 
 * @param {*} dataObject 
  */
export async function readNotification(id) {
    try {
        let data = { notificationsId: id }
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/readNotification", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * 
 * @param {*} dataObject 
 */
export async function getNotificationCount(id) {
    try {
        let data = { userId: id }
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/getNotificationCount", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}


export async function addNotificationToUsers(data) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}