import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Typography } from '@mui/material';
import { Redirect } from "react-router-dom";
import { User, LoadingTransparentBlocked } from '../index'
import { addUser, addProfilImage, deleteImage } from '../../API/REST/index'
import { setSnackbar } from '../../Actions/Index';
import './CreateProfile.scss';
import KeycloakService from '../../util/KeycloakService';
import { messages } from '../../util/toastMessages';

class CreateProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: false,
            user: {
                ...this.props.user,
                firstName: KeycloakService.getFirstName(),
                lastName: KeycloakService.getLastName(),
                email: KeycloakService.getUserEmail(),
                profilImageId: {
                    _id: process.env.REACT_APP_PROFILIMAGE_DEFAULT_ID,
                    //imagePath: "public/images/dummybild.jpg", 
                    imagePath: process.env.REACT_APP_DEFAULT_USER_IMAGE
                }
            },
        };

        this.handleNavigateToDashboard = this.handleNavigateToDashboard.bind(this)
        this.initialProfilImageSave = this.initialProfilImageSave.bind(this);
    }

    /**
     * handler for navigation to dashboard
     * @param {event} event 
     */
    async handleNavigateToDashboard(event) {
        this.props.reAuthentificate();
    }

    /**
     * Methods to prepare the following process 
     * @param {object} userState - state object
     * @param {*} imageFile - image object
     */
    async initialProfilImageSave(userState, imageFile) {
        this.setState({
            loadingDialog: true,
            loading: true,
        })
        const userData = {
            phoneNumber: userState.phoneNumber,
            gender: userState.gender,
            acceptDsgvo: userState.acceptDsgvo
        }
        if (imageFile === null || imageFile === undefined) {
            this.initialUserSave(userData, process.env.REACT_APP_PROFILIMAGE_DEFAULT_ID)
        } else {
            let formData = new FormData();
            formData.append('image', imageFile)
            this.uploadProfilImage(userData, formData)
        }
    }

    /**
     * Method to save the image of the user
     * @param {object} userData - state object
     * @param {*} image - image object
     */
    async uploadProfilImage(userData, image) {
        try {
            const data = await addProfilImage(image)
            switch (data.response.code) {
                case 200:
                    this.initialUserSave(userData, data.result.data.imageId)
                    break;
                case 500:
                    throw new Error("Error while adding image.")
                default:
                    throw new Error("Error while adding image.")

            }
        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            console.error(error)

            this.setState({
                loadingDialog: false,
                loading: false,
            })
        }
    }

    /**
     * Method saves the user in the database
     * @param {object} userObject - state object
     * @param {string} newProfilImageId - id of the new image object
     */
    async initialUserSave(userObject, newProfilImageId) {
        try {
            let newUser = {
                email: this.state.user.email,
                firstName: this.state.user.firstName,
                lastName: this.state.user.lastName,
                phoneNumber: userObject.phoneNumber,
                gender: userObject.gender,
                profilImageId: newProfilImageId,
                dsgvoAccept: userObject.acceptDsgvo,
                firstLogin: false
            };

            const data = await addUser(newUser)

            switch (data.response.code) {
                case 200:
                    this.handleNavigateToDashboard();
                    break;
                case 500:
                    throw new Error("Fehler beim anlegen eines Nutzers.")
                default:
                    throw new Error("Fehler beim anlegen eines Nutzers.")
            }
        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            deleteImage(newProfilImageId)
            console.error(error)
            this.setState({
                loadingDialog: false,
                loading: false,
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingTransparentBlocked /> : null}
                <div className="createProfil-titleCenter">
                    <Typography variant="h6" className="">Profil anlegen</Typography>
                </div>
                <User user={this.state.user} initialLogin={true} firstName={this.state.user.firstName} lastName={this.state.user.lastName} email={this.state.user.email} handler={this.initialProfilImageSave} />
            </React.Fragment>
        );
    }

}

export default connect(null, { setSnackbar })(CreateProfile);
