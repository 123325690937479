import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";
import { Typography, Fab, Card, CardContent } from '@mui/material';
import { formattedTime } from '../../util/formatting_helper'
import './UserRide.scss';

class UserRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
        this.handleRedirect = this.handleRedirect.bind(this)
    }

    componentDidMount() {

    }

    totalAttendee() {
        var totalAttendee = 0;
        this.props.rideObject.attendee.forEach((element) => {
            if (element.status === "ACCEPT") {
                totalAttendee = totalAttendee + 1;
            }
        })
        return totalAttendee
    }


    handleRedirect() {
        this.setState({ redirect: true })
    }

    render() {
        let bubbleColor = (this.totalAttendee() / this.props.rideObject.vehicleSpace) >= 0.5 ? "secondary" : "primary";
        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: '/ridedetails/rideId=' + this.props.rideObject._id,
                    state: { rideId: this.props.rideObject._id }
                }} />)
        }

        const renderDepartureLine = () => {
            return (
                <div className="r2r-display-flex userRide-flex-ride-info">
                    <Typography id="departure-time" data-testid="test-departure-time" className="userRide-flex-ride-info-left" component="div" variant="body2" >{formattedTime(this.props.rideObject.departureTimestamp)} Uhr</Typography>
                    <div id="svg_container" className=" userRide-flex-ride-info-mid">
                        <svg width="30px" height="100%" preserveaspectration="none">
                            <line x1="50%" y1="50%" x2="50%" y2="100%" stroke="black" strokeWidth="2" />
                            <circle cx="50%" cy="50%" r="5" stroke="black" strokeWidth="2" fill="white" />
                        </svg>
                    </div>
                    <Typography id="departure-address" data-testid="test-departure-address" className="userRide-flex-ride-info-right" component="div" variant="body2">{this.props.rideObject.placeDeparture.street + " " + this.props.rideObject.placeDeparture.houseNumber + " " + this.props.rideObject.placeDeparture.postalCode + " " + this.props.rideObject.placeDeparture.city}</Typography>
                </div>
            )
        }

        const renderArrivalLine = () => {
            return (
                <div className="r2r-display-flex userRide-flex-ride-info">
                    <Typography id="arrival-time" data-testid="test-arrival-time" className="r2r-typography-bold userRide-flex-ride-info-left" component="div" variant="body2" >{formattedTime(this.props.rideObject.arrivalTimestamp)} Uhr</Typography>
                    <div className=" userRide-flex-ride-info-mid">
                        <svg width="30px" height="100%">
                            <line x1="50%" y1="50%" x2="50%" y2="0%" stroke="black" strokeWidth="2" />
                            <circle cx="50%" cy="50%" r="5" stroke="black" strokeWidth="2" fill="black" />
                        </svg>
                    </div>
                    <Typography id="arrival-address" data-testid="test-arrival-address" className="userRide-flex-ride-info-right r2r-typography-bold" component="div" variant="body2" >{this.props.rideObject.placeArrival.street + " " + this.props.rideObject.placeArrival.houseNumber + " " + this.props.rideObject.placeArrival.postalCode + " " + this.props.rideObject.placeArrival.city}</Typography>
                </div>
            )
        }

        return (
            <Card className="rides-card-margin r2r-pointer" onClick={this.handleRedirect} >
                <CardContent className="rides-card">
                    <div className="r2r-display-flex userRide-flex-ride">
                        <Fab style={{ height: 50, width: 50 }} disabled color={bubbleColor} aria-label="Mitfahrer" >
                            {this.totalAttendee()}/{this.props.rideObject.vehicleSpace}
                        </Fab>
                        <div className="r2r-display-flex userRide-flex-content">
                            {renderDepartureLine()}
                            {renderArrivalLine()}
                        </div>
                    </div>
                </CardContent>
            </Card>

        );
    }
}

UserRide.propTypes = {
    rideObject: PropTypes.shape({
        _id: PropTypes.string,
        departureTimestamp: PropTypes.number,
        arrivalTimestamp: PropTypes.number,
        vehicleSpace: PropTypes.number,
        placeArrival: PropTypes.shape({
            street: PropTypes.string,
            houseNumber: PropTypes.string,
            postalCode: PropTypes.number,
            city: PropTypes.string,
        }),
        placeDeparture: PropTypes.shape({
            street: PropTypes.string,
            houseNumber: PropTypes.string,
            postalCode: PropTypes.number,
            city: PropTypes.string,
        }),
        attendee: PropTypes.arrayOf(PropTypes.object)
    }).isRequired
}

export default UserRide;
