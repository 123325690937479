import React, { Component } from "react";
import { withRouter, Route, Link, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { setUser } from "./Actions/Index.js";
import { getOutstandingRatings, checkInitalMailadress, checkTransaction } from "./API/REST/index";
import { createTheme } from '@mui/material/styles'
import { ThemeProvider, StyledEngineProvider, BottomNavigation, BottomNavigationAction, Hidden } from "@mui/material/";
import { Add, Search, DirectionsCar, Dashboard as DashboardIcon, PermIdentity } from "@mui/icons-material/";
import { ConfirmRideDialog, Networkerror, Loading, Toast, VehicleOverview, NotificationOverview, ChatOverview, Dashboard, MyProfil, RideView, CreateRide, EditRide, ViewRating, Feedback, SearchRide, ShowResults, CreateProfile, ManageRides, Chat, GiveRatingDialog, ChangeUserprofil, Unauthorized, ErrorNotFound, DsgvoDialog } from "./Components";
import { withStyles } from "@mui/styles";
import { getSubscriptions } from "./subscription";
import KeycloakService from "./util/KeycloakService.js";
import themeJson from "./config/theme.json";
import "./App.scss";

const theme = createTheme(themeJson);

const styles = {
    root: {
        color: "#727272",
        "&$selected": {
            color: "#ffcc00"
        }
    },
    selected: {}
};


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            openRatings: [],
            openConfirmations: [],
            initialLogin: true,
            user: {}
        };
        this.firstLoginReauthentificate = this.firstLoginReauthentificate.bind(this)
    }

    componentDidMount() {
        this.checkInitialLogin();
    }

    async checkInitialLogin() {
        try {
            let data = await checkInitalMailadress(KeycloakService.getUserEmail());
            switch (data.response.code) {
                case 200:
                    if (data.result.data) {
                        this.checkOutstandingRatings(data.result.data._id);
                        this.checkRideconfirmations(data.result.data._id);
                        this.props.setUser(data.result.data);
                        this.setState({
                            initialLogin: false,
                            user: data.result.data,
                            loading: false
                        });
                        getSubscriptions(data.result.data._id);
                    } else {
                        this.setState({
                            email: KeycloakService.getUserEmail(),
                            loading: false,
                            initialLogin: true
                        });
                    }
                    break;
                default:
                    this.setState({
                        loading: false,
                    })
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    }

    async checkOutstandingRatings(userId) {
        try {
            const data = await getOutstandingRatings(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        openRatings: data.result.data
                    });
                    break;
                default:
                    this.setState({
                        openRatings: []
                    });
                    break;
            }
        } catch (error) {
            this.setState({
                loading: false,
                openRatings: []
            });
            console.error(error)
        }
    }

    async checkRideconfirmations(userId) {
        try {
            const data = await checkTransaction(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        openConfirmations: data.result.data
                    });
                    break;
                default:
                    this.setState({
                        openConfirmations: []
                    });
                    break;
            }
        } catch (error) {
            this.setState({
                loading: false,
                openConfirmations: []
            });
            console.error(error)
        }
    }


    firstLoginReauthentificate() {
        this.checkInitialLogin()
    }

    renderGiveRatingDialog() {
        var resultArray = [];
        if (this.state.openRatings !== 0) {
            this.state.openRatings.forEach((element, index) => {
                if (element.receiver) {
                    resultArray.push(
                        <GiveRatingDialog key={index} isOpen={true} ratingId={element._id} firstName={element.receiver.firstName} lastName={element.receiver.lastName} receiver={element.receiver} />
                    );
                }

            });
        }
        return resultArray;
    }

    renderRideConfirmDialog() {
        var resultArray = [];
        if (this.state.openConfirmations !== 0) {
            this.state.openConfirmations.forEach((confirmation, index) => {
                if (confirmation.receiver) {
                    resultArray.push(
                        <ConfirmRideDialog key={index} isOpen={true} transactionid={confirmation._id} departureTimestamp={confirmation.purposeRide.departureTimestamp} firstName={confirmation.sender.user.firstName} lastName={confirmation.sender.user.lastName} />
                    );
                }

            });
        }
        return resultArray;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.app.snackbar.open !== state.open) {
            return {
                open: props.app.snackbar.open
            };
        } else {
            return null;
        }
    }

    renderDsgvoDialog() {
        if (!this.state.initialLogin) {
            if (!this.props.user.dsgvoAccept)
                return <DsgvoDialog />;
        }
    }

    // handle() {
    //     this.setState({
    //         open: this.props.app.snackbar.open
    //     });
    // }

    renderToast = () => {
        // if (this.props.app.snackbar.open) {
        return (
            <Toast
                message={this.props.app.snackbar.message || ""}
                severity={this.props.app.snackbar.severity || "success"}
                duration={6000} />
        );
        // }
    };

    render() {
        const actionClasses = this.props.classes;

        const renderComponent = component => {
            if (navigator.onLine) {
                if (this.state.initialLogin === false) {
                    return component;
                } else {
                    return <Redirect to="/initialLogin" />;
                }
            } else {
                return <Redirect to="/network" />;
            }
        };

        if (KeycloakService.isLoggedIn) {
            return (
                <div id="frame" className="">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            {this.state.loading ? (
                                <Loading />
                            ) : (
                                <React.Fragment>
                                    <div className="r2r-content r2r-display-flex">
                                        <Switch>
                                            <Route exact path="/" render={props => renderComponent(<Dashboard {...props} />)} />
                                            <Route path="/rides" render={props => renderComponent(<ManageRides {...props} />)} />
                                            <Route path="/editUser" render={props => renderComponent(<ChangeUserprofil {...props} />)} />
                                            <Route path="/profil" render={props => renderComponent(<MyProfil {...props} />)} />
                                            <Route path="/searchRide/departureFullAdress=:departureFullAdress&arrivalFullAdress=:arrivalFullAdress&departurearrival=:departurearrival&departureArrivalTimestamp=:departureArrivalTimestamp" render={props => renderComponent(<SearchRide {...props} />)} />
                                            <Route path="/showResults/departureLat=:departureLat&departureLng=:departureLng&departureStreet=:departureStreet&departureHouseNumber=:departureHouseNumber&departurePostalCode=:departurePostalCode&departureCity=:departureCity&arrivalLat=:arrivalLat&arrivalLng=:arrivalLng&arrivalStreet=:arrivalStreet&arrivalHouseNumber=:arrivalHouseNumber&arrivalPostalCode=:arrivalPostalCode&arrivalCity=:arrivalCity&departurearrival=:departurearrival&departureArrivalTimestamp=:departureArrivalTimestamp&departureFullAdress=:departureFullAdress&arrivalFullAdress=:arrivalFullAdress" render={props => renderComponent(<ShowResults {...props} />)} />
                                            <Route path="/ridedetails/rideId=:rideId" render={props => renderComponent(<RideView {...props} />)} />
                                            <Route path="/addride" render={props => renderComponent(<CreateRide {...props} />)} />
                                            <Route path="/editRide/:rideId" render={props => renderComponent(<EditRide {...props} />)} />
                                            <Route path="/rating" render={props => renderComponent(<ViewRating {...props} />)} />
                                            <Route path="/manageVehicle" render={props => renderComponent(<VehicleOverview {...props} />)} />
                                            <Route path="/chat/chatId=:chatId" render={props => renderComponent(<Chat {...props} user={this.state.user} />)} />
                                            <Route path="/chats" render={props => renderComponent(<ChatOverview {...props} user={this.state.user} />)} />
                                            <Route path="/notifications" render={props => renderComponent(<NotificationOverview {...props} />)} />
                                            <Route path="/feedback" render={props => renderComponent(<Feedback {...props} user={this.state.user} />)} />
                                            <Route exact path="/initialLogin" render={props => this.state.initialLogin === true ? (<CreateProfile {...props} user={this.state.user} reAuthentificate={this.firstLoginReauthentificate} />) : (<Redirect to="/" />)} />
                                            <Route exact path="/network" render={props => (<Networkerror {...props} />)} />
                                            <Route path="*" component={ErrorNotFound} />
                                        </Switch>
                                    </div>
                                    {this.state.initialLogin === false ? (
                                        this.state.appBarValue === "chat" ? null : (
                                            <Hidden lgUp>
                                                <BottomNavigation className="r2r-bottomNavigation" value={this.props.app.bottomNavState} onChange={this.handleBottomNavChange}>
                                                    <BottomNavigationAction classes={actionClasses} component={Link} to="/" icon={<DashboardIcon />} />
                                                    <BottomNavigationAction classes={actionClasses} component={Link} to={"/searchRide/departureFullAdress=" + 0 + "&arrivalFullAdress=" + 0 + "&departurearrival=" + 0 + "&departureArrivalTimestamp=" + 0} icon={<Search />} />
                                                    <BottomNavigationAction classes={actionClasses} component={Link} to="/addride" icon={<Add />} />
                                                    <BottomNavigationAction classes={actionClasses} component={Link} to="/rides" icon={<DirectionsCar />} />
                                                    <BottomNavigationAction classes={actionClasses} component={Link} to="/profil" icon={<PermIdentity />} />
                                                </BottomNavigation>
                                            </Hidden>
                                        )
                                    ) : null}
                                    {this.renderToast()}
                                    {this.renderGiveRatingDialog()}
                                    {this.renderRideConfirmDialog()}
                                    {this.renderDsgvoDialog()}

                                </React.Fragment>
                            )
                            }
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div >
            );
        } else {
            return <Unauthorized />;
        }
    }
}

const mapStateToProps = state => {
    return {
        app: state.app,
        user: state.user,
    };
};

export default withRouter(connect(mapStateToProps, { setUser })(withStyles(styles)(App)));
