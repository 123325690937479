/**
 * Method that takes two coordinates and return place informations
 * @param {Numbr} lat - latitude coordinate as Number
 * @param {Number} lng - longitute coordinate as Number
 */
export async function reverseGeocode(lat, lng) {

    let houseNumber = 0;
    let postalCode = 0;
    let city = "";
    //let land = ""
    let street = " ";
    let country = "";
    let formatted_address = "";

    await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + process.env.REACT_APP_GOOGLE_API_KEY)
        .then(response => response.json())
        .then(data => {
            if (data.results[0]) {
                formatted_address = data.results[0].formatted_address;
                data.results[0].address_components.forEach(async element => {
                    switch (element.types[0]) {
                        case "street_number": {
                            //console.log(element.long_name)
                            houseNumber = element.long_name;
                            break;
                        }
                        case "route": {
                            //console.log(element.long_name)
                            street = element.long_name;
                            break;
                        }
                        case "locality": {
                            //console.log(element.long_name)
                            city = element.long_name;
                            break;
                        }
                        case "country": {
                            //console.log(element.long_name)
                            country = element.long_name;
                            break;
                        }
                        case "postal_code": {
                            //console.log(element.long_name)
                            postalCode = element.long_name;
                            break;
                        }
                        default: {
                        }
                    }
                });
            }
        });
    // console.log([houseNumber, street, city, country, postalCode, formatted_address])
    return [houseNumber, street, city, country, postalCode, formatted_address];
}


