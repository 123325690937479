import React, { Component } from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DialogContentText, IconButton, Button, TextField } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material/";
import { LoadingTransparentBlocked } from '../Global/Loading'
import { updateRating } from "../../API/REST/index.js";
import { RATING, sendNotification } from '../../util/notification_helper';
import CustomAlertDialog from '../Global/CustomAlertDialog';
import "./GiveRatingDialog.scss";

const StarIconButton = (props) => {
    return <IconButton
        onClick={props.onClick}
        className="giveRatingDialog-iconButton"
        size="large">
        {props.ratingState > props.starIndex ? (
            <Star color="secondary" style={{ height: 40, width: 40 }} />
        ) : (
            <StarBorder
                color="primary.light"
                style={{ height: 40, width: 40 }}
            />
        )}
    </IconButton>
}

class GiveRatingDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogOpen: this.props.isOpen,
            rating: 0,
            ratingValid: "",
            comment: "",
            commentValid: "",
            checked: false
        };
    }

    /**
     * Check that the rating is valid and triggers the following mehtod
     */
    checkRatingValid = () => {
        if (this.state.rating === 0) {
            this.setState({ ratingValid: false });
            this.checkCommentValid();
        } else {
            this.setState({ ratingValid: true });
            this.checkCommentValid();
        }
    };

    /**
     * Check that comment is valid and triggers the handler for setting the comment
     */
    checkCommentValid() {
        if (this.state.comment === "") {
            this.setState({ commentValid: false });
            this.handleSetRating();
        } else {
            this.setState({ commentValid: true });
            this.handleSetRating();
        }
    }

    /**
     * If the comment an the rating is valid, the API will be called for setting the rating
     */
    async handleSetRating() {
        try {
            if (this.state.comment !== "" && this.state.rating !== 0) {
                const data = await updateRating(this.props.ratingId, this.state.rating, this.state.comment)
                switch (data.response.code) {
                    case 200:
                        let t = {
                            user: {
                                _id: this.props.receiver._id,
                                subscribtions: this.props.receiver.subscribtions
                            }
                        }
                        sendNotification([t], RATING)
                        break;
                    default:
                        break;
                }
                this.handleDialogClose();
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Handler for setting the rating
     * @param {number} rating 
     */
    setRating = rating => {
        this.setState({ rating: rating });
    };

    /**
     * Handler for closing the dialog
     */
    handleDialogClose = () => {
        this.setState({ dialogOpen: false });
    };


    /**
     * Change a state value
     * @param {string} name - name of the state
     */
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    /**
     * Change the state value
     * @param {string} name - name of the state
     */
    handleAnonym = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { dialogOpen } = this.state;
        // eslint-disable-next-line no-unused-vars
        const isLogoutDialogOpen = Boolean(dialogOpen);
        if (this.state.loading) {
            return (
                <LoadingTransparentBlocked />
            );
        }
        return (
            <CustomAlertDialog dataTestId="giveRatingDialog-dialog" dialogTitle="Bewertung" dialogContent={
                <React.Fragment>
                    <DialogContentText data-testid="giveRatingDialog-content" >
                        Gib {this.props.firstName} {this.props.lastName} eine Bewertung!
                    </DialogContentText>
                    <div className={this.state.ratingValid === false ? "r2r-display-flex r2r-justify-spaceBetween giveRatingDialog-starBoxInvalid" : "r2r-display-flex r2r-justify-spaceBetween giveRatingDialog-starBox"} >
                        <StarIconButton onClick={() => this.setRating(1)} starIndex={0} ratingState={this.state.rating} />
                        <StarIconButton onClick={() => this.setRating(2)} starIndex={1} ratingState={this.state.rating} />
                        <StarIconButton onClick={() => this.setRating(3)} starIndex={2} ratingState={this.state.rating} />
                        <StarIconButton onClick={() => this.setRating(4)} starIndex={3} ratingState={this.state.rating} />
                        <StarIconButton onClick={() => this.setRating(5)} starIndex={4} ratingState={this.state.rating} />
                    </div>
                    <TextField error={this.state.commentValid === false ? true : false}
                        onChange={this.handleChange("comment")}
                        id="outlined-textarea"
                        label="Kommentar"
                        placeholder="Kommentar"
                        multiline
                        className="giveRatingDialog-commentBox"
                        margin="normal"
                        variant="outlined" />
                    {/* <FormControlLabel label="anonym bewerten"
                        control={
                            <Checkbox
                                checked={this.state.checked}
                                onChange={this.handleAnonym("checked")}
                                value="checked"
                                inputProps={{
                                    "aria-label": "primary checkbox"
                                }}
                            />
                        } /> */}
                </React.Fragment>}
                dialogActions={<Button color="primary" onClick={() => this.checkRatingValid()}>
                    Bewertung abgeben
                </Button>}
                // anchorEl={this.state.dialogOpen}
                open={isLogoutDialogOpen}
                onClose={this.handleDialogClose} />
        );

    }
}

GiveRatingDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    ratingId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    receiver: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, null)(GiveRatingDialog);
