import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Slide, Alert } from '@mui/material'
import { setSnackbar } from '../../Actions/Index.js'
import { connect } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Toast(props) {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])


    const handleClose = (newState) => {
        setOpen(newState)
    }


    return (
        <Snackbar open={open} TransitionComponent={Transition} TransitionProps={{ onExited: () => props.setSnackbar("", false) }} autoHideDuration={props.duration} onClose={() => handleClose(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={() => handleClose(false)} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar >
    );
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'success', "warning"]).isRequired,
    duration: PropTypes.number.isRequired
}

const mapStateToProps = state => {
    return {
        open: state.app.snackbar.open,
    };
};

export default connect(mapStateToProps, { setSnackbar })(Toast);
