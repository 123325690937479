import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Toolbar, IconButton, List, ListItem, ListItemText, Drawer, ListItemIcon } from '@mui/material'
import { Menu, Dashboard, Search, Add, DirectionsCar, PermIdentity } from '@mui/icons-material'
import { setBottomNavState } from '../../Actions/Index.js'
import './CustomDesktopMenu.scss';

class CustomAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false
        }
    }

    render() {
        const { className, sx } = this.props


        const handleDrawerClose = () => {
            this.setState({
                drawerOpen: false
            });
        };

        const toggleDrawer = () => {
            this.setState({
                drawerOpen: !this.state.drawerOpen
            });
        };

        const renderDrawer = () => {
            return (
                <Drawer
                    className="customDestopMenu-drawer"
                    onClose={handleDrawerClose}
                    open={this.state.drawerOpen}>
                    <Toolbar />
                    <List>
                        <ListItem button onClick={() => this.props.setBottomNavState(0)} component={Link} to="/" selected={this.props.app.bottomNavState === 0} >
                            <ListItemIcon><Dashboard /></ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                        <ListItem button onClick={() => this.props.setBottomNavState(1)} component={Link} to={"/searchRide/departureFullAdress=" + 0 + "&arrivalFullAdress=" + 0 + "&departurearrival=" + 0 + "&departureArrivalTimestamp=" + 0} selected={this.props.app.bottomNavState === 1}  >
                            <ListItemIcon><Search /></ListItemIcon>
                            <ListItemText primary={"Suchen"} />
                        </ListItem>
                        <ListItem button onClick={() => this.props.setBottomNavState(2)} component={Link} to="/addride" selected={this.props.app.bottomNavState === 2}  >
                            <ListItemIcon><Add /></ListItemIcon>
                            <ListItemText primary={"Fahrt anlegen"} />
                        </ListItem>
                        <ListItem button onClick={() => this.props.setBottomNavState(3)} component={Link} to="/rides" selected={this.props.app.bottomNavState === 3}  >
                            <ListItemIcon><DirectionsCar /></ListItemIcon>
                            <ListItemText primary={"Fahrtenübersicht"} />
                        </ListItem>
                        <ListItem button onClick={() => this.props.setBottomNavState(4)} component={Link} to="/profil" selected={this.props.app.bottomNavState === 4}  >
                            <ListItemIcon><PermIdentity /></ListItemIcon>
                            <ListItemText primary={"Mein Profil"} />
                        </ListItem>
                    </List>
                </Drawer>
            )
        }

        return (
            <React.Fragment>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    className={className}
                    size="large"
                    sx={sx}
                >
                    <Menu />
                </IconButton>
                {renderDrawer()}
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        app: {
            bottomNavState: state.app.bottomNavState
        }
    };
};

export default withRouter(connect(mapStateToProps, { setBottomNavState })(CustomAppBar));