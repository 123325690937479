const appReducer = (state = { bottomNavState: 0, appBar: { appBarState: "dashboard", additionalData: "" }, snackbar: { open: false, message: "" } }, action) => {
    switch (action.type) {
        case "SETBOTTOMNAV":
            return Object.assign({}, state, {
                bottomNavState: action.payload
            });
        case "SETAPPBARSTATE":

            return Object.assign({}, state, {
                appBar: {
                    ...state.appBar,
                    appBarState: action.payload.appBarValue,
                    additionalData: action.payload.additionalData
                }
            });
        case "SETSNACKBAR":
            return Object.assign({}, state, {
                snackbar: {
                    ...state.snackbar,
                    open: action.payload.open,
                    message: action.payload.message,
                    severity: action.payload.severity
                }
            });
        default:
            return state;
    }
}

export default appReducer;