import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

/**
 * Method requests the API to send a notification to all users
 * @returns {Object} result of the API request
 */
export async function sendNotificationToAllUsers() {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + "pushs/pushToAllSubscribers")
        return response.data
    } catch (error) {
        console.error(error)
    }
}