import React, { Component } from 'react'
import * as ReactDOM from 'react-dom';
//Material UI Components
import { Typography, TextField, Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'

import { Send } from '@mui/icons-material/';

import './Chat.scss';
import ReceivingChatMessage from './ReceivingChatMessage.jsx';
import SendingChatMessage from './SendingChatMessage.jsx';
import io from "socket.io-client";
import { sendChatMessage, getChatMessages } from '../../API/Chat/chatRequests'
import { getChatInfos } from '../../API/REST/index'


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    cssLabel: {
        '&$cssFocused': {
            color: "#ffcc00",
        },
        color: "white"
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: "white"
        },
    },
    cssOutlinedInput: {
        color: "white",
        '&$cssFocused $notchedOutline': {
            borderColor: "white",
        },
    },
    notchedOutline: {

        borderColor: 'white !important',
    },

});

var messagesOldTimestamp = 0;
var socket;

class Chat extends Component {
    constructor(props) {
        super(props);
        socket = io(process.env.REACT_APP_CHAT_PATH);
        this.state = {
            loading: true,
            chatId: this.props.match.params.chatId,
            newMessage: "",
            additionalData: {},
            messages: [

            ]
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(3)
        this.getChatInfos()
        this.getMessages().then(this.createSocket());
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        const { messageList } = this.refs;
        const scrollHeight = messageList.scrollHeight;
        const height = messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    async getChatInfos() {
        this.setState({ loading: true })

        getChatInfos(this.state.chatId).then(result => {
            var driver = {}
            var member = {}
            if (result.member[0]._id !== this.props.user._id) {
                driver = {
                    firstName: result.member[0].firstName,
                    lastName: result.member[0].lastName,
                    imagePath: result.member[0].profilImageId.imagePath
                }
                member = {
                    firstName: result.member[1].firstName,
                    lastName: result.member[1].lastName,
                    imagePath: result.member[1].profilImageId.imagePath
                }
            } else {
                driver = {
                    firstName: result.member[1].firstName,
                    lastName: result.member[1].lastName,
                    imagePath: result.member[1].profilImageId.imagePath
                }
                member = {
                    firstName: result.member[0].firstName,
                    lastName: result.member[0].lastName,
                    imagePath: result.member[0].profilImageId.imagePath
                }
            }

            let additionalData = {
                rideDetails: result.ride,
                driverDetails: driver,
                memberDetails: member
            }

            this.setState({
                additionalData: additionalData
            })

            this.props.setAppBarState("chat", additionalData)
        })

    }

    async createSocket() {
        socket.on(this.state.chatId, message => {
            this.addMsg(message)
        });
    }

    async getMessages() {
        getChatMessages(this.state.chatId, this.props.keycloak.token).then(result => {
            if (result.messages === 0) {

            } else {
                let oldState = this.state.messages
                let newState = oldState.concat(result.messages)
                this.setState({
                    messages: newState
                })
            }
        })



    }

    async sendMessage() {
        var data = {
            chatId: this.state.chatId,
            rideId: this.state.additionalData.rideDetails._id,
            message: this.state.newMessage,
            timestamp: new Date().getTime(),
            sender: {
                userId: this.props.user._id,
            }
        }
        this.setState({
            newMessage: ""
        })
        sendChatMessage(data, this.props.keycloak.token).then(result => {
        })
    }

    addMsg(data) {
        this.setState(prevState => ({
            messages: [...prevState.messages, data],
        }))
    }

    handleChange = name => event => {

        this.setState({ [name]: event.target.value });
    };

    checkDateMessages(date, index) {
        if (index === 0) {
            messagesOldTimestamp = 0
        }
        var currentDate = new Date(date);
        var lastDate = new Date(messagesOldTimestamp);

        if (currentDate.getDate() === lastDate.getDate() && currentDate.getMonth() === lastDate.getMonth()) {
            messagesOldTimestamp = date;
            return false
        } else {
            messagesOldTimestamp = date;
            return true

        }
    }

    handleDateLabel(date) {
        var today = new Date();
        var currentDate = new Date(date);
        if (today.getDate() === currentDate.getDate() && today.getMonth() === currentDate.getMonth()) {
            return "Heute"
        } else {
            return this.formattedDate(date)
        }
    }

    formattedDate(timestamp) {
        var date = new Date(timestamp)
        var day = "0" + date.getDate();
        var month = "0" + (date.getMonth() + 1);
        var year = date.getFullYear();
        var formattedDate = day.substr(-2) + '.' + month.substr(-2) + '.' + year;

        return formattedDate
    }

    render() {
        const { classes } = this.props;


        return (
            <div className="chat-container">
                <div className="chat-area" ref="messageList">
                    {this.state.messages.map((item, key) => (

                        this.checkDateMessages(item.timestamp, key) ?
                            <div>
                                <div className="chat-label">
                                    <Typography variant="subtitle2" >{this.handleDateLabel(item.timestamp)}</Typography>
                                </div>
                                {item.sender.userId === this.props.user._id ? <SendingChatMessage key={key} item={item} member={this.state.additionalData.memberDetails} /> : <ReceivingChatMessage key={key} item={item} driver={this.state.additionalData.driverDetails} />}
                            </div>
                            : <div>
                                {item.sender.userId === this.props.user._id ? <SendingChatMessage key={key} item={item} member={this.state.additionalData.memberDetails} /> : <ReceivingChatMessage key={key} item={item} driver={this.state.additionalData.driverDetails} />}
                            </div>))}

                </div>
                <div className="chat-message-container" ref="messageBox">
                    <div className="chat-message-box">
                        <TextField
                            onChange={this.handleChange("newMessage")}
                            value={this.state.newMessage}
                            id="outlined-multiline-static"
                            multiline
                            label="Nachricht"
                            maxRows="5"
                            className="chat-message-box"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                },
                            }}
                            InputProps={{
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }} />

                    </div>
                    <div className="">
                        <Fab onClick={() => this.sendMessage()} size="medium" color="secondary" aria-label="Send" className="chat-send-button">
                            <Send />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        keycloak: {
            token: state.keycloak.token
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(withStyles(styles)(Chat));
