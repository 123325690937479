import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "rating"

/**
 * Method requests the API to get the open ratings
 * @param {string} id - id of the rating
 * @param {string} token - bearer token
 * @returns {object} result of the API request
 */
export async function getOutstandingRatings(id) {
    try {
        let data = { userId: id };
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/checkOutstandingRatings", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 *  Method requests the API to add a new rating
 * @param {string} sender - id of the sender
 * @param {string} receiver - id of the receiver
 * @param {string} rideId - id of the ride
 * @returns {object} result of the API request
 */
export async function addRating(sender, receiver, rideId) {
    try {

        let data = {
            sender: sender,
            receiver: receiver,
            rideId: rideId
        };
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to update a rating
 * @param {string} ratingId - id of the rating
 * @param {number} stars - number of stars for rating
 * @param {string} comment - comment for the rating
  * @param {string} token - bearer token
 * @returns {object} result of the API request
 */
export async function updateRating(ratingId, stars, comment) {
    try {

        let data = {
            ratingId: ratingId,
            stars: stars,
            outstanding: false,
            comment: comment
        };

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method calls the API to get all received ratings of a user
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getReceivedRatings(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/receivedRatingsByUserId/?receiverId=" + userId);
        return response.data
    } catch (error) {
        console.error(error)
    }
}