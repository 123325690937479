import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Typography } from '@mui/material'
import { connect } from 'react-redux'
import "./pageAvatarHeader.scss";
import CustomGrid from '../CustomGrid';

function PageAvatarHeader(props) {
    return (
        <div className="pah-header r2r-display-flex r2r-container-backgroundcolor avatar-align">
            <CustomGrid className="r2r-display-flex">
                <div className="pah-inner-container">
                    <Avatar className={props.nameTypography ? "avatar avatar-text" : "avatar avatar-noText"} alt="avatar" src={props.user.profilImageId ? process.env.REACT_APP_IMAGE_URL + props.user.profilImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />
                    {props.nameTypography ? <Typography className="header-text" variant="h5"> {props.user.firstName + " " + props.user.lastName}</Typography> : null}
                </div>
            </CustomGrid>
        </div>
    )
}

PageAvatarHeader.propTypes = {
    nameTypography: PropTypes.bool.isRequired
}

PageAvatarHeader.defaultProps = {
    nameTypography: false
}

const mapStateToProps = (state) => {
    return {
        user: {
            profilImageId: state.user.profilImageId,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
        }
    };
};

export default connect(mapStateToProps, null)(PageAvatarHeader);