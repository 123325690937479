export async function sendChatMessage(data, token) {
    const headers = new Headers({
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + this.props.keycloak.token
    });

    const options = {
        method: "POST",
        headers,
        body: JSON.stringify(data),
        mode: "cors"
    }

    let response = await fetch(process.env.REACT_APP_CHAT_PATH + 'chat/addNewMessage', options)
    let responseData = await response.json()


    return responseData

}

export async function getChatMessages(chatId, token) {
    const headers = new Headers({
        "Content-Type": "application/json",
        //"Authorization": "Bearer " + this.props.keycloak.token
    });

    const options = {
        method: "GET",
        headers,
        mode: "cors"
    }

    let response = await fetch(process.env.REACT_APP_CHAT_PATH + 'chat/getMessages/?chatId=' + chatId, options)
    let responseData = await response.json()


    return responseData

}