import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()
const PATH = "carmodel"

/**
 * Method requests the API to get all carmodels that are in de DB
 * @returns {object} result of the API request
 */
export async function getAllCarmodels() {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/");
        return response.data
    } catch (error) {
        console.error(error)
    }
}