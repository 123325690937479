import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Phone, PermIdentity, MoreVert } from '@mui/icons-material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVenus, faMars, faGenderless } from '@fortawesome/free-solid-svg-icons'
import { Typography, IconButton, Menu, MenuItem, DialogContentText, Button, } from '@mui/material';
import { AppRating, CustomGrid, LoadingTransparentBlocked, ViewRating, CustomAppBar, VehicleCard, SectionTypography, PageAvatarHeader } from '../index'
import { getUserData, getVehiclesFromUser, deleteVehicle, updateFavoriteVehicle } from '../../API/REST/index'
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import './MyProfil.scss';
import KeycloakService from '../../util/KeycloakService';
import CustomAlertDialog from '../Global/CustomAlertDialog';

class MyProfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            vehicle: [],
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(4)
        this.requestData();
    }

    requestData() {
        this.getProfileInfo()
        this.pullVehicle()
    }

    async getProfileInfo() {
        try {
            this.setState({ loading: true })
            const data = await getUserData(this.props.user._id)
            switch (data.response.code) {
                case 200:
                    this.setState({ user: data.result.data, loading: false });
                    break;
                case 500:
                    console.error(data)
                    break;
                default:
                    break;
            }

        } catch (error) {
            console.error(error)
        }

    }

    async pullVehicle() {
        try {

            this.setState({ loading: true })
            const data = await getVehiclesFromUser(this.props.user._id)
            switch (data.response.code) {
                case 200:
                    this.setState({ vehicle: data.result.data, loading: false });
                    this.checkSelectedVehicle();
                    break;
                case 500:
                    console.error(data)
                    this.setState({ vehicle: [], loading: false });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
            this.setState({ vehicle: [], loading: false });
        }
    }

    checkSelectedVehicle() {
        for (var i = 0; i < this.state.vehicle.length; i++) {
            if (this.state.vehicle[i].favorite === true) {
                this.setState({ selectedVehicle: i });

            }
        }
    }

    deleteVehicle = async (vehicleId) => {
        this.setState({ loading: true })
        deleteVehicle(this.props.user._id, vehicleId).then(async data => {
            switch (data.response.code) {
                case 200:
                    this.handleFilterDialogClose();
                    await this.pullVehicle();
                    break;
                default:
                    break;
            }
        })
    }

    async putFavoriteVehicle(vehicleId, value) {
        this.setState({ loading: true })
        updateFavoriteVehicle(vehicleId, value).then(async data => {
            switch (data.response.code) {
                case 200:
                    this.handleFilterDialogClose();
                    await this.pullVehicle();
                    break;
                default:
                    break;
            }
        })
    }

    handleOnChangeFavorite = (id) => {
        for (var i = 0; i < this.state.vehicle.length; i++) {
            if (this.state.vehicle[i]._id === id) {
                this.putFavoriteVehicle(this.state.vehicle[i]._id, true);
            } else {
                this.putFavoriteVehicle(this.state.vehicle[i]._id, false);
            }
        }
    }


    renderAppBarHeader() {
        return (
            <CustomAppBar title="Profil">
                <div className="r2r-container-flex-grow" />

                <ViewRating />
                {/*<IconButton
                            component={Link}
                            to="/chats"
                            edge="end"
                            color="inherit"
                            flex="1">
                            <Badge className="" badgeContent={0} color="secondary">
                                <LocalPostOffice />
                            </Badge>
                        </IconButton>*/}
                <AppRating />

                <IconButton
                    aria-haspopup="true"
                    onClick={this.handleMobileMenuOpen}
                    color="inherit"
                    size="large">
                    <MoreVert />
                </IconButton>
            </CustomAppBar>
        );
    }

    handleMobileMenuOpen = event => {
        this.setState({ menueOpen: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ menueOpen: null });
    }

    handleLogoutDialogClose = () => {
        this.setState({ dialogOpen: null });
    }

    handleLogoutDialogOpen = event => {
        this.handleMenuClose();
        this.setState({ dialogOpen: event.currentTarget });
    }

    renderMenu() {
        const isMenuOpen = Boolean(this.state.menueOpen);
        return (
            <Menu anchorEl={this.state.menueOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}>
                <MenuItem component={Link} to="/editUser" onClick={this.handleMenuClose}>Profil bearbeiten</MenuItem>
                <MenuItem component={Link} to="/manageVehicle" onClick={this.handleMenuClose}>Fahrzeuge verwalten</MenuItem>
                {/*  <MenuItem component={Link} to="/feedback" onClick={this.handleMenuClose}>ÖV Bewertung</MenuItem> */}
                <MenuItem onClick={this.handleLogoutDialogOpen}>Abmelden</MenuItem>
            </Menu>
        )
    }

    logout = () => {
        this.handleLogoutDialogClose()
        KeycloakService.doLogout()
    }

    renderLogoutDialog() {
        const isLogoutDialogOpen = Boolean(this.state.dialogOpen)
        return (
            <CustomAlertDialog dialogTitle="Abmelden" dialogContent={
                <DialogContentText> Möchten Sie sich wirklich abmelden?</DialogContentText>
            } dialogActions={<React.Fragment>
                <Button color="secondary" onClick={this.handleLogoutDialogClose}>
                    Zurück
                </Button>
                <Button variant="contained" color="secondary" autoFocus onClick={() => this.logout()}>
                    Abmelden
                </Button>
            </React.Fragment>} anchorEl={this.state.dialogOpen} open={isLogoutDialogOpen} onClose={this.handleLogoutDialogClose} />
            // <Dialog anchorEl={this.state.dialogOpen} open={isLogoutDialogOpen} onClose={this.handleLogoutDialogClose} aria-labelledby="simple-dialog-title">
            //     <DialogTitle id="simple-dialog-title">Abmelden</DialogTitle>
            //     <DialogContent>
            //         <DialogContentText> Möchten Sie sich wirklich abmelden?</DialogContentText>
            //     </DialogContent>
            //     <DialogActions>
            //         <Button color="secondary" onClick={this.handleLogoutDialogClose}>
            //             Zurück
            //         </Button>
            //         <Button variant="contained" color="secondary" autoFocus onClick={() => this.logout()}>
            //             Abmelden
            //         </Button>
            //     </DialogActions>
            // </Dialog>
        )
    }

    render() {
        const renderMyProfil = () => {
            return (
                <React.Fragment>
                    <PageAvatarHeader nameTypography={false} />
                    <CustomGrid>
                        <div className="r2r-container-content r2r-container-content-flex-column">
                            <div className="r2r-contentContainer-element-margin profil-identityInformations">
                                <div className="myProfil-label">
                                    <PermIdentity color="secondary" className="myProfil-icon" /><Typography variant="body1">{this.props.user.firstName + " " + this.props.user.lastName}</Typography>
                                </div>

                                <div className="myProfil-label">
                                    {this.props.user.gender === "männlich" ?
                                        <FontAwesomeIcon icon={faMars} className="myProfil-faIcon" /> :
                                        this.props.user.gender === "weiblich" ?
                                            <FontAwesomeIcon icon={faVenus} className="myProfil-faIcon" /> :
                                            <FontAwesomeIcon icon={faGenderless} className="myProfil-faIcon" />}<Typography variant="body1">{this.props.user.gender}</Typography>
                                </div>

                                <div className="myProfil-label">
                                    <Phone color="secondary" className="myProfil-icon" /><Typography variant="body1">{this.props.user.phoneNumber}</Typography>
                                </div>
                            </div>

                            <div className="r2r-contentContainer-element-margin">
                                {this.state.vehicle.length === 0 ?
                                    <div className="r2r-container-content-flex-column r2r-container-center-align app-messageEmpty-container">
                                        <SectionTypography  >Du hast noch kein Fahrzeug angelegt.</SectionTypography>
                                    </div> :
                                    <React.Fragment>
                                        <SectionTypography  >Favorisiertes Fahrzeug</SectionTypography>
                                        {this.state.vehicle.map((item, key) => (item.favorite ?
                                            <VehicleCard key={key} functional={false} vehicle={item} deleteVehicle={this.deleteVehicle} /> : null
                                        ))}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </CustomGrid>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {this.renderMenu()}
                {this.renderLogoutDialog()}
                {this.state.loading ?
                    <LoadingTransparentBlocked /> :
                    <React.Fragment>
                        {renderMyProfil()}
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(MyProfil);
