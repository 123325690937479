import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
//Material UI Components
import { LoadingTransparentBlocked } from '../Global/Loading'
import { Typography } from '@mui/material'

import ChatChannel from './ChatChannel.jsx';
import './ChatOverview.scss';

import { getChatsbyUserId } from '../../API/REST/index'

class ChatOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,

            channels: [

            ]
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(3)
        this.props.setAppBarState("chatOverview")
        this.getChatChannels()
    }

    async getChatChannels() {

        getChatsbyUserId(this.props.user._id).then(result => {
            this.setState({
                loading: false,
                channels: result.chats
            })
        })

    }

    handlerOpenChat = (data) => {
        this.props.history.push({
            pathname: '/chat/chatId=' + data.chatId
        })
    }


    render() {
        if (this.state.loading) {
            return (<LoadingTransparentBlocked />)
        } else {
            return (
                <React.Fragment>

                    {this.state.channels.length === 0 ? (<div className="chatOverview-text"><Typography className="App  r2r-content-title " variant="h6">Tritt einer Fahrt bei oder biete eine Fahrt an, um zu chatten.</Typography> </div>) : (
                        <React.Fragment>
                            {this.state.channels.map((item, key) => (
                                <React.Fragment>
                                    {item.member[0]._id !== this.props.user._id ?
                                        <ChatChannel handleOpenChat={this.handlerOpenChat} chatId={item._id} image={item.member[0].profilImageId.imagePath} firstName={item.member[0].firstName} lastName={item.member[0].lastName} departureCity={item.ride.placeDeparture.city} arrivalCity={item.ride.placeArrival.city} departureTime={new Date(item.ride.departureTimestamp)} /> :
                                        <ChatChannel handleOpenChat={this.handlerOpenChat} chatId={item._id} image={item.member[1].profilImageId.imagePath} rideId={item.ride._id} firstName={item.member[1].firstName} lastName={item.member[1].lastName} departureCity={item.ride.placeDeparture.city} arrivalCity={item.ride.placeArrival.city} departureTime={new Date(item.ride.departureTimestamp)} />}
                                </React.Fragment>))}</React.Fragment>)}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        keycloak: {
            token: state.keycloak.token
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(ChatOverview);
