const keycloakReducer = (state = {}, action) => {
    switch (action.type) {
        case "SETKEYCLOAK":
            state = action.payload
            return state;

        default:
            return state;

    }
}

export default keycloakReducer;