

const buslines = [
    { value: '1', label: '1' },
    { value: '5', label: '5' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '666', label: '666' },
    { value: '743', label: '743' },
];

export default buslines;