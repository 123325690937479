import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { Typography, Button, Avatar, IconButton, Menu, MenuItem, DialogContentText, FormControlLabel, Checkbox, Stack } from '@mui/material';
// eslint-disable-next-line no-unused-vars
import { StarRate, SpaOutlined, Phone, CheckCircleOutline, HelpOutline, HighlightOff, SupervisorAccount, MoreVert } from '@mui/icons-material/';
import { enterRide, exitRide, getRideDetails, addRating, createTransaction, completeRide, deleteRide, getChatId, getReceivedRatings } from '../../API/REST/index'
import { GET_IN_RIDE, sendNotification } from '../../util/notification_helper';
import { CustomAppBar, VehicleCard, LoadingTransparentBlocked, Attendee, CustomGrid } from '../index'
import { setBottomNavState } from '../../Actions/Index.js'
import { formattedDate, formattedTime } from '../../util/formatting_helper.js'
import SectionTypography from '../Global/sectionTypography/sectionTypography';
import CustomAlertDialog from '../Global/CustomAlertDialog';
import './RideView.scss';


class RideView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rideId: this.props.match.params.rideId,
            driverIsMe: false,
            rideDetails: {},
            driverDetails: {},
            driverImage: "",
            vehicleDetails: {},
            vehicleImage: "",
            attendee: [],

            menuDialogProps: {
                open: false,
                title: "",
                handler: ""
            },
            confirmedAttendees: [],

            ratings: [],
            attend: "",
            minAccept: false,
            member: [],
            chatId: 0,
            loading: true

        }
    }

    async componentDidMount() {
        this.props.setBottomNavState(3)
        await this.getRideDetails(this.state.rideId);
    }

    /**
     * Method to get detailes of the ride
     * @param {string} rideID - id of a ride
     */
    async getRideDetails(rideID) {
        const data = await getRideDetails(rideID)
        switch (data.response.code) {
            case 200:
                if (data.result.attend === null) {
                    this.setState({
                        loading: false
                    });
                } else {
                    let driverState = false
                    if (data.result.data.userId._id === this.props.user._id) {
                        driverState = true
                    }
                    const ratingData = await getReceivedRatings(data.result.data.userId._id)
                    if (ratingData.response.code === 200) {
                        this.setState({
                            ratings: ratingData.result.data,
                            rideDetails: data.result.data,
                            driverIsMe: driverState,
                            driverDetails: data.result.data.userId,
                            driverImage: data.result.data.userId.profilImageId ? data.result.data.userId.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE,
                            vehicleDetails: data.result.data.vehicleId,
                            vehicleImage: data.result.data.vehicleId.vehicleImageId ? data.result.data.vehicleId.vehicleImageId.imagePath : process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE,
                            attendee: data.result.data.attendee,
                            loading: false
                        });
                    } else {
                        this.setState({
                            ratings: [],
                            rideDetails: data.result.data,
                            driverIsMe: driverState,
                            driverDetails: data.result.data.userId,
                            driverImage: data.result.data.userId.profilImageId ? data.result.data.userId.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE,
                            vehicleDetails: data.result.data.vehicleId,
                            vehicleImage: data.result.data.vehicleId.vehicleImageId ? data.result.data.vehicleId.vehicleImageId.imagePath : process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE,
                            attendee: data.result.data.attendee,
                            loading: false
                        });
                    }

                    //this.getChatId();
                    this.checkParticipant();
                    this.minAccept();
                }
                break;
            default:
                break;
        }

    }

    /**
     * 
     */
    async getChatId() {
        getChatId().then(data => {
            switch (data.response.code) {
                case 200:
                    this.setState({
                        chatId: data.result.chat._id
                    });
                    break;

                default:
                    this.setState({
                        chatId: 0
                    });
                    break;
            }
        });
    }

    /**
     * Method change the "attend" state and triggers the "handleEnter()" method
     */
    userStepIn = () => {
        this.setState({ attend: "OPEN" })
        this.handleEnter()
    }

    /** 
     * Method change the "attend" state and triggers the "handleExit()" method
    */
    userStepOut = () => {
        this.setState({ attend: "" })
        this.handleExit()
    }

    /**
     * Method checks a state of all attendees
     */
    checkParticipant() {
        for (var i = 0; i < this.state.attendee.length; i++) {
            if (this.state.attendee[i].user._id === this.props.user._id) {
                this.setState({ attend: this.state.attendee[i].status })
            }
        }
    }

    /**
     * Method calculate the average rating
     * @returns {Number} average rating  
     */
    calculateRating() {
        if (this.state.ratings.length === 0) {
            return 0
        } else {
            var totalRating = 0
            // eslint-disable-next-line no-unused-vars

            for (var i = 0; i < this.state.ratings.length; i++) {
                totalRating = totalRating + this.state.ratings[i].stars
            }
            return Number((totalRating / this.state.ratings.length).toFixed(2))
        }



    }

    /**
     * Method handles the enter process, when a user enters a ride
     */
    async handleEnter() {
        var data = {
            rideId: this.state.rideId,
            newAttendee: {
                status: "OPEN",
                user: this.props.user._id
            },
            member: [
                this.state.driverDetails,
                this.props.user._id
            ],
        };
        enterRide(data).then(data => {
            switch (data.response.code) {
                case 200:
                    let subsribtionObject = {
                        user: {
                            _id: this.state.driverDetails._id,
                            subscribtions: this.state.driverDetails.subscribtions
                        }
                    }
                    sendNotification([subsribtionObject], GET_IN_RIDE, { rideid: this.state.rideId })
                    this.setState({
                        chatId: data.result.newChat
                    })
                    break;
                case 500:
                    break;
                default:
                    break;
            }

        })
    }

    /**
     * Method handles the exit process, when a user left a ride
     */
    async handleExit() {
        var data = {
            rideId: this.state.rideId,
            userId: this.props.user._id,
            member: [
                this.props.user._id,
                this.state.driverDetails
            ],
            chatId: this.state.chatId
        };
        exitRide(data).then(data => {
            switch (data.response.code) {
                case 200:
                    break;
                case 500:
                    break;
                default:
                    break;
            }

        })
    }

    /**
     * 
     */
    minAccept() {
        for (var i = 0; i < this.state.attendee.length; i++) {
            if (this.state.attendee[i].status === "ACCEPT") {
                this.setState({ minAccept: true })
            }
        }
    }

    /**
     * Method checks the current attend state and returns the right content
     * @param { ('ACCEPT'|'OPEN'|'DENIED')} attendState - state of the attendee
     */
    checkAttendState(attendState) {
        switch (attendState) {
            case "ACCEPT": {
                return (
                    <div style={{ backgroundColor: "#aaf542" }} className="r2r-container-content-flex-row r2r-container-center-align rideView-status rideView-margin-bottom">
                        <CheckCircleOutline style={{ color: "white", marginRight: "6" }} />
                        <Typography style={{ color: "white" }} variant="body1">Du fährst mit!</Typography>
                    </div>
                )
            }
            case "OPEN": {
                return (
                    <div style={{ backgroundColor: "#42b6f5" }} className="r2r-container-content-flex-row r2r-container-center-align rideView-status rideView-margin-bottom">
                        <HelpOutline style={{ color: "white", marginRight: "6" }} />
                        <Typography style={{ color: "white" }} variant="body1">Du hast angefragt.</Typography>
                    </div>
                )
            }
            case "DENIED": {
                return (
                    <div style={{ backgroundColor: "#f57542" }} className="r2r-container-content-flex-row r2r-container-center-align rideView-status rideView-margin-bottom">
                        <HighlightOff style={{ color: "white", marginRight: "6" }} />
                        <Typography style={{ color: "white" }} variant="body1">Du wurdest abgelehnt.</Typography>
                    </div>
                )
            }
            default: {
                return null
            }
        }
    }

    /**
     * Method handles the open state of the menu
     * @param {event} event 
     */
    handleMobileMenuOpen = event => {
        this.setState({ menueOpen: event.currentTarget });
    }

    /**
     * Method handles the close state of the menu
     * @param {event} event 
     */
    handleMenuClose = () => {
        this.setState({ menueOpen: null, menuDialogProps: { ...this.state.menuDialogProps, open: false } });
    }

    /**
     * Method to add a Rating to the ride owner
     * @param {string} rideOwner - id of the user which offers the ride
     * @param {string} updatedRide - id of the ride
     * @param {string[]} attendedUser -id(s) of the the users, which have participate the ride
     */
    addRatingForOwner(rideOwner, updatedRide, attendedUser) {
        try {
            attendedUser.forEach(element => {
                if (element.status === "ACCEPT") {
                    addRating(element.user, rideOwner, updatedRide)
                }
            });
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Method to add a Rating to the attended user
     * @param {string} rideOwner - id of the user which offers the ride
     * @param {string} updatedRide - id of the ride
     * @param {string[]} attendedUser -id(s) of the the users, which have participate the ride
     */
    addRatingForAttendees(rideOwner, updatedRide, attendedUser) {
        try {
            attendedUser.forEach(element => {
                if (element.status === "ACCEPT") {
                    addRating(rideOwner, element.user, updatedRide)
                }
            });
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * Method set the state of the ride on finished and calls differend methods to add ratings to the driver and the attendees
     * @param {string} rideId -  id of the ride, which will be finished
     */
    handleEndRide = async (rideId, confirmedAttendees) => {
        try {
            const data = await completeRide(rideId)
            switch (data.response.code) {
                case 200:
                    // if (data.result.attendedUser.length !== 0) {
                    //     this.addRatingForOwner(data.result.rideOwner, data.result.updatedRide, data.result.attendedUser)
                    //     this.addRatingForAttendees(data.result.rideOwner, data.result.updatedRide, data.result.attendedUser)
                    // }

                    const data = await createTransaction(confirmedAttendees, this.props.user._id, rideId)
                    switch (data.response.code) {
                        case 200:
                            this.handleMenuClose()
                            break;
                        default:
                            break;
                    }

                    //TODO: Löschen
                    // updateStats(this.props.user._id, this.props.user.stats.co2Saving + Number(data.result.stats.co2Saving), this.props.user.stats.numberOfTakenPersons, this.props.user.stats.numberOfDrivenWith)
                    break;
                default:
                    break;
            }
            this.handleMenuClose()
            this.props.history.push("/rides")
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Method deletes a ride by the id
     * @param {string} rideId - id of the ride, which will be deleted
     */
    handleDeleteRide = async (rideId) => {
        try {
            const data = await deleteRide(this.props.user._id, rideId)
            switch (data.response.code) {
                case 200:
                    sendNotification(this.state.rideDetails.attendee, "DELETE_RIDE");
                    this.handleMenuClose()
                    break;
                default:
                    this.handleMenuClose()
                    break;
            }
            this.props.history.push("/rides")
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Method renders the menu with all the items depending on if the current user is the driver of the ride or not
     */
    renderMenu() {
        const isMenuOpen = Boolean(this.state.menueOpen);
        if (this.state.driverIsMe) {
            return (
                <Menu anchorEl={this.state.menueOpen}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}>
                    {/* <MenuItem component={Link} to={"/rides"} onClick={() => this.handleEndRide(this.state.rideId)} >Fahrt beenden</MenuItem> */}
                    {/* <MenuItem component={Link} to={"/editRide/" + this.state.rideId} onClick={this.handleMenuClose}>Fahrt bearbeiten</MenuItem>
                    <MenuItem component={Link} to={"/rides"} onClick={() => this.handleDeleteRide(this.state.rideId)}>Fahrt entfernen</MenuItem> */}
                    <MenuItem disabled={this.state.rideDetails.status === "COMPLETED"} onClick={() => { this.checkConfirmed(); this.setState({ menuDialogProps: { open: true, title: "Fahrt beenden", handler: "END" }, menueOpen: false }) }}>Fahrt beenden</MenuItem>
                    <MenuItem disabled={this.state.rideDetails.status === "COMPLETED"} component={Link} to={"/editRide/" + this.state.rideId} onClick={this.handleMenuClose}>Fahrt bearbeiten</MenuItem>
                    <MenuItem disabled={this.state.rideDetails.status === "COMPLETED"} onClick={() => { this.checkConfirmed(); this.setState({ menuDialogProps: { open: true, title: "Fahrt löschen", handler: "DELETE" }, menueOpen: false }) }}>Fahrt entfernen</MenuItem>
                </Menu >
            )
        } else {
            return (
                <Menu anchorEl={this.state.menueOpen}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}>
                    <MenuItem onClick={this.handleMenuClose}>Fahrt melden</MenuItem>
                </Menu>
            )
        }

    }

    /**Method renders the header app bar */
    renderAppBarHeader() {
        if (this.state.driverIsMe) {
            return (
                <CustomAppBar title="Fahrtdetails">
                    <div className="r2r-container-flex-grow" />
                    {/*<IconButton
                            component={Link}
                            to="/chats"
                            color="inherit">
                            <LocalPostOffice />
                        </IconButton>*/}

                    <IconButton
                        aria-haspopup="true"
                        onClick={this.handleMobileMenuOpen}
                        color="inherit"
                        size="large">
                        <MoreVert />
                    </IconButton>
                </CustomAppBar>
            );
        } else {
            return (
                <CustomAppBar title="Fahrtdetails">
                    <div className="r2r-container-flex-grow" />
                    {/* <IconButton
                            component={Link}
                            to="/chats"
                            color="inherit">
                            <LocalPostOffice />
                        </IconButton>*/}
                    <IconButton
                        aria-haspopup="true"
                        onClick={this.handleMobileMenuOpen}
                        color="inherit"
                        size="large">
                        <MoreVert />
                    </IconButton>
                </CustomAppBar>
            );
        }
    }

    checkConfirmed = async () => {
        const acceptedAttendees = this.state.rideDetails.attendee.filter(attendee => attendee.status === "ACCEPT")
        let data = await acceptedAttendees.map(attendee => {
            return {
                ...attendee,
                confirmed: false
            }
        })

        this.setState({
            confirmedAttendees: data
        })

    }

    renderMenuDialog = () => {
        const { open, title, handler } = this.state.menuDialogProps;

        const buttonHandler = () => {
            switch (handler) {
                case "END":
                    const confirmedAttendees = this.state.confirmedAttendees.filter(attendee => attendee.confirmed).map(attendee => attendee.user._id)
                    this.handleEndRide(this.state.rideId, confirmedAttendees)
                    break;
                case "DELETE":
                    this.handleDeleteRide(this.state.rideId)
                    break;
                default:
                    break;
            }
        }

        const renderCheckboxes = () => {

            if (this.state.confirmedAttendees.length > 0) {
                return this.state.confirmedAttendees.map((attendee, index) => {
                    return (
                        <FormControlLabel
                            // value="start"
                            // control={<Checkbox />}
                            control={<Checkbox checked={attendee.confirmed} onChange={(event) => {
                                let previousArray = this.state.confirmedAttendees
                                previousArray[index].confirmed = event.target.checked
                                this.setState({
                                    confirmedAttendees: previousArray
                                })
                            }} />}
                            label={attendee.user.firstName + " " + attendee.user.lastName}
                            labelPlacement="start"
                        />
                    )
                })
            }
        }

        const dialogContent = () => {
            switch (handler) {
                case "END":
                    return (
                        <React.Fragment><DialogContentText data-testid="rideview-menudialog-content" >
                            Möchtest du die Fahrt wirklich beenden? Diese Aktion kann nicht rückgängig gemacht werden. Die Punkte werden dir nach der bestätigung deiner Mitfahrer gutgeschrieben. Die Mitfahrer werden über diese aktion informiert.
                        </DialogContentText>
                            <DialogContentText data-testid="rideview-menudialog-content" >
                                Wer ist bei dir mitgefahren?
                            </DialogContentText>
                            <Stack spacing={2}>
                                {renderCheckboxes()}
                            </Stack>
                        </React.Fragment>)

                case "DELETE":
                    return (<DialogContentText data-testid="rideview-menudialog-content" >
                        Möchtest du die Fahrt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden. Dir werden keine Punkte gutgeschrieben. Die Mitfahrer werden über diese aktion informiert.
                    </DialogContentText>)

                default:
                    break;
            }
        }

        return <CustomAlertDialog open={open}
            dialogTitle={title}
            onClose={() => this.setState({
                menuDialogOpen: {
                    ...this.state.menuDialogProps,
                    open: false
                }
            })} dialogContent={
                <DialogContentText data-testid="rideview-menudialog-content" >
                    {dialogContent()}
                </DialogContentText>
            }
            dialogActions={
                <React.Fragment>
                    <Button color="primary" onClick={() => this.setState({ menuDialogProps: { ...this.state.menuDialogProps, open: false } })}>
                        Abbrechen
                    </Button>
                    <Button color="primary" onClick={() => buttonHandler()}>
                        {title}
                    </Button>
                </React.Fragment>
            } />
    }

    renderRideView = () => {
        return (
            <CustomGrid>
                <div className="r2r-container-content">
                    <div className="r2r-contentContainer-element-margin ">

                        {this.state.driverIsMe ? null : this.checkAttendState(this.state.attend)}

                        <div className="r2r-display-flex rideView-flex-date r2r-content-title">
                            <div className="r2r-display-flex rideView-flex-date-left">
                                <Typography variant="h5">{formattedDate(this.state.rideDetails.departureTimestamp)}</Typography>
                            </div>
                            <div className="r2r-display-flex rideView-flex-date-right">
                                <SpaOutlined style={{ height: 20, width: 20, color: "green" }} />
                                <Typography variant="body1">{this.state.rideDetails.routeDetails.co2emissions}g CO2</Typography>
                            </div>
                        </div>

                        <div className="r2r-display-flex rideView-flex-ride-info">

                            <div className="r2r-display-flex rideView-flex-ride-info-left">
                                <Typography variant="body1" >{formattedTime(this.state.rideDetails.departureTimestamp)} Uhr</Typography>
                            </div>

                            <div id="svg_container" className="r2r-display-flex rideView-flex-ride-info-mid">
                                <svg width="30px" height="100%" preserveaspectration="none">
                                    <line x1="50%" y1="20%" x2="50%" y2="100%" stroke="black" strokeWidth="2" />
                                    <circle cx="50%" cy="20%" r="7" stroke="black" strokeWidth="2" fill="white" />
                                </svg>
                            </div>

                            <div className="r2r-display-flex rideView-flex-ride-info-right">
                                <div className="">
                                    <Typography id="rideview-departurePlace" data-testid="test-departurePlace" variant="body1">
                                        {this.state.rideDetails.placeDeparture.street} {this.state.rideDetails.placeDeparture.houseNumber === 0 ? null : this.state.rideDetails.placeDeparture.houseNumber}
                                        <br />
                                        {this.state.rideDetails.placeDeparture.postalCode + " " + this.state.rideDetails.placeDeparture.city}
                                    </Typography>
                                </div>

                            </div>
                        </div>

                        <div className="r2r-display-flex rideView-flex-ride-info">
                            <div className="r2r-display-flex rideView-flex-ride-info-left">
                                <Typography variant="body1" className="r2r-typography-bold">{formattedTime(this.state.rideDetails.arrivalTimestamp)} Uhr</Typography>
                            </div>

                            <div className="r2r-display-flex rideView-flex-ride-info-mid">
                                <svg width="30px" height="100%">
                                    <line x1="50%" y1="20%" x2="50%" y2="0%" stroke="black" strokeWidth="2" />
                                    <circle cx="50%" cy="20%" r="7" stroke="black" strokeWidth="2" fill="black" />
                                </svg>
                            </div>

                            <div className="r2r-display-flex rideView-flex-ride-info-right">
                                <div className="">
                                    <Typography id="rideview-arrivalPlace" data-testid="test-arrivalPlace" variant="body1" className="r2r-typography-bold">
                                        {this.state.rideDetails.placeArrival.street} {this.state.rideDetails.placeArrival.houseNumber === 0 ? null : this.state.rideDetails.placeArrival.houseNumber}
                                        <br />
                                        {this.state.rideDetails.placeArrival.postalCode + " " + this.state.rideDetails.placeArrival.city}
                                    </Typography>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="r2r-contentContainer-element-margin">
                        <div className="r2r-display-flex rideView-flex-driver">
                            <div className="r2r-display-flex r2r-content-title">
                                <Typography variant="h5">{this.state.driverDetails.firstName + " " + this.state.driverDetails.lastName}</Typography>
                            </div>
                            <div className="r2r-display-flex rideView-flex-driver-content">
                                <div className="rideView-flex-driver-left">


                                    <div className="r2r-display-flex rideView-flex-driver-left-rating">
                                        <div className="rideView-flex-driver-left-icon">
                                            <StarRate color="secondary" />
                                        </div>
                                        <Typography variant="body1">{this.calculateRating()}/5 ({this.state.ratings.length} Bewertungen)</Typography>
                                    </div>


                                    <div className="r2r-display-flex rideView-flex-driver-left-seats">
                                        <div className="rideView-flex-driver-left-icon">
                                            <SupervisorAccount color="secondary" />
                                        </div>
                                        <Typography variant="body1">{this.state.rideDetails.vehicleSpace} {this.state.rideDetails.vehicleSpace === 1 ? "Platz" : "Plätze"}</Typography>
                                    </div>

                                    {this.state.driverIsMe ?
                                        <div className="r2r-display-flex rideView-flex-driver-left-phone">
                                            <div className="rideView-flex-driver-left-icon">
                                                <Phone color="secondary" />
                                            </div>
                                            <Typography variant="body1">{this.state.driverDetails.phoneNumber} </Typography>
                                        </div> :
                                        this.state.attend === "ACCEPT" ?
                                            <div className="r2r-display-flex rideView-flex-driver-left-phone">
                                                <div className="rideView-flex-driver-left-icon">
                                                    <Phone color="secondary" />
                                                </div>
                                                <Typography variant="body1">{this.state.driverDetails.phoneNumber} </Typography>
                                            </div>
                                            : null}
                                </div>

                                {this.state.attend === "ACCEPT" ?
                                    <Avatar className="rideView-avatar" alt="avatar" src={process.env.REACT_APP_IMAGE_URL + this.state.driverImage} />
                                    : null}

                            </div>
                        </div>
                        {this.state.rideDetails.rideDescription === "" ? null :
                            <div className="rideView-flex-description ">
                                <SectionTypography>Beschreibung</SectionTypography>
                                <Typography variant="body1">{this.state.rideDetails.rideDescription} </Typography>
                            </div>
                        }
                    </div>
                    {this.state.driverIsMe ?
                        <div className="r2r-contentContainer-element-margin">
                            <div className="rideView-flex-attendees">
                                <SectionTypography>Fahrzeug</SectionTypography>
                                <div className="rideView-flex-showCar">
                                    <VehicleCard vehicle={this.state.vehicleDetails} functional={false} />
                                </div>
                            </div>
                        </div> : this.state.attend === "ACCEPT" ?
                            <div className="r2r-contentContainer-element-margin">
                                <div className="rideView-flex-attendees">
                                    <SectionTypography>Fahrzeug</SectionTypography>
                                    <div className="rideView-flex-showCar">
                                        <VehicleCard vehicle={this.state.vehicleDetails} functional={false} />
                                    </div>
                                </div>
                            </div> : null}

                    <div className=" rideView-padding-content">

                        <React.Fragment>
                            <div className="rideView-flex-attendees">
                                <SectionTypography>Mitfahrer</SectionTypography>
                                {this.state.driverIsMe ?
                                    this.state.attendee.length === 0 ?
                                        <div className="r2r-display-flex rideView-message-box">
                                            <Typography color="secondary" variant="subtitle1" className="rideView-message" >Es fährt noch keiner mit!</Typography>
                                        </div> :
                                        this.state.attendee.map((attendee, index) => (
                                            <Attendee key={attendee} ridestatus={this.state.rideDetails.status} departuretimestamp={this.state.rideDetails.departureTimestamp} driverIsMe={this.state.driverIsMe} attendee={attendee} rideId={this.state.rideDetails._id} />)) :
                                    this.state.minAccept ?
                                        this.state.attendee.map((attendee, index) => (
                                            attendee.status === "ACCEPT" ?
                                                <Attendee key={attendee} ridestatus={this.state.rideDetails.status} departuretimestamp={this.state.rideDetails.departureTimestamp} driverIsMe={this.state.driverIsMe} attendee={attendee} rideId={this.state.rideDetails._id} />
                                                : null)) :
                                        <div className="r2r-display-flex rideView-message-box">
                                            <Typography color="secondary" variant="subtitle1" className="rideView-message" >Es fährt noch keiner mit!</Typography>
                                        </div>}
                            </div>

                            {this.state.driverIsMe ? null :
                                this.state.attend === "OPEN" || this.state.attend === "ACCEPT" ?
                                    <div className="rideView-attend-button">
                                        <Button variant="contained" color="primary" className="app-button" onClick={() => { this.userStepOut() }}>
                                            AUSSTEIGEN
                                        </Button>
                                    </div>
                                    : this.state.attend === "DENIED" ?
                                        <div />
                                        : <div className="rideView-attend-button">
                                            <Button variant="contained" color="secondary" className="app-button" onClick={() => { this.userStepIn() }}>
                                                EINSTEIGEN
                                            </Button>
                                        </div>
                            }
                        </React.Fragment>
                    </div>
                </div >
            </CustomGrid >
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderMenuDialog()}
                {this.renderAppBarHeader()}
                {this.renderMenu()}
                {this.state.loading ? <LoadingTransparentBlocked /> :
                    <React.Fragment>
                        {this.renderRideView()}
                    </React.Fragment>
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps, { setBottomNavState })(RideView);