import React from "react";
import image_404 from "../../Images/ausfahrt_404.svg";
import { CustomAppBar } from "..";
import "./error_404.scss";
import { Typography, Grid } from "@mui/material";

const ErrorNotFound = () => {

    const renderAppBarHeader = () => {
        return (
            <CustomAppBar title="">
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    return (
        <React.Fragment>
            {renderAppBarHeader()}
            < Grid data-testid="errorNotFound-dialog" className="error404_container" container >
                <Grid className="error404_description" item xs={12} sm={6}>
                    <Typography variant="h5" align="center">Oops! Da ist wohl etwas schief gelaufen...</Typography>
                    < Typography className="error404_body" variant="body1" align="center" > Diese Seite ist nicht verfügbar.</Typography >
                </Grid >
                <Grid className="error404_image" item xs={12} sm={6}>
                    <img src={image_404} alt="this is a dead_end" />
                </Grid>
            </Grid >
        </React.Fragment>
    );
}

export default (ErrorNotFound);
