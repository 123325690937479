import React, { Component } from "react";
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { setBottomNavState, setAppBarState, setSnackbar } from '../../Actions/Index.js'
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Typography, Switch, FormControlLabel, Button, Checkbox, Fab } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ArrowRight, ArrowLeft, Cached, GpsFixed, LocationOn } from "@mui/icons-material/";
import { getVehiclesFromUser, addRide, addRides, addRoutine } from "../../API/REST/index";
import { checkRequiredFields } from "../../util/validation_helper.js";
import { reverseGeocode } from "../../util/googleApi_helper.js";
import { CustomGrid, CustomAppBar, LoadingTransparentBlocked, SectionTypography, CustomAutocomplete, CustomDateTimePicker } from "../index.js";
import NoVehicleComp from "./NoVehicleComp.jsx";
import RideDescriptionComp from "./RideDescriptionComp.jsx";
import AdditionalRideInformation from "./AdditionalRideInformation.jsx";
import VehicleSelectorComp from "./VehicleSelectorComp.jsx";
import OpenSeats from "./OpenSeats.jsx";
import "./CreateRide.scss";

const styles = theme => ({
    multilineColor: {
        color: "white"
    },

    cssLabel: {
        color: "white"
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "white !important"
    }
});

class CreateRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            loadingAdd: false,
            vehicle: [],
            selectedVehicleId: "",
            checkedRoutine: false,
            checkedReturn: false,
            numberSeats: 1,
            checkedSmoking: false,
            checkedAllergy: false,
            repeats: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            },
            startPoint: {
                address: ""
            },
            endPoint: {
                address: ""
            },
            placeDeparture: {},
            placeArrival: {},
            departureTimestamp: new Date().getTime() + 600000,
            returnJournesTimestamp: new Date().getTime() + 1200000,
            selectedDateOutward: new Date().getTime() + 600000,
            selectedDateReturn: new Date().getTime() + 1200000,
            description: "",
            weeklyRepetitions: 2,
            errors: {
                startPlaces: false,
                endPlaces: false,
                vehicle: false,
                departureTime: false,
                arrivalTimestamp: false
            },
            currentLocation: {
                departure: {
                    disableGetPosition: false
                },
                arrival: {
                    disableGetPosition: false
                }
            }
        };
    }

    componentDidMount() {
        this.props.setBottomNavState(2)
        this.requestData();
    }

    /**
     * Handler for requesting data
     */
    requestData() {
        this.getVehicles(this.props.user._id);
    }

    /**
     * Requests the API for the vehicles of a user
     * @param {string} userId - id of the user
     */
    async getVehicles(userId) {
        try {
            const data = await getVehiclesFromUser(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        vehicle: data.result.data,
                        loading: false
                    });
                    break;

                default:
                    this.setState({
                        vehicle: [],
                        loading: false
                    });
                    break;
            }
        } catch (error) {
            console.error(error)
            this.setState({
                vehicle: [],
                loading: false
            });
        }
        this.checkSelectedVehicle();
    }

    /**
     * handler for changeing the state of the repetition weekdays
     * @param {string} name - name of the weekday
     */
    handleOnChangeDay = name => event => {
        if (this.state.repeats[name] === true) {
            this.setState({
                repeats: {
                    ...this.state.repeats,
                    [name]: false
                }
            });
        } else {
            this.setState({
                repeats: {
                    ...this.state.repeats,
                    [name]: true
                }
            });
        }
    };

    /**
     * handler for changeing the return routine 
     */
    handleReturnOnChangeRoutine = () => {
        if (this.state.checkedRoutine !== true) {
            this.setState({ checkedReturn: false });
        }
    };

    /**
   * handler for changeing the departure date
   * @param {number} date 
   */
    handleDateChange = date => {
        try {


            this.setState({
                selectedDateOutward: date,
                departureTimestamp: date.getTime()
            });

        } catch (error) {
            console.error(error)

            this.setState({
                selectedDateOutward: new Date().getTime(),
                departureTimestamp: new Date().getTime(),
                errors: {
                    ...this.state.errors,
                    departureTime: true
                }
            })
        }

    };

    /**
     * handler for changeing the return date
     * @param {number} date 
     */
    handleDateChangeReturn = date => {
        try {
            if (date < this.state.selectedDateOutward) {
                this.setState({
                    selectedDateReturn: date,
                    returnJournesTimestamp: date.getTime()
                });
            }

            if (date < this.state.selectedDateOutward) {
                this.setState({
                    selectedDateReturn: new Date().getTime() + 600000,
                    returnJournesTimestamp: new Date().getTime(),
                    errors: {
                        ...this.state.errors,
                        arrivalTimestamp: true
                    }
                });
                return
            }
            if (date > this.state.selectedDateOutward && this.state.errors.arrivalTimestamp) {
                this.setState({
                    selectedDateReturn: date,
                    returnJournesTimestamp: date.getTime(),
                    errors: {
                        ...this.state.errors,
                        arrivalTimestamp: false
                    }
                });
                return
            }
            this.setState({
                selectedDateReturn: date,
                returnJournesTimestamp: date.getTime(),
            });
        } catch (error) {
            console.error(error)
            this.setState({
                selectedDateReturn: new Date().getTime(),
                returnJournesTimestamp: new Date().getTime(),
                errors: {
                    ...this.state.errors,
                    arrivalTimestamp: true
                }
            })
        }


    };

    /**
 * handler for changeing the checkbox depending on the name of the state
 * @param {string} name - name of the state
 */
    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
        if (name === "checkedRoutine") {
            this.handleReturnOnChangeRoutine();
        }
    };

    /**
   * handler for decrement the number of seats
   */
    handleIncremenetSeats = () => {
        if (this.state.numberSeats < 8) {
            this.setState({
                numberSeats: this.state.numberSeats + 1
            });
        }
    };

    /**
     * handler for incrementing the number of seats
     */
    handleDecrementSeats = () => {
        if (this.state.numberSeats !== 0) {
            this.setState({
                numberSeats: this.state.numberSeats - 1
            });
        }
    };

    /**
     * handler for incrementing the repetitions
     */
    handleIncrementRepetitions = () => {
        if (this.state.weeklyRepetitions < 12) {
            this.setState({
                weeklyRepetitions: this.state.weeklyRepetitions + 1
            });
        }
    };

    /**
     * handler for decrementing the repetitions
     */
    handleDecrementRepetitions = () => {
        if (this.state.weeklyRepetitions !== 1) {
            this.setState({
                weeklyRepetitions: this.state.weeklyRepetitions - 1
            });
        }
    };

    // change of routine
    handleChangeRoutine = event => {
        this.setState({ routineType: event.target.value });
    };

    /**
     * Handler for changeing the state depending of the state name
     * @param {string} name - name of the state
     */
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    /**
     * Handler for changeing the number of repetitions
     * @param {event} event 
     */
    handleChangeRoutineNumber = event => {
        this.setState({ routineNumber: event.target.value });
    };

    /**
     * Handler for creating a ride
     * @param {event} e 
     */
    handleOnCreate = e => {
        let validationObject = [
            {
                type: "STRING",
                value: this.state.startPoint.address,
                errorIndex: 0
            },
            {
                type: "STRING",
                value: this.state.endPoint.address,
                errorIndex: 1
            },
            {
                type: "STRING",
                value: this.state.selectedVehicleId,
                errorIndex: 2
            },
            {
                type: "TIMESTAMP",
                value: new Date(this.state.selectedDateOutward).getTime(),
                errorIndex: 3,
            },
            {
                type: "TIMESTAMP",
                value: new Date(this.state.selectedDateOutward).getTime(),
                errorIndex: 4,
            }]
        checkRequiredFields(validationObject).then(data => {
            if (data.errorAvailable === false) {
                this.checkRoutines();
            } else {
                e.preventDefault();
                this.props.setSnackbar("Bitte alle Felder befüllen!", true, "error")
                this.setState({
                    errors: {
                        startPlaces: data.errorArray[0],
                        endPlaces: data.errorArray[1],
                        vehicle: data.errorArray[2],
                        departureTime: data.errorArray[3],
                        arrivalTimestamp: data.errorArray[4]
                    }
                });
            }
        });
    };

    /**
   * Handler for the departure place selection
   * @param {string} address - address of the arrival place
   */
    handleSelectStart = address => {
        geocodeByAddress(address.description)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(data => {
                    this.setState({
                        startPoint: {
                            ...this.state.startPoint,
                            address: address.description,
                            addressLat: latLng.lat,
                            addressLng: latLng.lng
                        },
                        placeDeparture: {
                            ...this.state.placeDeparture,
                            formattedAddress: data[5],
                            city: data[2],
                            postalCode: data[4],
                            houseNumber: data[0],
                            street: data[1],
                            country: data[3],
                            state: ""
                        }
                    });
                });
            }).catch(error => console.error(error));
    }

    /**
    * Handler for locating the users departure place
    * @param {number[]} latlng 
    */
    handleLocateUserStart = (latlng) => {
        reverseGeocode(latlng.lat, latlng.lng).then(data => {
            this.handleSelectStart({ description: data[5] })
        })
    }

    /**
     * Handler for locating the users arrival place
     * @param {number[]} latlng 
     */
    handleLocateUserEnd = (latlng) => {
        reverseGeocode(latlng.lat, latlng.lng).then(data => {
            this.handleSelectEnd({ description: data[5] })
        })
    }

    /**
     * Handler for the arrival place selection
     * @param {string} address - address of the arrival place
     */
    handleSelectEnd = address => {
        geocodeByAddress(address.description)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                reverseGeocode(latLng.lat, latLng.lng).then(data => {
                    this.setState({
                        endPoint: {
                            ...this.state.endPoint,
                            address: address.description,
                            addressLat: latLng.lat,
                            addressLng: latLng.lng
                        },
                        placeArrival: {
                            ...this.state.placeArrival,
                            formattedAddress: data[5],
                            city: data[2],
                            postalCode: data[4],
                            houseNumber: data[0],
                            street: data[1],
                            country: data[3],
                            state: ""
                        }
                    });
                });
            }).catch(error => console.error("Error", error));
    }

    /**
     * Calculates the timestamps for routine rides depending on the dinal departure time of the initial ride
     * @param {number} weekdayIndex - index of the weekday --> monday = 1, tuesday = 2 and so on
     * @param {number} timestamp - timestamp of the ride
     */
    async calculateTimestamps(weekdayIndex, timestamp) {
        let returnArray = []
        let t = new Date(JSON.parse(JSON.stringify(timestamp)))
        t.setDate(t.getDate() + (7 + weekdayIndex - t.getDay()) % 7)
        t.setHours(t.getHours())
        t.setMinutes(t.getMinutes());
        returnArray.push(t.getTime());
        for (let index = 1; index < this.state.weeklyRepetitions; index++) {
            let newTime = new Date(t.setDate(t.getDate() + 7))
            returnArray.push(newTime.getTime())
        }
        return returnArray
    }

    /**
     * Prepares the timestamps for routine rides depending on the dinal departure time of the initial ride
     */
    async prepareTimestampsForRoutine() {
        var routineTimestamps = []
        var timestamp = new Date(JSON.parse(JSON.stringify(this.state.selectedDateOutward)))
        for (var property in this.state.repeats) {
            switch (property) {
                case "monday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(1, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "tuesday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(2, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "wednesday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(3, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "thursday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(4, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "friday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(5, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "saturday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(6, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                case "sunday":
                    if (this.state.repeats[property]) {
                        this.calculateTimestamps(7, timestamp).then(data => {
                            routineTimestamps.push(data)
                        })
                    }
                    break;
                default:
                    break;
            }
        }

        return routineTimestamps
    }

    /**
     * Check if the user want to create a routine
     */
    async checkRoutines() {
        try {
            if (this.state.checkedRoutine) {
                const preparedRides = await this.prepareTimestampsForRoutine()
                const newRideIds = await this.saveNewRides(preparedRides.flat())
                if (newRideIds.length > 0) {
                    this.saveNewRoutine(newRideIds)
                }
            } else {
                if (this.state.checkedReturn) {
                    const preparedRides = await this.prepareRidesForReturn()
                    const data = await addRides(preparedRides)
                    switch (data.response.code) {
                        case 200:
                            this.setState({ loadingAdd: false, redirect: true });
                            break;
                        default:
                            console.error("Error while adding a ride: ", data)
                            break;
                    }
                } else {
                    this.saveNewRide();
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    /**
     * Create a array of ride objects 
     * @returns {object[]}
     */
    async prepareRidesForReturn() {
        let ridesArray = []
        for (let index = 0; index < 2; index++) {
            if (index === 0) {
                let data = {
                    placeDeparture: {
                        formattedAddress: this.state.placeDeparture.formattedAddress,
                        city: this.state.placeDeparture.city,
                        postalCode: this.state.placeDeparture.postalCode,
                        houseNumber: this.state.placeDeparture.houseNumber,
                        street: this.state.placeDeparture.street,
                        country: this.state.placeDeparture.country,
                        state: "",
                    },
                    departureLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.startPoint.addressLng,
                            this.state.startPoint.addressLat
                        ]
                    },
                    placeArrival: {
                        formattedAddress: this.state.placeArrival.formattedAddress,
                        city: this.state.placeArrival.city,
                        postalCode: this.state.placeArrival.postalCode,
                        houseNumber: this.state.placeArrival.houseNumber,
                        street: this.state.placeArrival.street,
                        country: this.state.placeArrival.country,
                        state: "",
                    },
                    arrivalLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.endPoint.addressLng,
                            this.state.endPoint.addressLat
                        ]
                    },


                    longitudeArrival: this.state.endPoint.addressLng,
                    latitudeArrival: this.state.endPoint.addressLat,
                    departureTimestamp: this.state.departureTimestamp,
                    arrivalTimestamp: 0,
                    status: "START",

                    rideDescription: this.state.description,
                    vehicleId: this.state.selectedVehicleId,
                    vehicleSpace: this.state.numberSeats,
                    userId: this.props.user._id,
                    attendee: [],
                    filter: {
                        smoker: this.state.checkedSmoking,
                        allergy: this.state.checkedAllergy,
                        rideType: "PRIVATE",
                    }
                }
                ridesArray.push(data)
            } else {
                let data = {
                    placeDeparture: {
                        formattedAddress: this.state.placeArrival.formattedAddress,
                        city: this.state.placeArrival.city,
                        postalCode: this.state.placeArrival.postalCode,
                        houseNumber: this.state.placeArrival.houseNumber,
                        street: this.state.placeArrival.street,
                        country: this.state.placeArrival.country,
                        state: "",
                    },
                    departureLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.endPoint.addressLng,
                            this.state.endPoint.addressLat
                        ]
                    },
                    placeArrival: {
                        formattedAddress: this.state.placeDeparture.formattedAddress,
                        city: this.state.placeDeparture.city,
                        postalCode: this.state.placeDeparture.postalCode,
                        houseNumber: this.state.placeDeparture.houseNumber,
                        street: this.state.placeDeparture.street,
                        country: this.state.placeDeparture.country,
                        state: "",
                    },
                    arrivalLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.startPoint.addressLng,
                            this.state.startPoint.addressLat
                        ]
                    },
                    longitudeArrival: this.state.startPoint.addressLng,
                    latitudeArrival: this.state.startPoint.addressLat,
                    departureTimestamp: this.state.returnJournesTimestamp,
                    status: "START",
                    rideDescription: this.state.description,
                    vehicleId: this.state.selectedVehicleId,
                    vehicleSpace: this.state.numberSeats,
                    userId: this.props.user._id,
                    attendee: [],
                    filter: {
                        smoker: this.state.checkedSmoking,
                        allergy: this.state.checkedAllergy,
                        rideType: "PRIVATE",
                    }
                }
                ridesArray.push(data)
            }
        }
        return ridesArray
    }

    /**
     * Call the API to create a new Ride
     */
    async saveNewRide() {
        this.setState({ loadingAdd: true })
        let newRide = {
            placeDeparture: {
                formattedAddress: this.state.placeDeparture.formattedAddress,
                city: this.state.placeDeparture.city,
                postalCode: this.state.placeDeparture.postalCode,
                houseNumber: this.state.placeDeparture.houseNumber,
                street: this.state.placeDeparture.street,
                country: this.state.placeDeparture.country,
                state: "",
            },
            departureLocation: {
                type: "Point",
                coordinates: [
                    this.state.startPoint.addressLng,
                    this.state.startPoint.addressLat
                ]
            },
            placeArrival: {
                formattedAddress: this.state.placeArrival.formattedAddress,
                city: this.state.placeArrival.city,
                postalCode: this.state.placeArrival.postalCode,
                houseNumber: this.state.placeArrival.houseNumber,
                street: this.state.placeArrival.street,
                country: this.state.placeArrival.country,
                state: "",
            },
            arrivalLocation: {
                type: "Point",
                coordinates: [
                    this.state.endPoint.addressLng,
                    this.state.endPoint.addressLat
                ]
            },
            departureTimestamp: this.state.departureTimestamp,
            rideDescription: this.state.description,
            vehicleId: this.state.selectedVehicleId,
            vehicleSpace: this.state.numberSeats,
            userId: this.props.user._id,
            attendee: [],
            filter: {
                smoker: this.state.checkedSmoking,
                allergy: this.state.checkedAllergy,
                rideType: "PRIVATE",
            }
        }

        const data = await addRide(newRide)
        switch (data.response.code) {
            case 200:
                this.setState({ loadingAdd: false, redirect: true });
                this.props.setSnackbar("Fahrt angelegt", true, "success")
                break;
            default:
                this.setState({ loadingAdd: false });
                this.props.setSnackbar("Ein fehler ist aufgetreten. Die Fahrt konnte nicht angelegt werden", true, "error")
                console.error("Error while adding a ride: ", data)
                break;
        }
    }

    /**
     * Call the API to create new rides
     * @param {object[]} newTimestamp 
     * @returns {string[]}
     */
    async saveNewRides(newTimestamp) {
        try {
            this.setState({ loadingAdd: true })
            var newRides = newTimestamp.map(element => {
                return {
                    placeDeparture: {
                        formattedAddress: this.state.placeDeparture.formattedAddress,
                        city: this.state.placeDeparture.city,
                        postalCode: this.state.placeDeparture.postalCode,
                        houseNumber: this.state.placeDeparture.houseNumber,
                        street: this.state.placeDeparture.street,
                        country: this.state.placeDeparture.country,
                        state: "",
                    },
                    departureLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.startPoint.addressLng,
                            this.state.startPoint.addressLat
                        ]
                    },
                    placeArrival: {
                        formattedAddress: this.state.placeArrival.formattedAddress,
                        city: this.state.placeArrival.city,
                        postalCode: this.state.placeArrival.postalCode,
                        houseNumber: this.state.placeArrival.houseNumber,
                        street: this.state.placeArrival.street,
                        country: this.state.placeArrival.country,
                        state: "",
                    },
                    arrivalLocation: {
                        type: "Point",
                        coordinates: [
                            this.state.endPoint.addressLng,
                            this.state.endPoint.addressLat
                        ]
                    },
                    departureTimestamp: element,
                    rideDescription: this.state.description,
                    vehicleId: this.state.selectedVehicleId,
                    vehicleSpace: this.state.numberSeats,
                    userId: this.props.user._id,
                    attendee: [],
                    filter: {
                        smoker: this.state.checkedSmoking,
                        allergy: this.state.checkedAllergy,
                        rideType: "PRIVATE",
                    }
                }
            })

            const data = await addRides(newRides)
            switch (data.response.code) {
                case 200:
                    this.props.setSnackbar("Fahrten angelegt.", true, "success")
                    var newRideIds = data.result.data;
                    this.setState({ loadingAdd: true });
                    return newRideIds;
                default:
                    this.props.setSnackbar("Ein fehler ist aufgetreten. Die Fahrt konnte nicht angelegt werden", true, "error")
                    console.error("Error while adding rides: ", data)
                    return []
            }
        } catch (error) {
            this.props.setSnackbar("Ein fehler ist aufgetreten. Die Fahrt konnte nicht angelegt werden", true, "error")
            return []
        }
    }

    /**
     * Call the API to create a new routine
     * @param {string[]} ids - ids of the created rides
     */
    async saveNewRoutine(ids) {
        try {
            this.setState({
                loadingAdd: true
            })

            var newRoutine = {
                repeats: this.state.repeats,
                rides: ids,
                user: this.props.user._id,
                departureTime: this.state.departureTime,
            };

            const data = await addRoutine(newRoutine)
            switch (data.response.code) {
                case 200:
                    this.props.setSnackbar("Routine angelegt!", true, "success")
                    this.setState({ loadingAdd: false, redirect: true })
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.setState({ loadingAdd: false })
            // this.props.setSnackbar("Ein fehler ist aufgetreten. Die Fahrt konnte nicht angelegt werden", true, "error")
            console.error(error)
        }
    }

    /**
     * Check, if a vehicle is defined as favorite
     */
    checkSelectedVehicle() {
        this.state.vehicle.forEach(vehicle => {
            if (vehicle.favorite === true) {
                this.setState({ selectedVehicleId: vehicle._id });
            }
        })
    };

    /**
     * Handle for vehicle change
     */
    changeVehicle = (id) => {
        this.setState({ selectedVehicleId: id });
    }

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Fahrt anlegen">
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    render() {
        const renderDateTimePicker = (
            <div className="r2r-contentContainer-element-margin" >
                <SectionTypography> Wann fährst du los?</SectionTypography>
                <div className="r2r-container-content-flex-column">
                    <div className="r2r-container-content-flex-row r2r-container-center-align ">
                        <Typography className="createRide-flex-grow" variant="body1">
                            Hinfahrt
                        </Typography>
                        <div className="createRide-flex-grow">
                            <div className="r2r-container-content-flex-row r2r-container-center-align ">

                                <FormControlLabel label="Rückfahrt"
                                    control={<Checkbox disabled={this.state.checkedRoutine === false ? false : true} checked={this.state.checkedReturn}
                                        onChange={this.handleCheckboxChange("checkedReturn")}
                                        value="checkedA" />} />
                            </div>
                        </div>
                    </div>

                    <div className={this.state.checkedReturn === true ? "r2r-container-content-flex-row" : ""}>
                        <div className="r2r-container-content-flex-column createRide-felxgrow">
                            <CustomDateTimePicker value={this.state.selectedDateOutward} onChange={() => this.handleDateChange} error={this.state.errors.departureTime} />

                        </div>
                        {this.state.checkedReturn === true ? (
                            <div className="r2r-container-content-flex-column createRide-felxgrow">
                                <CustomDateTimePicker value={this.state.selectedDateReturn} onChange={() => this.handleDateChangeReturn} error={this.state.errors.arrivalTimestamp} />

                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )

        const renderNumberOfPeople = (
            <OpenSeats openSeats={this.state.numberSeats} onIncrement={this.handleIncremenetSeats} onDecrement={this.handleDecrementSeats} />
        )

        const renderVehicleSelector = (
            <VehicleSelectorComp vehicles={this.state.vehicle} changeVehicle={this.changeVehicle} selectedVehicleId={this.state.selectedVehicleId} />
        )

        const renderRideDescription = (
            <RideDescriptionComp description={this.state.description} onChange={this.handleChange("description")} />
        )

        const renderAdditionalInformations = (
            <AdditionalRideInformation valueSmoking={this.state.checkedSmoking} valueAllergy={this.state.checkedAllergy} onChangeSmoking={this.handleCheckboxChange("checkedSmoking")} onChangeAllergy={this.handleCheckboxChange("checkedAllergy")} />
        )

        const renderCreateRide = () => {
            if (this.state.loading) {
                return (<LoadingTransparentBlocked />)
            }
            if (this.state.redirect) {
                return <Redirect to="/rides" />;
            }
            if (this.state.vehicle.length > 0) {
                return (
                    <div>
                        {/* <div className="r2r-container-header r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column"> */}
                        <div className=" r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column">
                            <CustomGrid className="r2r-container-header-margin">
                                <CustomAutocomplete
                                    id="startpunkt"
                                    className=""
                                    locateUser={true}
                                    handleLocateUser={this.handleLocateUserStart}
                                    disableGetPosition={this.state.currentLocation.departure.disableGetPosition}
                                    error={this.state.errors.startPlaces}
                                    placeholder="Wähle deinen Startpunkt!"
                                    label="Startpunkt"
                                    required={true}
                                    icon={<GpsFixed color="secondary" />}
                                    address={this.state.startPoint.address}
                                    handleSelect={this.handleSelectStart} />
                                <CustomAutocomplete
                                    id="endpoint"
                                    className=""
                                    locateUser={false}
                                    handleLocateUser={this.handleLocateUserEnd}
                                    disableGetPosition={this.state.currentLocation.arrival.disableGetPosition}
                                    error={this.state.errors.endPlaces}
                                    placeholder="Wähle dein Ziel!"
                                    required={true}
                                    label="Endpunkt"
                                    icon={<LocationOn color="secondary" />}
                                    address={this.state.endPoint.address}
                                    handleSelect={this.handleSelectEnd} />
                            </CustomGrid>
                        </div>
                        <CustomGrid>
                            <div className="r2r-container-content">

                                {renderDateTimePicker}
                                {renderVehicleSelector}
                                {renderNumberOfPeople}
                                {renderRideDescription}
                                {renderAdditionalInformations}

                                <div className="r2r-contentContainer-element-margin">
                                    <FormControlLabel label="Routinefahrt erstellen" control={
                                        <Switch
                                            checked={this.state.checkedRoutine}
                                            onChange={this.handleCheckboxChange("checkedRoutine")}
                                            value="checkedA"
                                        />
                                    }
                                    />

                                </div>

                                {this.state.checkedRoutine === true ? (
                                    <React.Fragment>
                                        <div className="r2r-contentContainer-element-margin">
                                            <SectionTypography>An welchen Wochentagen fährst du?</SectionTypography>
                                            <div className="r2r-container-content-flex-row createRide-center-between">
                                                <Fab onClick={this.handleOnChangeDay("monday")} color={this.state.repeats.monday === false ? "primary" : "secondary"} variant="extended" size="small">M</Fab>
                                                <Fab onClick={this.handleOnChangeDay("tuesday")} color={this.state.repeats.tuesday === false ? "primary" : "secondary"} variant="extended" size="small">D</Fab>
                                                <Fab onClick={this.handleOnChangeDay("wednesday")} color={this.state.repeats.wednesday === false ? "primary" : "secondary"} variant="extended" size="small">M</Fab>
                                                <Fab onClick={this.handleOnChangeDay("thursday")} color={this.state.repeats.thursday === false ? "primary" : "secondary"} variant="extended" size="small">D</Fab>
                                                <Fab onClick={this.handleOnChangeDay("friday")} color={this.state.repeats.friday === false ? "primary" : "secondary"} variant="extended" size="small">F</Fab>
                                                <Fab onClick={this.handleOnChangeDay("saturday")} color={this.state.repeats.saturday === false ? "primary" : "secondary"} variant="extended" size="small">S</Fab>
                                                <Fab onClick={this.handleOnChangeDay("sunday")} color={this.state.repeats.sunday === false ? "primary" : "secondary"} variant="extended" size="small">S</Fab>
                                            </div>
                                        </div>
                                        <div className="r2r-contentContainer-element-margin">

                                            <SectionTypography> Anzahl Wiederholungen (wochen)?</SectionTypography>
                                            <div className="createRide-container-content-flex-row-center">
                                                <Cached style={{ height: 84, width: 84 }} color="secondary" />
                                                <ArrowLeft className="r2r-pointer" style={{ height: 48, width: 48 }} onClick={this.handleDecrementRepetitions} color="primary">
                                                    {" "}
                                                </ArrowLeft>
                                                <Typography variant="h4">{" "}{this.state.weeklyRepetitions}{" "}</Typography>
                                                <ArrowRight className="r2r-pointer" style={{ height: 48, width: 48 }} onClick={this.handleIncrementRepetitions} color="primary">
                                                    {" "}
                                                </ArrowRight>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : null}
                                <div className="r2r-contentContainer-element-margin">
                                    <Button onClick={this.handleOnCreate} className="app-btn-secondary">
                                        {" "}Los Gehts{" "}
                                    </Button>
                                </div>
                            </div>
                        </CustomGrid>
                    </div>
                )
            } else {
                return (
                    <NoVehicleComp />
                );

            }
        }

        return (
            <React.Fragment>
                {this.renderAppBarHeader()}

                {this.state.loadingAdd ?
                    <LoadingTransparentBlocked /> :
                    <React.Fragment>
                        {renderCreateRide()}
                    </React.Fragment>
                }
            </React.Fragment >
        );

    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setAppBarState, setBottomNavState, setSnackbar })(withStyles(styles)(CreateRide));