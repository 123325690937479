import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { IssueTracker } from '..'

function CustomDialogAppBar(props) {
    return (
        <AppBar data-testid="customDialogAppBar-appbar" className="r2r-customdialog-appbar">
            <Toolbar>
                <IconButton
                    className={"r2r-appbar-iconbutton"}
                    color="inherit"
                    onClick={props.onClickHandler}
                    aria-label="Close"
                    size="large">
                    <Close />
                </IconButton>
                <Typography variant="h6" color="inherit">{props.title}</Typography>
                {props.children}
                <div className="r2r-container-flex-grow" />
                <IssueTracker />
            </Toolbar>
        </AppBar>
    );
}

CustomDialogAppBar.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClickHandler: PropTypes.func
}

CustomDialogAppBar.defaultProps = {
    title: ""
}

export default CustomDialogAppBar

