/* eslint-disable array-callback-return */
import { sendPushNotificationToUsers } from "../API/Push/pushRequests";
import { addNotificationToUsers } from "../API/REST/index";

const OPTION_TYPES = { RIDE: "RIDE", GENERAL: "GENERAL", MESSAGE: "MESSAGE", RATING: "RATING" }
export const EDIT_RIDE = "EDIT_RIDE"
export const GET_IN_RIDE = "GET_IN_RIDE"
export const GET_OUT_RIDE = "GET_OUT_RIDE"
export const RATING = "RATING"
export const EDIT_CAR = "EDIT_CAR"
export const REQUEST_REJECTED = "REQUEST_REJECTED"
export const REQUEST_ACCEPTED = "REQUEST_ACCEPTED"
export const REQUEST = "REQUEST"

export async function sendNotification(attandees, option, additionalInformation) {
    try {
        if (attandees.length > 0) {
            var subscriberIds = await concatAllSubscriberIds(attandees);
            var userIds = await concatAllUserIds(attandees);
            var data = await dataHelper(option, userIds, additionalInformation)

            sendNotifications(subscriberIds, data);
            addUserNotification(data)
        }
    } catch (error) {
        console.error(error)
    }
}

async function sendNotifications(subscriberIds, requestData) {
    try {
        const dataPush = await sendPushNotificationToUsers(subscriberIds, requestData.message, {})
        switch (dataPush.response.code) {
            case 200:
                // console.log(result);
                break;
            default:
                console.error("Ungültiger code");
                break;
        }
    } catch (error) {
        console.error(error)
    }
}


async function addUserNotification(requestData) {
    try {
        const dataNot = await addNotificationToUsers(requestData)
        switch (dataNot.response.code) {
            case 200:
                // console.log(result);
                break;
            default:
                console.error("Ungültiger code");
                break;
        }
    } catch (error) {
        console.error(error)
    }
}

async function concatAllSubscriberIds(attendeeArray) {
    let resultArray = [];
    await attendeeArray.map(attendee => {
        resultArray = concat(resultArray, attendee.user.subscribtions);
    });
    return resultArray
}

async function concatAllUserIds(attendeeArray) {
    let resultArray = [];
    await attendeeArray.map(key => {
        resultArray = concat(resultArray, key.user._id);
    });
    return resultArray
}

function concat(result, data) {
    return result.concat(data);
}

async function dataHelper(option, userIds, additionalInformation) {
    let data = {};
    switch (option) {
        case EDIT_RIDE: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Fahrt geändert",
                message: "Eine Fahrt an der du teilnimmst, wurde geändert!",
                additionalInformation
            }
            break;
        }
        case "DELETE_RIDE": {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Fahrt gelöscht",
                message: "Eine Fahrt an der du teilnimmst oder die du angefragt hast, wurde gelöscht!",
                additionalInformation: {
                    test: "test"
                }
            }
            break;
        }
        case "SEND_MESSAGE": {
            data = {
                userId: userIds[0],
                type: "MESSAGE",
                title: "Neue Nachricht",
                message: "Du hast eine neue Nachticht erhalten!",
                additionalInformation: {
                    test: "test"
                }
            }
            break;
        }
        case EDIT_CAR: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Fahrzeug geändert",
                message: "Ein Fahrzeug in dem du mitfährst hat sich geändert!",
                additionalInformation: {}
            }
            break;
        }
        case "APP_UPDATE": {
            data = {
                userId: userIds[0],
                type: "GENERAL",
                title: "Update",
                message: "Die App wurde upgedatet!",
                additionalInformation: {
                    test: "test"
                }
            }
            break;
        }
        case REQUEST: {
            data = {
                userId: userIds[0],
                type: "GENERAL",
                title: "Anfrage erhalten",
                message: "Du hast eine neue Anfrage erhalten!",
                additionalInformation: {
                    test: "test"
                }
            }
            break;
        }
        case REQUEST_REJECTED: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Anfrage abgelehnt",
                message: "Deine Anfrage wurde abgelehnt!",
                additionalInformation
            }
            break;
        }
        case REQUEST_ACCEPTED: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Anfrage angenommen",
                message: "Deine Anfrage wurde angenommen!",
                additionalInformation
            }
            break;
        }
        case GET_IN_RIDE: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Eingestiegen",
                message: "Du hast einen neuen Mitfahrer!",
                additionalInformation
            }
            break;
        }
        case GET_OUT_RIDE: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RIDE,
                title: "Ausgestiegen",
                message: "Du hast einen Mitfahrer verloren!",
                additionalInformation: {
                    test: "test"
                }
            }
            break;
        }
        case RATING: {
            data = {
                userId: userIds[0],
                type: OPTION_TYPES.RATING,
                title: "Neue Bewertung",
                message: "Du hast eine Bewertung erhalten!",
                additionalInformation: {}
            }
            break;
        }
        default: {
            break;
        }
    }
    return data;

}
