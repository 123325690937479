import React, { Component } from 'react';

//Material UI Components
import { Divider, Typography, Avatar } from '@mui/material';
import { LoadingTransparentBlocked } from '../Global/Loading'
import './ChatChannel.scss';

class ChatChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {

    }

    handlePassData = (e) => {
        var rideDataObject = {
            chatId: this.props.chatId,
        }
        this.props.handleOpenChat(rideDataObject)
    }

    render() {
        if (this.state.loading) {
            return (<LoadingTransparentBlocked />)
        } else {
            return (
                <div onClick={this.handlePassData} className="r2r-container-content-flex-column">
                    <div className="r2r-container-content-flex-row chatChannel-container  chatChannel-space-between">
                        <div className="r2r-container-content-flex-row">
                            <Avatar className="chatChannel-image" alt="avatar" src={process.env.REACT_APP_IMAGE_URL + this.props.image} />
                            <div>
                                <Typography variant="subtitle1" className="chatChannel-text r2r-typography-bold">{this.props.firstName + " " + this.props.lastName}</Typography>
                                <div className="r2r-container-content-flex-row">
                                    <Typography variant="body2" className="chatChannel-text">{this.props.departureCity + " >> " + this.props.arrivalCity}</Typography>
                                </div>

                                <Typography variant="body2" className="chatChannel-text" > {this.props.departureTime.toLocaleString('de-DE', { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric' }) + " Uhr"}</Typography>
                            </div>
                        </div >
                        <div className="r2r-container-content-flex-column chatChannel-space-between">
                            <Typography variant="body2" className="chatChannel-text">09:30</Typography>
                            <div className="chatChannel-badge">4</div>
                        </div>
                    </div>
                    <Divider className="chatChannel-devider" />
                </div>
            );
        }
    }
}

export default ChatChannel;
