import React, { Component } from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography, Card, CardContent } from '@mui/material';
import { CheckCircleOutline, HelpOutline, HighlightOff } from '@mui/icons-material/';
import { Redirect } from "react-router-dom";
import { formattedTime } from '../../util/formatting_helper'

import './AttendedRide.scss';

class AttendedRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            status: "",
        }
        this.handleRedirect = this.handleRedirect.bind(this)
    }

    componentDidMount() {
        this.checkStatus();
    }

    checkStatus() {
        for (var i = 0; i < this.props.item.attendee.length; i++) {
            if (this.props.item.attendee[i].user === this.props.user) {
                this.setState({ status: this.props.item.attendee[i].status });
            }
        }
    }

    handleRedirect() {
        this.setState({ redirect: true })
    }

    renderCheckStatus(valueStatus) {
        switch (valueStatus) {
            case "OPEN": {
                return (
                    <div className="movingOffer-rating">
                        <HelpOutline color="secondary" />
                    </div>
                )
            }
            case "ACCEPT": {
                return (
                    <div className="movingOffer-rating">
                        <CheckCircleOutline color="secondary" />
                    </div>
                )
            }
            case "DENIED": {
                return (
                    <div className="movingOffer-rating">
                        <HighlightOff color="secondary" />
                    </div>
                )
            }
            default: {
                return null
            }
        }
    }

    render() {
        if (this.state.redirect === true) {
            return (
                <Redirect to={{
                    pathname: '/ridedetails/rideId=' + this.props.item._id,
                    state: { rideId: this.props.item._id }
                }} />)
        }
        return (
            <React.Fragment>
                <Card data-testid="attendedRide-card" onClick={this.handleRedirect} className="rides-card-margin r2r-pointer">
                    <CardContent className="rides-card">
                        <div className="attendedRide-flex-outer">
                            <div className="attendedRide-flex-pic">
                                <Avatar className="attendedRide-flex-pic" style={{ height: 50, width: 50 }} alt="avatar" src={this.props.item.userId.profilImageId ? process.env.REACT_APP_IMAGE_URL + this.props.item.userId.profilImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE} />
                            </div>
                            <div className="attendedRide-flex-right">
                                <div className="r2r-display-flex attendedRide-flex-right-name  movingOffer-firstLine">
                                    <Typography data-testid="attendetRide-attendeeName" variant="subtitle2" className="r2r-typography-bold">{this.props.item.userId.firstName} {this.props.item.userId.lastName}</Typography>
                                    {this.renderCheckStatus(this.state.status)}
                                </div>
                                <div className="attendedRide_wrapper">
                                    <div className="attendedRide-flex-right-text">
                                        <Typography data-testid="attendetRide-departurePlace" variant="body2">{this.props.item.placeDeparture.street + " " + this.props.item.placeDeparture.houseNumber + " " + this.props.item.placeDeparture.postalCode + " " + this.props.item.placeDeparture.city}</Typography>
                                    </div>
                                    <div className="attendedRide-rideTime-div">
                                        <Typography data-testid="attendetRide-departureTimestamp" variant="body2">{formattedTime(this.props.item.departureTimestamp)} Uhr</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default AttendedRide;
