import keycloakReducer from './KeycloakReducer';
import userReducer from './UserReducer';
import appReducer from './AppReducer';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    user: userReducer,
    keycloak: keycloakReducer,
    app: appReducer
})

export default allReducers;