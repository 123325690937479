import React, { Component } from 'react';
import { CircularProgress, Typography } from '@mui/material';

import './Loading.scss'

class Loading extends Component {
    render() {
        //const { classes } = this.props;
        return (
            <div data-testid="loading-div" className="loading">
                <CircularProgress color="primary" />
            </div>

        );
    }
}

class LoadingTransparentBlocked extends Component {
    render() {
        //const { classes } = this.props;
        return (
            <div data-testid="loading-div" className="loading-transparent-blocked">
                <CircularProgress color="primary" />
            </div>

        );
    }
}

class LoadingVehicle extends Component {
    render() {
        //const { classes } = this.props;
        return (
            <div data-testid="loading-div" className="r2r-container-content-flex-column app-messageEmpty-container r2r-container-center-align app-progressContainer">
                <CircularProgress color="secondary" />
                <Typography style={{ color: '#FFFFFF' }} className="loading-typography" variant="body2">
                    Fahrzeug wird erstellt...
                </Typography>
            </div>

        );
    }
}
export { Loading, LoadingVehicle, LoadingTransparentBlocked };
//export default withStyles()(Loading);

