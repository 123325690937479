import React from 'react'
import { Typography } from '@mui/material'
import { WifiOff } from '@mui/icons-material'
import { CustomGrid, CustomAppBar } from '../..'
import './Networkerror.scss'

export default function Networkerror() {

    const renderAppBar = () => {
        return (
            <CustomAppBar title="">
                <div className="r2r-container-flex-grow" />

            </CustomAppBar>
        )
    }

    return (
        <React.Fragment>
            {renderAppBar()}
            <CustomGrid data-testid="networkerror-grid" className="r2r-display-flex r2r-justify-center networkerror-container">

                <WifiOff style={{ fontSize: 100 }} />
                <Typography className="networkerror-text" align="center" variant="body1" color="inherit">Es liegt ein Problem mit der Internetverbindung vor. Dies kann daran liegen, dass das WLAN oder die mobilen Daten deaktiviert sind. Überprüfe deine Einstellungen</Typography>

            </CustomGrid>
        </React.Fragment>
    )
}
