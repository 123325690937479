import React from 'react'
import PropTypes from 'prop-types'
import SectionTypography from '../Global/sectionTypography/sectionTypography'
import { Checkbox, FormControlLabel } from '@mui/material'

function AdditionalRideInformation(props) {
    return (
        <div data-testid="additionalRideInformation-div" className="r2r-contentContainer-element-margin">
            <SectionTypography>Weitere Angaben zu deiner Fahrt</SectionTypography>
            <div>
                <FormControlLabel label="In meinem Auto wird geraucht." control={
                    <Checkbox onChange={props.onChangeSmoking} checked={props.valueSmoking} />} />
                <FormControlLabel label="Mein Auto ist nicht für Allergiker (Tierhaarallergie) geeignet." control={
                    <Checkbox onChange={props.onChangeAllergy} checked={props.valueAllergy} />} />
            </div>
        </div>
    )
}

AdditionalRideInformation.propTypes = {
    onChangeSmoking: PropTypes.func.isRequired,
    onChangeAllergy: PropTypes.func.isRequired,
    valueSmoking: PropTypes.bool.isRequired,
    valueAllergy: PropTypes.bool.isRequired
}

export default AdditionalRideInformation