import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import SectionTypography from '../Global/sectionTypography/sectionTypography'

function NoVehicleComp(props) {
    return (
        <div data-testid="novehiclecomp-div" className="r2r-container-content r2r-container-content-flex-column r2r-container-center-align app-messageEmpty-container">
            <SectionTypography>Bevor du eine Fahrt erstellen kannst, musst du ein Fahrzeug hinterlegen.</SectionTypography>
            <Button component={Link}
                to="/manageVehicle"
                variant="contained"
                color="secondary">
                {" "}Fahrzeug anlegen{" "}
            </Button>
        </div>
    )
}

NoVehicleComp.propTypes = {

}

export default NoVehicleComp
