import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import { AppBar, Tab, Tabs, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Commute, DirectionsCar } from '@mui/icons-material/';
import { LoadingTransparentBlocked, AttendedRide, UserRide, RideHistory, CustomGrid, CustomAppBar } from '../index'
import { getOwnRides, getAttendedRides } from '../../API/REST/index.js';
import { formattedDate } from '../../util/formatting_helper';
import SectionTypography from '../Global/sectionTypography/sectionTypography.jsx';
import './ManageRides.scss';


function TabContainer(props) {
    if (props.value === props.index) {
        return (
            <div className="r2r-display-flex rides-tabcontainer" role="tabpanel" id={`simple-tabpanel-${props.index}`} aria-labelledby={`simple-tab-${props.index}`} >
                {props.children}
            </div>
        )
    } else {
        return (<React.Fragment />)
    }
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
};

const styles = theme => ({
    appBar: {
        position: 'relative',
        zindex: "1000"
    },
    title: {
        flex: 1,
    },
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        // backgroundColor: theme.palette.background.paper,
    },
});

var userRidesOldTimestamp = 0;
var attendedRidesOldTimestamp = 0;

class ManageRides extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userRides: [],
            attendedRides: [],
            value: 0,
            loading: true,
            rideHisroyOpen: false
        };
    };

    componentDidMount() {
        this.props.setBottomNavState(3)
        this.requestData()
    }

    async requestData() {
        await this.getOwnRides(this.props.user._id);
        await this.getAttendedRides(this.props.user._id);
    }

    /**
       * Method requests the own rides
     * @param {string} userId - id of the user
     */
    async getOwnRides(userId) {
        try {
            this.setState({ loading: true })
            const data = await getOwnRides(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        userRides: data.result.rides,
                        loading: false,
                    });
                    break;
                default:
                    this.setState({
                        loading: false,
                    });
                    break;
            }
        } catch (error) {
            console.error(error);
        }


    }

    /**
     * Method requests the attended rides
     * @param {string} userId - id of the user
     */
    async getAttendedRides(userId) {
        try {
            this.setState({ loading: true })
            const data = await getAttendedRides(userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        attendedRides: data.result.rides,
                        loading: false,
                    });
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }

    }

    /**
     * 
     */
    handleChange = (event, value) => {
        if (value === 0) {
            this.setState({ value });
            this.getAttendedRides(this.props.user._id)
        } else {
            this.setState({ value });
            this.getOwnRides(this.props.user._id);
        }
    };


    checkDateUserRides(date, index) {
        if (index === 0) {
            userRidesOldTimestamp = 0
        }
        var currentDate = new Date(date);
        var lastDate = new Date(userRidesOldTimestamp);

        if (currentDate.getDate() === lastDate.getDate() && currentDate.getMonth() === lastDate.getMonth()) {
            userRidesOldTimestamp = date;
            return false
        } else {
            userRidesOldTimestamp = date;
            return true
        }
    }


    checkDateattendedRides(date, index) {
        if (index === 0) {
            attendedRidesOldTimestamp = 0
        }
        var currentDate = new Date(date);
        var lastDate = new Date(attendedRidesOldTimestamp);

        if (currentDate.getDate() === lastDate.getDate() && currentDate.getMonth() === lastDate.getMonth()) {
            attendedRidesOldTimestamp = date;
            return false
        } else {
            attendedRidesOldTimestamp = date;
            return true
        }
    }

    checkDateLabel(date) {
        var today = new Date();
        var currentDate = new Date(date);
        if (today.getDate() === currentDate.getDate() && today.getMonth() === currentDate.getMonth()) {
            return "Heute"
        } else {
            return formattedDate(date)
        }
    }

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Fahrtenübersicht">
                <div className="r2r-container-flex-grow" />
                <RideHistory />
                {/* <IconButton
                            component={Link}
                            to="/chats"
                            edge="end"
                            color="inherit"
                            flex="1">
                            <LocalPostOffice />
                </IconButton>*/}
            </CustomAppBar>
        );
    }

    handleRideHistoryOpenState = (open) => {
        this.setState({
            rideHisroyOpen: open
        })
    }

    render() {
        const { classes } = this.props;


        const renderUserRides = () => {
            if (this.state.userRides.length > 0) {
                return (
                    <CustomGrid>
                        <div className="r2r-container-content">
                            {this.state.userRides.map((ride, key) => (
                                <React.Fragment key={key} >
                                    {this.checkDateUserRides(ride.departureTimestamp, key) ?
                                        <React.Fragment>
                                            <SectionTypography>{this.checkDateLabel(ride.departureTimestamp)}</SectionTypography>
                                            <UserRide rideObject={ride} />
                                        </React.Fragment>
                                        : <UserRide rideObject={ride} />}
                                </React.Fragment>))}
                        </div> </CustomGrid>
                )
            } else {
                return (<CustomGrid className="r2r-display-flex" >
                    <div className="r2r-display-flex r2r-justify-center rides-emptyText ">
                        <SectionTypography> In diesem Bereich siehst du deine eigenen Fahrten. Bisher hast du noch keine Fahrten angelegt.</SectionTypography>
                        <Button component={Link} to="/addride" variant="contained" color="secondary" className={classes.button}> Fahrt anlegen </Button>
                    </div>
                </CustomGrid>)
            }
        }

        const renderAttendedRides = () => {
            if (this.state.attendedRides.length > 0) {
                return (
                    <CustomGrid>
                        <div className="r2r-container-content">
                            {this.state.attendedRides.map((ride, key) => (
                                <React.Fragment key={key}>
                                    {this.checkDateattendedRides(ride.departureTimestamp, key) ?
                                        <React.Fragment>
                                            <SectionTypography>{this.checkDateLabel(ride.departureTimestamp)}</SectionTypography>
                                            <AttendedRide item={ride} user={this.props.user._id} />
                                        </React.Fragment>
                                        : <AttendedRide item={ride} user={this.props.user._id} />}
                                </React.Fragment>))}
                        </div>
                    </CustomGrid>
                )
            } else {
                return (
                    <CustomGrid className="r2r-display-flex" >
                        <div className=" r2r-display-flex r2r-justify-center rides-emptyText ">
                            <SectionTypography>In diesem Bereich siehst du Fahrten an denen du teilnimmst.</SectionTypography>
                            <Button component={Link} to={"/searchRide/departureFullAdress=" + 0 + "&arrivalFullAdress=" + 0 + "&departurearrival=" + 0 + "&departureArrivalTimestamp=" + 0} variant="contained" color="secondary" className={classes.button}> Mitfahrgelegenheit suchen </Button>
                        </div>
                    </CustomGrid>)
            }
        }

        const renderManageRides = () => {
            return (
                <React.Fragment>

                    <AppBar className="rides-appbar" position="static" color="default"  >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            variant="fullWidth"
                            scrollButtons
                            indicatorColor="secondary"
                            textColor="primary"
                            width="100%"
                            allowScrollButtonsMobile>
                            <Tab data-testid="rides-attendeedride-tab" style={{ width: "50%" }} label="Mitfahren" icon={<Commute />} />
                            <Tab data-testid="rides-userride-tab" style={{ width: "50%" }} label="Meine Fahrten" icon={<DirectionsCar />} />
                        </Tabs>
                    </AppBar>
                    {this.state.loading ?
                        <LoadingTransparentBlocked /> :
                        <React.Fragment>
                            <TabContainer value={this.state.value} index={0}>
                                {renderAttendedRides()}
                            </TabContainer>
                            <TabContainer value={this.state.value} index={1}>
                                {renderUserRides()}
                            </TabContainer>
                        </React.Fragment>}
                </React.Fragment >
            );
        }

        return (
            <React.Fragment>
                {this.state.loadingAdd ? <LoadingTransparentBlocked /> : null}
                {this.renderAppBarHeader()}
                {renderManageRides()}
            </React.Fragment >
        );
    }
}

ManageRides.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setAppBarState, setBottomNavState })(withStyles(styles)(ManageRides));
