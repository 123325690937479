import HttpService from '../../util/HttpService'
let _axios = HttpService.getAxiosClient()

export async function sendPushNotificationToUsers(ids, body, data) {
    try {

        let payload = {
            subscriberIds: ids,
            body: body,
            data: data,
            title: process.env.REACT_APP_PUSH_TITLE || "R2R - HS PF Mobility",
            url: process.env.REACT_APP_PUSH_APP_URL || "",
            icon: process.env.REACT_APP_PUSH_ICON || process.env.REACT_APP_IMAGE_URL + "/public/images/logo.png",
            badge: process.env.REACT_APP_PUSH_BADGE || process.env.REACT_APP_IMAGE_URL + "/public/images/logo.png",
            tag: process.env.REACT_APP_PUSH_TAG || "R2R",
            ttl: 36000,
        }

        let response = await _axios.post(process.env.REACT_APP_NOTIFICATION_PATH + "pushs/pushToSubscriber", payload)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}