import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "vehicle"

/**
 * Method requests the API to delete a vehicle
 * @param {string} userId - id of the user
 * @param {string} vehicleId - id of the vehicle
 * @returns {object} result of the API request
 */
export async function deleteVehicle(vehicleId) {
    try {
        let response = await _axios.delete(process.env.REACT_APP_REST_URL + PATH + '/?id=' + vehicleId)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to change the favorite flag of the vehicle
 * @param {string} vehicleId - id of the vehicle
 * @param {boolean} newFavoriteValue - new value of the favorite state
 * @returns {object} result of the API request
 */
export async function updateFavoriteVehicle(vehicleId, newFavoriteValue) {
    try {
        var data = {
            favorite: newFavoriteValue
        }

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/updateFavoriteById/?id=' + vehicleId, data)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to change a vehicle
 * @param {string} vehicleId - id of the vehicle
 * @param {object} changedData - object with the changed data
 * @returns {object} result of the API request
 */
export async function updateVehicle(vehicleId, data) {
    try {

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/?id=' + vehicleId, data)
        return response.data
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method requests the API to add a new vehicle
 * @param {object} data - object with the data of the new vehicle
 * @returns {object} result of the API request
 */
export async function addNewVehicle(data) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/', data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all vehicles of a user
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getVehiclesFromUser(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/vehiclesByUserId/?userid=" + userId)
        return response.data
    } catch (error) {
        console.error(error)
    }
}