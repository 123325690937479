/* eslint-disable no-unused-vars */
export function formattedTime(timestamp) {
    return new Date(timestamp).toLocaleTimeString('de-DE', { hour: "2-digit", hour12: false, minute: "2-digit" })
}

export function formattedDate(timestamp) {
    return new Date(timestamp).toLocaleDateString('de-DE', { year: "numeric", month: "2-digit", day: "2-digit" })
}

export function format(inputValue, options) {
    const { onlyDigits, onlyWords, type, minLength, maxLength, format, delimitor, uppercase, lowercase } = options

    if (uppercase && lowercase) {
        console.error('Only one of the parameter can be true')
    } else {
        if (uppercase) {
            // console.log(inputValue.toUpperCase())
        }

        if (lowercase) {
            // console.log(inputValue.toLowerCase())
        }
    }

    if (format) {
        const split = format.split(delimitor)
        const numberOfParts = split.length
        let pattern = ""
        for (let index = 0; index < numberOfParts; index++) {
            let subPattern = '(\\w{' + split[index].length + '})'
            pattern = pattern.concat(subPattern)
        }

    }

    switch (type) {
        case 'EMAIL': {


            break;
        }
        default: {

        }
    }


}