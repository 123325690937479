import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, CardActions, CardHeader, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CardMedia } from '@mui/material';
import { MoreVert, Favorite } from '@mui/icons-material/';
import './VehicleCard.scss';

class VehicleCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            dialogOpen: null,
        }
    }

    /**
   * Opens the menu 
   */
    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    /**
     * Closes the menu 
     */
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    /**
     * Close the dialog for deleting a vehicle
     */
    handleDeleteDialogClose = () => {
        this.setState({ dialogOpen: null });
    }

    /**
     * Opens the dialog for deleting a vehicle
     * @param {Event} event - event für getting the initializing element
     */
    handleDeleteDialogOpen = event => {
        this.handleMenuClose();
        this.setState({ dialogOpen: event.currentTarget });
    }

    /**
     * Close the menu of the vehicle card and opens the dialog to update a vehicle
     */
    handleUpdateVehicle() {
        this.handleMenuClose();
        this.props.handleFilterDialogOpen("UPDATE", this.props.vehicle._id);
    }

    /**
     * Close the dialog for deleting a vehicle ans execute the method to delete a vehicle, which is provided in the props
     * @param {string} rideID - id of a ride as a string
     */
    handleDeleteVehicle = (rideID) => {
        this.handleDeleteDialogClose();
        this.props.deleteVehicle(rideID);
    }


    render() {
        const { anchorEl } = this.state;
        const { dialogOpen } = this.state;
        const isdeleteDialogOpen = Boolean(dialogOpen);
        const deleteDialog = (
            <Dialog anchorEl={dialogOpen} open={isdeleteDialogOpen} onClose={this.handleDeleteDialogClose} aria-labelledby="simple-dialog-title">
                <DialogTitle id="simple-dialog-title">Entfernen</DialogTitle>
                <DialogContent>
                    <DialogContentText> Möchten Sie das Fahrzeug wirklich entfernen?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.handleDeleteDialogClose}>
                        Abbrechen
                    </Button>
                    <Button color="secondary" variant="contained" autoFocus onClick={() => this.handleDeleteVehicle(this.props.vehicle._id)}>
                        Entfernen
                    </Button>
                </DialogActions>
            </Dialog>
        )

        /**
         * Returns the menu, if the prop "functional" is true
         */
        const functionalMenu = () => {
            if (this.props.functional) {
                return (
                    <Menu id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}>
                        <MenuItem onClick={() => this.handleUpdateVehicle()}>Bearbeiten</MenuItem>
                        <MenuItem onClick={this.handleDeleteDialogOpen}>Entfernen</MenuItem>
                    </Menu>
                )
            }
        }

        /**
         * Returns the card action section, if the prop "functional" is true
         */
        const functionalCardAction = () => {
            if (this.props.functional) {
                return (
                    <CardActions >
                        <IconButton
                            onClick={() => this.props.handleFavoriteChange(this.props.vehicle._id)}
                            color={this.props.vehicle.favorite === true ? "secondary" : "primary"}
                            aria-label="Add to favorites"
                            size="large">
                            <Favorite />
                        </IconButton>
                        {this.props.vehicle.favorite === true ?
                            <Typography color="secondary" variant="body1">Ausgewählt</Typography>
                            : <Typography variant="body1">Fahrzeug auswählen</Typography>}

                    </CardActions>
                );
            }
        }

        return (
            <React.Fragment>
                {deleteDialog}
                <Card data-testid="test-card" className="r2r-vehicleCard">
                    <CardHeader id="vehicleCard-brand" data-testid="test-brand" className=" r2r-vehicleCard-paddingBottom-0"
                        action={this.props.functional ? (
                            <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.handleMenuOpen}
                                size="large">
                                <MoreVert />
                            </IconButton>) : null}
                        titleTypographyProps={{ variant: 'h6' }}
                        title={this.props.vehicle.vehicleBrand + " " + this.props.vehicle.vehicleModel} />
                    {functionalMenu()}
                    <CardContent className={this.props.functional ? "r2r-vehicleCard-paddingBottom-0" : null} >
                        <div className="r2r-container-content-flex-row">
                            <CardMedia
                                component="img"
                                sx={{ width: 125, height: 100, objectFit: "cover" }}
                                image={this.props.vehicle.vehicleImageId ? process.env.REACT_APP_IMAGE_URL + this.props.vehicle.vehicleImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE}
                                alt="carimage"
                            />
                            {/* <div className="r2r-vehicleCard-image-center">
                                <img className="r2r-vehicleCard-image" alt="Fahrzeugbild" src={this.props.vehicle.vehicleImageId ? process.env.REACT_APP_IMAGE_URL + this.props.vehicle.vehicleImageId.imagePath : process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE} />
                            </div> */}
                            <div className="r2r-vehicleCard-car-info">
                                <div className="r2r-vehicleCard-right-margin">
                                    <Typography id="vehicleCard-plate" data-testid="test-plate" variant="body1"><strong>{"Kennzeichen: "}</strong>{this.props.vehicle.vehiclePlate}</Typography>
                                </div>
                                <div className="r2r-vehicleCard-right-margin">
                                    <Typography id="vehicleCard-color" data-testid="test-color" variant="body1"><strong>{"Farbe: "}</strong>{this.props.vehicle.vehicleColor}</Typography>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    {functionalCardAction()}
                </Card >
            </React.Fragment>
        );
    }
}

VehicleCard.propTypes = {
    functional: PropTypes.bool.isRequired,
    vehicle: PropTypes.object.isRequired,
    deleteVehicle: PropTypes.func,
    handleFilterDialogOpen: PropTypes.func,
    handleFavoriteChange: PropTypes.func
}


export default (VehicleCard);