import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "ride"

/**
 * Object that represents a ride
 * @typedef {Object} Ride
 * @property {{ formattedAddres: string, city: string,postalCode:  Number, houseNumber: string, country: string, state: string} } placeDeparture - departureinformation of the place
 * @property {{ formattedAddres: string, city: string,postalCode:  Number, houseNumber: string, country: string, state: string} } placeArrival - arrivalinformation of the place
 * @property {{type: "Point", coordinates: [  number,number]}} departureLocation - location of the departure
 * @property {{type: "Point", coordinates: [  number,number]}} arrivalLocation - location of the arrival
 * @property {string} rideDescription - description of the ride
 * @property {number} departureTimestamp - timestamp of the departure
 * @property {0} arrivalTimestamp - timestamp of the departure
 * @property {string} vehicleId - id of the vehicle
 * @property {number} vehicleSpace - number of free places in the ride
 * @property {string} userId - id of the user
 * @property {object[]} attendee - array of attendees
 * @property {{ smoker: boolean, allergy: boolean,rideType: "PRIVATE"} } filter - filter criteria of the ride
 */

/**
 * Method requests the API to add a single ride
 * @param {Ride} rideObject - the {@link Ride} object
 * @returns {object} result of the API request
 */
export async function addRide(rideObject) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/', rideObject);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to add multiple rides
 * @param {Ride[]} rideArray - array of ride objects
 * @returns {object} result of the API request
 */
export async function addRides(rideArray) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/multiple', rideArray);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all rides of a user
 * @param {string} userId - id of the user
 * @returns {object} result of the API request
 */
export async function getOwnRides(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/ridesByUserId?id=" + userId)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all rides, on which the user participate
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getAttendedRides(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/attendedrides?id=" + userId)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to change the status of a attendee
 * @param {string} rideId - id of the ride
 * @param {string} userId - id of the user
 * @param {("ACCEPT"|"DENIED"|"OPEN")} newStatus - new status of the user
 * @returns {object} result of the API request
 */
export async function putStatusByUserId(rideId, userId, newStatus) {
    try {
        const data = {
            rideId: rideId,
            userId: userId,
            newStatus: newStatus,
        };

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/updateStatusById/", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all rides for the ridehistory
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getRidesForRideHistory(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/getRidesForHistory/?userId=" + userId)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API for details of a ride
 * @param {string} rideId id of the ride
 * @returns {object} result of the API request
 */
export async function getRideDetails(rideId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/getRideDetails?rideId=" + rideId)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to complete a ride
 * @param {string} rideId - id of a ride
 * @returns {object} result of the API request
 */
export async function completeRide(rideId) {
    try {
        let data = {
            rideId: rideId
        }
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/completeRide", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to delete a ride
 * @param {string} userId - id of the user
 * @param {string} rideId - id of the ride
 * @returns {object} result of the API request
 */
export async function deleteRide(userId, rideId) {
    try {
        let data = {
            userId: userId
        }
        let response = await _axios.delete(process.env.REACT_APP_REST_URL + PATH + "/?id=" + rideId, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get rides by the arrival coordinates and the arrival time
 * @param {string} userId - id of a user
 * @param {number} departureLng - departure longitude
 * @param {number} departureLat - departure latitude
 * @param {number} arrivalLng - arrival longitude
 * @param {number} arrivalLat - arrival latitude
 * @param {number} arrivalTimestamp -arrival timestamp
 * @returns {object} result of the API request
 */
export async function getRideByArriaval(userId, departureLng, departureLat, arrivalLng, arrivalLat, arrivalTimestamp) {
    try {
        const data = {
            userId: userId,
            departureLng: departureLng,
            departureLat: departureLat,
            arrivalLng: arrivalLng,
            arrivalLat: arrivalLat,
            arrivalTimestamp: arrivalTimestamp
        };

        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/getRidesByArrivalCoordinate", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get rides by the departure coordinates and the departure time
 * @param {string} userId 
 * @param {number} departureLng - departure longitude
 * @param {number} departureLat - departure latitude
 * @param {number} arrivalLng - arrival longitude
 * @param {number} arrivalLat - arrival latitude
 * @param {number} arrivalTimestamp -departure timestamp
 * @returns {object} result of the API request
 */
export async function getRideByDeparture(userId, departureLng, departureLat, arrivalLng, arrivalLat, departureTimestamp) {
    try {

        const data = {
            userId: userId,
            departureLng: departureLng,
            departureLat: departureLat,
            arrivalLng: arrivalLng,
            arrivalLat: arrivalLat,
            departureTimestamp: departureTimestamp
        };

        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/getRidesByDepartureCoordinate", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}


/**
 * Method requests the API to get rides by the departure coordinates and the departure time
 * @param {string} userId 
 * @returns {object} result of the API request
 */
export async function getNextRides(userId) {
    try {

        const data = {
            userid: userId
        };

        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/nextrides", data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to change a ride
 * @param {string} rideId - id of the ride
 * @param {object} data - changed rideobject
 * @param {string} token - bearer token
 * @returns {object} result of the API request
 */
export async function putChangedRide(rideId, data) {
    try {
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/?id=' + rideId, data)
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to add a new attendee to the ride
 * @param {object} data - object of the new attendee
 * @returns {object} result of the API request
 */
export async function enterRide(data) {
    try {
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/addAttendee/', data);
        return response.data;
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method requests the API to add a new attendee to the ride
 * @param {object} data - object of the new attendee
 * @returns {object} result of the API request
 */
export async function checkVehicleUsage(vehicleid) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + '/vehicleusage/?vehicleid=' + vehicleid);
        return response.data;
    } catch (error) {
        console.error(error)
    }

}

/**
 * Methdo requests the API to remove a attendee from a ride
 * @param {object} data - object of the attendee which will be removed from the ride
 * @returns {object} result of the API request
 */
export async function exitRide(data) {
    try {

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/deleteAttendee/', data);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all rides with a specific vehicle
 * @param {string} vehicleId - id of a vehicle
 * @returns {object} result of the API request
 */
export async function getRidesByVehicleId(vehicleId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + '/getRidesByVehicleId?vehicleId=' + vehicleId);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}
