import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()
const PATH = "image"

export async function addProfilImage(formData) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/upload', formData)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * 
 * @param {*} profilImageId 
 * @param {*} formData 
 */
export async function changeProfilImage(profilImageId, formData) {
    try {
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/changeImage/?id=' + profilImageId, formData)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function addVehicleImage(formData) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/upload', formData);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function updateVehicleImage(vehicleImageId, formData) {
    try {
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/changeImage/?id=' + vehicleImageId, formData);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function deleteImage(imageid) {
    try {
        let response = await _axios.delete(process.env.REACT_APP_REST_URL + PATH + '/' + imageid);
        return response.data
    } catch (error) {
        console.error(error)
    }
}