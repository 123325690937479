import React, { Component } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material/';
import SectionTypography from '../Global/sectionTypography/sectionTypography';
import './NoRide.scss';


class NoRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Card className="noRide-card-margin">
                <CardHeader title={<SectionTypography>Anstehende Fahrten</SectionTypography>} />
                <CardContent className="noRide-card">
                    <div data-testid="test-div" className="r2r-display-flex noRide-flex-outer">
                        Keine anstehenden Fahrten
                    </div>
                </CardContent>
            </Card>
        );
    }
}

export default NoRide;