/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { connect } from 'react-redux'
import { IconButton, Button, TextField, Dialog, DialogActions, Checkbox, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { BugReport } from "@mui/icons-material/";
import { sendBugToJira } from "../../API/REST/index";
import get_browser_info from "../../util/browser_helper";
import "./IssueTracker.scss"

function IssueTracker(props) {
    const [summary, setSummary] = useState("");
    const [bugProposal, setBugProposal] = useState("");
    const [anonym, setAnonym] = useState(false);
    const [open, setOpen] = useState(false);
    const [openError] = useState(false);
    const [error] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSummary("");
        setAnonym(false);
        setBugProposal("");
    };

    const prepareBugProposal = () => {
        if (!anonym) {
            return (
                "Name: \t" + "\n" +
                "E-Mail: \t" + "\n" +
                "Fehlerbeschreibung: \t" + bugProposal + "\n" +
                "Browserinformationen: \t"
            )
        } else {
            return (
                "Name: \t" + props.user.firstName + " " + props.user.lastName + "\n" +
                "E-Mail: \t" + props.user.email + "\n" +
                "Fehlerbeschreibung: \t" + bugProposal + "\n" +
                "Browserinformationen: \t" + JSON.stringify(get_browser_info())
            )
        }
    }

    const sendBug = () => {
        sendBugToJira(summary, prepareBugProposal());
        setOpen(false);
        setSummary("");
        setAnonym(false);
        setBugProposal("");
    };

    if (error !== "") {
        return (
            <Dialog
                open={openError}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-bugProposal">
                <DialogTitle id="alert-dialog-title">
                    {"Etwas ist schiefgelaufen..."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-bugProposal">
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <React.Fragment>
            <IconButton
                data-testid="issuetracker-button"
                color="inherit"
                edge="end"
                flex="1"
                onClick={handleClickOpen}
                size="large">
                <BugReport />
            </IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" data-testid="issuetracker-dialogTitle" className="">
                    Fehler oder Problem melden
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Bitte geben Sie eine Zusammenfassung und eine Beschreibung des
                        Fehlers ein.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="summary"
                        label="Zusammenfassung"
                        type="text"
                        fullWidth
                        value={summary}
                        onChange={e => setSummary(e.target.value)} />
                    <TextField
                        margin="dense"
                        id="bugProposal"
                        label="Fehlerbeschreibung"
                        type="text"
                        fullWidth
                        value={bugProposal}
                        onChange={e => setBugProposal(e.target.value)} />
                    <div className="issueTracker-checkbox">
                        <div >
                            <Checkbox
                                checked={anonym}
                                onChange={e => setAnonym(!anonym)}
                                value="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        <div>E-Mail Adresse für Rückfragen sowie den Namen mit übermitteln?</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Abbrechen</Button>
                    <Button onClick={sendBug} color="primary">Absenden</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        user: {
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            email: state.user.email
        }
    };
};

export default connect(mapStateToProps, null)(IssueTracker);
