/* eslint-disable no-redeclare */
export async function checkRequiredFieldss(arrayOfFields) {
    // eslint-disable-next-line no-unused-vars
    let index = 0
    let resultArray = []
    let errorAvailable = false
    // eslint-disable-next-line array-callback-return
    arrayOfFields.map((element, i) => {
        if (element === "" || element === null || element === undefined) {
            resultArray.push(true)
            errorAvailable = true
        } else {
            resultArray.push(false)

        }
    })
    const returnArray = {
        errorAvailable: errorAvailable,
        errorArray: resultArray
    }
    return (returnArray)

}

export async function checkRequiredFields(arrayOfFields) {
    let errorAvailable = false
    let resultArray = []
    //console.log("Input Array", arrayOfFields)
    arrayOfFields.forEach(element => {
        //console.log(element)
        switch (element.type) {
            case "STRING": {
                if (checkStringValue(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "TIMESTAMP": {
                if (checkTimestamp(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "OBJECT": {
                if (checkObject(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "BIRDATE": {
                if (checkBirthdate(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "BOOLEANTRUE": {
                if (checkBooleanTrue(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "BOOLEANFALSE": {
                if (checkBooleanFalse(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            case "IMAGE": {
                if (checkImage(element.value)) {
                    errorAvailable = true;
                    resultArray.push(true)
                    break;
                } else {
                    resultArray.push(false)
                    break;
                }
            }
            default:
                resultArray.push(true)
                break;
        }

    });

    //console.log("CheckRequiredFields:", resultArray)
    const returnArray = {
        errorAvailable: errorAvailable,
        errorArray: resultArray
    }
    //console.log(returnArray)
    return (returnArray)
}

function checkStringValue(stringValue) {
    if (stringValue === "" || stringValue === null || stringValue === undefined) {
        return true
    } else {
        return false
    }
}

function checkTimestamp(timestampValue) {
    if (timestampValue === "" || timestampValue === null || timestampValue === undefined || isNaN(timestampValue)) {
        return true
    } else {
        if (timestampValue <= new Date().getTime()) {
            return true
        } else {
            return false
        }
    }
}

function checkObject(objectValue) {
    if (objectValue === null || objectValue === undefined) {
        return true
    } else {
        return false
    }
}

function checkBirthdate(birthdayValue) {
    if (birthdayValue === null || birthdayValue === undefined) {
        return true
    } else {
        if (birthdayValue >= new Date().getTime()) {
            return true
        } else {
            return false
        }
    }
}

function checkBooleanTrue(booleanValue) {
    if (booleanValue === null || booleanValue === undefined) {
        return true
    } else {
        if (booleanValue === false) {
            return true
        } else {
            return false
        }
    }
}

function checkBooleanFalse(booleanValue) {
    if (booleanValue === null || booleanValue === undefined) {
        return true
    } else {
        if (booleanValue === true) {
            return true
        } else {
            return false
        }
    }
}

function checkImage(imageValue) {
    if (imageValue === null || imageValue === undefined) {
        return true
    } else {
        return false
    }
}

export function validate(input, type, stateName) {
    switch (type) {
        case "wordsOnly":
            var regEx = /[A-Za-z]/g
            return input.replace((/\d/g), "")
        case "email":
            var regEx = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            if (!input.match(regEx)) {
                this.setState({
                    [stateName]: input,
                    errors: {
                        vehicleBrand: true
                    }
                })
            } else {
                this.setState({
                    [stateName]: input,
                    errors: {
                        vehicleBrand: false
                    }
                })
            }
            break;
        case "digitsOnly":
            var regEx = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            if (!input.match(regEx)) {
                this.setState({
                    [stateName]: input,
                    errors: {
                        vehicleBrand: true
                    }
                })
            } else {
                this.setState({
                    [stateName]: input,
                    errors: {
                        vehicleBrand: false
                    }
                })
            }
            break;
        default:
            break
    }
}