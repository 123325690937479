import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Star, StarBorder, StarHalf } from '@mui/icons-material/';
import { formattedDate } from '../../util/formatting_helper'
import './OneRating.scss';

class OneRating extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * Creates the number of stars depending on the given stars of the rating
     */
    giveStar = () => {
        var returnValue = [];
        var ratingNumber = this.props.rating.stars
        for (var i = 0; i <= 4; i++) {
            if (ratingNumber >= 1) {
                returnValue.push(<Star key={i} style={{ color: 'orange', height: 20, width: 20 }} />);
                ratingNumber--;
            } else if (ratingNumber === 0.5) {
                returnValue.push(<StarHalf key={i} style={{ color: 'orange', height: 20, width: 20 }} />);
                ratingNumber = ratingNumber - 0.5;
            } else {
                returnValue.push(<StarBorder key={i} style={{ color: 'orange', height: 20, width: 20 }} />);
            }
        }
        return (returnValue);
    }

    renderRating(image, firstName, lastName) {
        return (

            <ListItem   >
                <ListItemAvatar>
                    <Avatar alt="avatar" src={process.env.REACT_APP_IMAGE_URL + image} />
                </ListItemAvatar>
                <ListItemText disableTypography secondary={
                    <React.Fragment>
                        <div className="oneRating-flex-star">
                            {this.giveStar()}
                        </div>
                        <Typography component={'span'} variant="body1">{this.props.rating.comment}</Typography>
                    </React.Fragment>}>
                    <div className="r2r-container-content-flex-row oneRating-flex-right-name">
                        <Typography className="r2r-typography-bold" component={'span'}>{firstName} {lastName} </Typography>
                        <Typography className="" component={'span'}>{formattedDate(this.props.rating.timestamp)}</Typography>
                    </div>
                </ListItemText>
            </ListItem>


        )
    }


    render() {
        if (this.props.rating.sender) {
            if (this.props.rating.sender.profilImageId) {
                return this.renderRating(this.props.rating.sender.profilImageId.imagePath, this.props.rating.sender.firstName, this.props.rating.sender.lastName)

            } else {
                return this.renderRating(process.env.REACT_APP_DEFAULT_USER_IMAGE, this.props.rating.sender.firstName, this.props.rating.sender.lastName)
            }

        } else {
            return this.renderRating(process.env.REACT_APP_DEFAULT_USER_IMAGE, "anonym", "")
        }
    }
}

OneRating.propTypes = {

    rating: PropTypes.object.isRequired
}


export default OneRating;
