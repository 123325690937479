import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom";
import { setBottomNavState, setAppBarState, setUser, setSnackbar } from '../../Actions/Index.js'
import { User, LoadingTransparentBlocked, CustomGrid, CustomAppBar } from '../index'
import { changeUserData, changeProfilImage, getUserData, addProfilImage } from '../../API/REST/index.js'
import "./ChangeUserprofil.scss";

class ChangeUserprofil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingChange: false,
        }
        this.handleProfilImage = this.handleProfilImage.bind(this);
        this.changeUserData = this.changeUserData.bind(this);
        this.handleNavigateToMyProfil = this.handleNavigateToMyProfil.bind(this);
    }

    componentDidMount() {
        this.props.setBottomNavState(4)
    }

    requestData() {
        this.getProfileInfo()
    }

    /**
     * Requests necassary data
     */
    async getProfileInfo() {
        try {
            this.setState({ loading: true })
            const data = await getUserData(this.props.user._id)
            switch (data.response.code) {
                case 200:
                    this.setState({ loading: false, user: data.result.data });
                    break;

                default:
                    this.setState({ loading: false, user: data.result.data });
                    break;
            }

        } catch (error) {
            this.setState({ loading: false });
            console.error(error)
        }

    }

    /**
     * Method handles the upload of a profil image
     * @param {object} userState - object of the userstate
     * @param {*} imageFile - image file
     */
    async handleProfilImage(userState, imageFile) {
        try {
            if (imageFile === null) {
                this.setState({ loadingChange: true })
                this.changeUserData(userState, ('profilImageId' in userState ? userState.profilImageId : null))
            } else {
                this.setState({ loadingChange: true, loadingDialog: true })
                let formData = new FormData();
                formData.append('image', imageFile)
                if (!userState.profilImageId) {
                    if (userState.profilImageId._id === process.env.REACT_APP_PROFILIMAGE_DEFAULT_ID) {
                        const data = await addProfilImage(formData)
                        switch (data.response.code) {
                            case 200:
                                this.changeUserData(userState, data.result.data.imageId)
                                break;
                            default:
                                this.setState({ loadingChange: false })
                                this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                                break;
                        }
                    } else {
                        const data = await changeProfilImage(userState.profilImageId._id)
                        switch (data.response.code) {
                            case 200:
                                this.changeUserData(userState, userState.profilImageId._id)
                                break;
                            default:
                                this.setState({ loadingChange: false })
                                this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                                break;
                        }
                    }
                } else {
                    const data = await addProfilImage(formData)
                    switch (data.response.code) {
                        case 200:
                            this.changeUserData(userState, data.result.data.imageId)
                            break;
                        default:
                            this.setState({ loadingChange: false })
                            this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                            break;
                    }
                }

            }
        } catch (error) {
            this.setState({ loadingChange: false })
            this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
            console.error(error)
        }
    }

    /**
     * Method handles the change process of the user data
     * @param {object} userState - object of the userstate
     * @param {*} imageId - id of the new image
     */
    async changeUserData(userState, imageId) {

        let userData = {
            firstName: userState.firstName,
            lastName: userState.lastName,
            email: userState.email,
            phoneNumber: userState.phoneNumber,
            gender: userState.gender,
            profilImageId: imageId,
            rides: userState.rides,
            vehicles: userState.vehicles,
            attend: userState.attend,
        };
        try {
            const data = await changeUserData(this.props.user._id, userData)
            switch (data.response.code) {
                case 200:
                    this.setState({ loadingChange: false })
                    this.props.setUser(userState);
                    this.handleNavigateToMyProfil();
                    break;
                default:
                    this.setState({ loadingChange: false })
                    this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                    break;
            }

        } catch (error) {
            this.setState({ loadingChange: false })
            this.props.setSnackbar("Es ist eine Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
            console.error(error)
        }
    }

    /**
     * Change the state for redirection
     * @param {event} event 
     */
    async handleNavigateToMyProfil(event) {
        this.setState({ redirect: true });
    }

    /**
     * Handles state change
     * @param {event} name 
     */
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    /**
     * Renders the appbar
     */
    renderAppBarHeader() {
        return (
            <CustomAppBar title="Profil bearbeiten" backIconEnabled={true} backHandler={() => this.setState({ redirect: true })} >
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    render() {
        const renderChangeUserProfil = () => {
            return (
                <React.Fragment>
                    {this.state.loadingChange ? <LoadingTransparentBlocked /> : null}
                    <User user={this.props.user} handler={this.handleProfilImage} />
                </React.Fragment>
            );
        }

        if (this.state.redirect) {
            return <Redirect to='/profil' />
        }

        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {this.state.loading ? <LoadingTransparentBlocked /> :
                    <CustomGrid>
                        {renderChangeUserProfil()}
                    </CustomGrid>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};


export default connect(mapStateToProps, { setBottomNavState, setAppBarState, setUser, setSnackbar })(ChangeUserprofil);
