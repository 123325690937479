import HttpService from './util/HttpService'
let _axios = HttpService.getAxiosClient()

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY)


function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

async function sendSubscription(subscription, userId) {
    // console.log(subscription)
    return _axios.post(process.env.REACT_APP_NOTIFICATION_PATH + 'subscribers', subscription).then(response => { if (response.data.status === "Subscription saved") { saveSubscriptionToUser(response.data.subscriptionId, userId) } }).catch(error => {
        console.error(error)
    })
}

async function saveSubscriptionToUser(subscription, userID) {
    let data = {
        subscribtionId: subscription,
        userId: userID
    }
    return _axios.put(process.env.REACT_APP_REST_URL + 'user/pushSubscription', data).then(response => { /*console.log(response.data)*/ });
}

export function subscribeUser() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
            if (!registration.pushManager) {
                // console.log('Push manager unavailable.')
                return
            }
            registration.pushManager.getSubscription().then(function (existedSubscription) {
                if (existedSubscription === null) {
                    console.log('No subscription detected, make a request.')
                    registration.pushManager.subscribe({
                        applicationServerKey: convertedVapidKey,
                        userVisibleOnly: true
                    }).then(function (newSubscription) {
                        console.log('New subscription added.')
                        sendSubscription(newSubscription)
                    }).catch(function (e) {
                        if (Notification.permission !== 'granted') {
                            console.log('Permission was not granted.')
                        } else {
                            console.error('An error ocurred during the subscription process.', e)
                        }
                    })
                } else {
                    console.log('Existed subscription detected.')
                }
            })
        }).catch(function (e) {
            console.error('An error ocurred during Service Worker registration.', e)
        })
    }
}

export async function getSubscriptions(userId) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(function (registration) {
            if (!registration.pushManager) {
                console.log('Push manager unavailable.')
                return
            }
            registration.pushManager.getSubscription().then(function (existedSubscription) {
                // console.log(existedSubscription)
                if (existedSubscription !== null) {
                    sendSubscription(existedSubscription, userId)
                    return existedSubscription
                } else {

                }
            })
        }).catch(function (e) {
            console.error('An error ocurred during Service Worker registration.', e)
        })
    }
}