import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { TextField, Slide, Dialog, AppBar, Toolbar, IconButton, Typography, Button, Autocomplete } from '@mui/material/';
import { Brush, Class, DirectionsCar, Close } from '@mui/icons-material/';
import InputMask from 'react-input-mask';
import { CustomGrid, LoadingTransparentBlocked } from '../index';
import { checkRequiredFields } from '../../util/validation_helper.js'
import { EDIT_CAR, sendNotification } from '../../util/notification_helper';
import { updateVehicle, getRidesByVehicleId, addNewVehicle, addVehicleImage, updateVehicleImage, getAllCarmodels } from '../../API/REST/index'
// import { setSnackbar } from '../../Actions/Index.js'
import './AddVehicleDialog.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});

class AddVehicleDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: this.props.open,
            vehicle: {
                vehicleImageId: {
                    _id: "5da724f392731a3dad4cb362",
                    imagePath: process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE
                },
                vehicleBrand: "",
                vehicleModel: "",
                vehicleColor: "",
                vehiclePlate: "",
            },
            plate: {
                identificationNumber: "",
                differential: "",
                district: ""
            },
            vehicleData: [],
            loadingProgress: false,
            vehicleDataModels: [],
            imageFile: null,
            errors: {
                image: false,
                brand: false,
                model: false,
                color: false,
                plateIdentificationNumber: false,
                plateDifferential: false,
                plateDistrict: false
            },
        }
    }

    componentDidMount() {
        this.fillAutocomplete()
        if (this.props.options.vehicle) {
            const plate = this.props.options.vehicle.vehiclePlate.split("-")
            this.setState({
                vehicle: this.props.options.vehicle,
                imageFile: "",
                plate: {
                    identificationNumber: plate[2],
                    differential: plate[1],
                    district: plate[0]
                }
            })
        }
    }

    async fillAutocomplete() {
        try {
            const data = await getAllCarmodels()
            switch (data.response.code) {
                case 200:
                    this.setState({ vehicleData: data.result.data })
                    break;
                case 500:
                    this.setState({ vehicleData: [] })
                    console.error("Fehler beim abrufen der Daten für die Autovervollständigungsfelder")
                    break;
                default:
                    this.setState({ vehicleData: [] })
                    console.error("Fehler beim abrufen der Daten für die Autovervollständigungsfelder")
                    break;
            }
        } catch (error) {
            this.setState({ vehicleData: [] })
            console.error(error)
        }
    }

    handleChange = name => event => {
        this.setState({
            errors: {
                ...this.state.errors,
                color: false
            },
            vehicle: {
                ...this.state.vehicle,
                [name]: event.target.value
            },
        })
    };

    handleChangePlate = name => event => {
        this.setState({
            errors: {
                ...this.state.errors,
                plateIdentificationNumber: false,
                plateDifferential: false,
                plateDistrict: false
            },
            plate: {
                ...this.state.plate,
                [name]: event.target.value.toUpperCase()
            },
            vehicle: {
                ...this.state.vehicle,
                vehiclePlate: this.state.plate.district.toUpperCase() + "-" + this.state.plate.differential.toUpperCase() + "-" + this.state.plate.identificationNumber.toUpperCase()
            },
        })
    };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size <= 10000000) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        image: false
                    },
                    imageFile: event.target.files[0],
                    vehicle: {
                        ...this.state.vehicle,
                        vehicleImageId: {
                            ...this.state.vehicle.vehicleImageId,
                            imagePath: URL.createObjectURL(event.target.files[0]),
                        }
                    },
                });
            } else {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        image: true
                    },

                });
                console.error("Image to large. Max image size is 10MB.")
                // this.props.setSnackbar("Bild ist zu groß!", true, "error")
            }
        }
    }

    handleCreateUpdateVehicle = async () => {
        let validationObject = [
            {
                type: "STRING",
                value: this.state.vehicle.vehicleBrand,
                errorIndex: 0
            },
            {
                type: "STRING",
                value: this.state.vehicle.vehicleModel,
                errorIndex: 1
            },
            {
                type: "STRING",
                value: this.state.vehicle.vehicleColor,
                errorIndex: 2
            },
            {
                type: "STRING",
                value: this.state.plate.identificationNumber,
                errorIndex: 3,
            },
            {
                type: "STRING",
                value: this.state.plate.differential,
                errorIndex: 4,
            },
            {
                type: "STRING",
                value: this.state.plate.district,
                errorIndex: 5,
            },
            {
                type: "IMAGE",
                value: this.state.imageFile,
                errorIndex: 6
            },
        ]
        const validator = await checkRequiredFields(validationObject)

        if (validator.errorAvailable) {
            // this.props.setSnackbar("Bitte alle Felder ausfüllen!", true, "error")
            this.setState({
                errors: {
                    brand: validator.errorArray[0],
                    model: validator.errorArray[1],
                    color: validator.errorArray[2],
                    plateIdentificationNumber: validator.errorArray[3],
                    plateDifferential: validator.errorArray[4],
                    plateDistrict: validator.errorArray[5],
                    image: validator.errorArray[6]
                }
            })
        } else {
            this.setState({
                errors: {
                    brand: validator.errorArray[0],
                    model: validator.errorArray[1],
                    color: validator.errorArray[2],
                    plateIdentificationNumber: validator.errorArray[3],
                    plateDifferential: validator.errorArray[4],
                    plateDistrict: validator.errorArray[5],
                    image: validator.errorArray[6]
                }
            })
            if (this.props.options.vehicle === undefined) {
                this.handleCreateVehicle()
            } else {
                this.handleUpdateVehicle()
            }
        }
    }

    async handleCreateVehicle() {
        try {
            this.setState({ loadingProgress: true })
            const imageId = await this.postVehicleImage(this.state.imageFile);
            if (!(imageId === "")) {
                this.postVehicle(imageId)
            } else {
                this.setState({ loadingProgress: false });
                // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
                this.handleClose(true)
            }
        } catch (error) {
            this.setState({ loadingProgress: false });
            // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
            this.handleClose(true)
        }
    }

    async handleUpdateVehicle() {
        if (!('_id' in this.state.vehicle.vehicleImageId)) {
            const imageId = await this.postVehicleImage(this.state.imageFile)
            if (!(imageId === "")) {
                this.setState({
                    vehicle: {
                        ...this.state.vehicle,
                        vehicleImageId: {
                            ...this.state.vehicle.vehicleImageId,
                            _id: imageId
                        }
                    }
                })
                this.updateVehicle()
            }
        } else {
            if (this.state.vehicle.vehicleImageId.imagePath.includes("blob")) {
                this.updateVehicleImage(this.state.imageFile)
            }
            this.updateVehicle()
        }
    }

    async postVehicle(imageId) {
        try {
            this.setState({ loadingProgress: true })
            let newVehicle = {
                vehicleModel: this.state.vehicle.vehicleModel,
                vehicleBrand: this.state.vehicle.vehicleBrand,
                vehicleImageId: imageId,
                vehicleColor: this.state.vehicle.vehicleColor.toLowerCase(),
                vehiclePlate: this.state.plate.district.toUpperCase() + "-" + this.state.plate.differential.toUpperCase() + "-" + this.state.plate.identificationNumber.toUpperCase(),
                favorite: "false",
                userId: this.props.user._id,
            };
            const data = await addNewVehicle(newVehicle)
            switch (data.response.code) {
                case 200:
                    this.setState({ loadingProgress: false });
                    // this.props.setSnackbar("Fahrzeug angelegt.", true, "success")
                    this.handleClose(true)
                    break;
                case 413: {
                    this.setState({ loadingProgress: false });
                    // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
                    this.handleClose(true)
                    break;
                }
                case 500: {
                    this.setState({ loadingProgress: false });
                    // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
                    this.handleClose(true)
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            this.setState({ loadingProgress: false });
            // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
            this.handleClose(true)
        }
    }

    async updateVehicle() {
        try {
            this.setState({ loadingProgress: true })
            let changedVehicle = {
                vehicleModel: this.state.vehicle.vehicleModel,
                vehicleBrand: this.state.vehicle.vehicleBrand,
                vehicleImageId: this.state.vehicle.vehicleImageId._id,
                vehicleColor: this.state.vehicle.vehicleColor.toLowerCase(),
                vehiclePlate: this.state.plate.district.toUpperCase() + "-" + this.state.plate.differential.toUpperCase() + "-" + this.state.plate.identificationNumber.toUpperCase(),
                favorite: this.state.vehicle.favorite,
                userId: this.state.vehicle.userId
            };
            const data = await updateVehicle(this.state.vehicle._id, changedVehicle)
            switch (data.response.code) {
                case 200:
                    this.setState({ loadingDialog: false, loadingProgress: false });
                    // this.props.setSnackbar("Änderungen übernommen.", true, "success")
                    this.handleClose(true)
                    const data = await getRidesByVehicleId(this.state.vehicle._id)
                    switch (data.response.code) {
                        case 200:
                            let resArray = data.result.rides.map(element => element.attendee);
                            sendNotification(resArray.flat(1), EDIT_CAR)
                            break;
                        case 500: {
                            // this.props.setSnackbar("Fehler beim ändern eines Fahrzeugs.", true, "error")
                            console.error("Error while update vehicle")
                            break;
                        }
                        default:
                            // this.props.setSnackbar("Fehler beim ändern eines Fahrzeugs.", true, "error")
                            console.error("Error while update vehicle")
                            break;
                    }
                    break;
                case 500:
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    async postVehicleImage(image) {
        try {
            this.setState({ loadingProgress: true })
            let formData = new FormData();
            formData.append('image', image)
            const data = await addVehicleImage(formData)
            switch (data.response.code) {
                case 200: {
                    return data.result.data.imageId
                }
                case 413: {
                    this.setState({ loadingProgress: false })
                    // this.props.setSnackbar("Das Bild ist zu groß.", true, "warning")
                    this.handleClose(true)
                    return ""
                }
                case 500: {
                    console.error("Error: 500, Image could not be added")
                    this.setState({ loadingProgress: false });
                    // this.props.setSnackbar("Fehler beim anlegen eines Fahrzeugs.", true, "error")
                    this.handleClose(true)
                    return ""
                }
                default: {
                    this.setState({ loadingProgress: false })
                    // this.props.setSnackbar("Es ist ein Fehler aufgetreten", true, "error")
                    this.handleClose(true)
                    return ""
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    async updateVehicleImage(image) {
        try {
            this.setState({ loadingProgress: true })
            let formData = new FormData();
            formData.append('image', image)

            const data = await updateVehicleImage(this.state.vehicle.vehicleImageId._id, formData)
            switch (data.response.code) {
                case 200:
                    break;
                case 500:
                    console.error("Error while update image")
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error)
        }
    }

    insertImagePath() {
        if (this.state.vehicle.vehicleImageId) {
            if (this.state.vehicle.vehicleImageId.imagePath.includes("blob")) {
                return (this.state.vehicle.vehicleImageId.imagePath)
            } else {
                return (process.env.REACT_APP_IMAGE_URL + this.state.vehicle.vehicleImageId.imagePath)
            }
        }
        return (process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_VEHICLE_IMAGE)
    }

    async handleClose(pullVehicles) {
        this.setState({
            pullVehicles: pullVehicles,
            dialogOpen: false
        })
    }

    render() {
        const { handleDialogClose } = this.props;

        const renderAppbarDialog = () => {
            return (
                <AppBar className="" position="sticky">
                    <Toolbar>
                        <IconButton
                            className={"r2r-appbar-iconbutton"}
                            edge="start"
                            color="inherit"
                            onClick={() => this.handleClose(false)}
                            aria-label="Close"
                            size="large">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className="">
                            {this.props.options.toolbarText}
                        </Typography>
                        <div className="r2r-container-flex-grow" />
                        <Button color="inherit" edge="end" onClick={this.handleCreateUpdateVehicle}>
                            {this.props.options.executeButtonText}
                        </Button>
                    </Toolbar>
                </AppBar>
            );
        }

        const renderVehicleInput = () => {
            return (
                <React.Fragment>
                    <div className="r2r-display-flex addVehicleDialog-input-container">
                        <DirectionsCar className='addVehicleDialog-prefixicon-textfield' color="secondary" />

                        <Autocomplete
                            id="brand"
                            options={this.state.vehicleData.map((data) => data.brand)}
                            // defaultValue={""}
                            freeSolo
                            fullWidth
                            openOnFocus
                            autoSelect
                            blurOnSelect
                            value={this.state.vehicle.vehicleBrand}
                            onChange={(event, value) => {
                                if (value === null) {
                                    this.setState({ errors: { ...this.state.errors, brand: false }, vehicle: { ...this.state.vehicle, vehicleBrand: "", vehicleModel: "" }, vehicleDataModels: [] })
                                } else {
                                    if (value.brand) {
                                        this.setState({ errors: { ...this.state.errors, brand: false }, vehicle: { ...this.state.vehicle, vehicleBrand: value.brand, vehicleModel: "" }, vehicleDataModels: value.models })
                                    } else {
                                        const data = this.state.vehicleData.find((element) => element.brand === value)
                                        if (data) {
                                            this.setState({ errors: { ...this.state.errors, brand: false }, vehicle: { ...this.state.vehicle, vehicleBrand: value, vehicleModel: "" }, vehicleDataModels: data.models })
                                        } else {
                                            this.setState({ errors: { ...this.state.errors, brand: false }, vehicle: { ...this.state.vehicle, vehicleBrand: value, vehicleModel: "" }, vehicleDataModels: [] })
                                        }

                                    }
                                }
                            }}
                            renderInput={params =>
                                <TextField {...params}
                                    error={this.state.errors.brand}
                                    id=""
                                    className=""
                                    variant="standard"
                                    required
                                    fullWidth
                                    label="Hersteller"
                                    margin="normal" />}
                        />

                    </div>

                    <div className="r2r-display-flex addVehicleDialog-input-container">

                        <DirectionsCar style={{ color: "#FFFFFF", height: 30, width: 30, margin: "0 0 5px 0" }} />
                        <Autocomplete
                            id="model"
                            options={this.state.vehicleDataModels.map((data) => data)}
                            //getOptionLabel={option => option.brand}
                            style={{ width: "100%" }}
                            // defaultValue={""}
                            freeSolo
                            autoSelect
                            openOnFocus
                            blurOnSelect
                            value={this.state.vehicle.vehicleModel}
                            onChange={(event, value) => {
                                if (value === null) {
                                    this.setState({ errors: { ...this.state.errors, model: false }, vehicle: { ...this.state.vehicle, vehicleModel: "" } })
                                } else {
                                    if (value) {
                                        this.setState({ errors: { ...this.state.errors, model: false }, vehicle: { ...this.state.vehicle, vehicleModel: value } })
                                    } else {
                                        this.setState({ errors: { ...this.state.errors, model: false }, vehicle: { ...this.state.vehicle, vehicleModel: value } })
                                    }
                                }
                            }}
                            renderInput={params =>
                                <TextField {...params}
                                    error={this.state.errors.model}
                                    id=""
                                    variant="standard"
                                    className=""
                                    required
                                    fullWidth
                                    label="Modell"
                                    margin="normal" />}
                        />

                    </div>


                </React.Fragment>)
        }

        return (
            <React.Fragment>
                <Dialog
                    data-testid="addvehicledialog-dialog"
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={() => this.handleClose(false)}
                    TransitionComponent={Transition}
                    TransitionProps={{
                        onExited: () => handleDialogClose(this.state.pullVehicles)
                    }}>
                    {this.state.loadingProgress ? <LoadingTransparentBlocked /> : null}
                    {renderAppbarDialog()}
                    <CustomGrid>
                        <div className="r2r-container-content">
                            <label htmlFor="contained-button-file">
                                <div className="addVehicleDialog-image r2r-display-fley r2r-contentContainer-element-margin addVehicleDialog-container">
                                    <img id="target" className={this.state.errors.image ? "addVehicleDialog-img addVehicleDialog-image-error" : "addVehicleDialog-img"} alt="Fahrzeugbild" src={this.insertImagePath()} />
                                    <div className="addVehicleDialog-uploadtext">Bild hochladen</div>
                                </div >
                            </label>
                            <input
                                accept="image/png, image/jpeg"
                                onChange={this.onImageChange}
                                className="r2r-upload-label"
                                id="contained-button-file"
                                type="file" />
                            <div className="r2r-contentContainer-element-margin">
                                {renderVehicleInput()}
                                <div className="r2r-display-flex addVehicleDialog-input-container">

                                    <Brush className='addVehicleDialog-prefixicon-textfield' color="secondary" />

                                    <TextField
                                        error={this.state.errors.color}
                                        id=""
                                        fullWidth
                                        required
                                        variant="standard"
                                        label="Farbe"
                                        value={this.state.vehicle.vehicleColor}
                                        // defaultValue={this.state.vehicle.vehicleColor}
                                        onChange={this.handleChange('vehicleColor')}
                                        margin="normal" />
                                </div>

                                <div className="r2r-display-flex addVehicleDialog-input-container addVehicleDialog-plate-container">
                                    <Class className='addVehicleDialog-prefixicon-textfield' color="secondary" />


                                    <InputMask
                                        // {...rest}
                                        mask="a??"
                                        formatChars={{
                                            "a": "[A-Za-z]",
                                            "?": "[A-Za-z]"
                                        }}
                                        maskChar=""
                                        onChange={this.handleChangePlate('district')}
                                        value={this.state.plate.district}
                                        // defaultValue={this.state.plate.district}
                                        alwaysShowMask={false}>
                                        {(inputProps) => <TextField
                                            {...inputProps}
                                            error={this.state.errors.plateDistrict}
                                            id=""
                                            className=""
                                            fullWidth
                                            variant="standard"
                                            required
                                            label="Kennzeichen erster Block"
                                            placeholder="PF"
                                            margin="normal"
                                        />}
                                    </InputMask>


                                    <InputMask
                                        // {...rest}
                                        mask="a?"
                                        formatChars={{
                                            "a": "[A-Za-z]",
                                            "?": "[A-Za-z]"
                                        }}
                                        maskChar=""
                                        onChange={this.handleChangePlate('differential')}
                                        value={this.state.plate.differential}
                                        // defaultValue={this.state.plate.differential}
                                        alwaysShowMask={false}  >
                                        {(inputProps) => <TextField
                                            {...inputProps}
                                            error={this.state.errors.plateDifferential}
                                            id=""
                                            className=""
                                            fullWidth
                                            variant="standard"
                                            required
                                            label="Kennzeichen zweiter Block"
                                            placeholder="HS"
                                            margin="normal"
                                        />}
                                    </InputMask>


                                    <InputMask
                                        // {...rest}
                                        mask="9???"
                                        formatChars={{
                                            "9": "[0-9]",
                                            "?": "[0-9Ee]",
                                            "!": "[Ee]"
                                        }}
                                        maskChar=""
                                        onChange={this.handleChangePlate('identificationNumber')}
                                        value={this.state.plate.identificationNumber}
                                        // defaultValue={this.state.plate.identificationNumber}
                                        alwaysShowMask={false}  >
                                        {(inputProps) => <TextField
                                            {...inputProps}
                                            error={this.state.errors.plateIdentificationNumber}
                                            id=""
                                            className=""
                                            variant="standard"
                                            fullWidth
                                            required
                                            label="Kennzeichen dritter Block"
                                            placeholder="2022"
                                            margin="normal"
                                        />}
                                    </InputMask>

                                </div >

                            </div>
                        </div>
                    </CustomGrid>
                </Dialog>
            </React.Fragment>
        );
    }
}

AddVehicleDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    options: PropTypes.shape({
        vehicle: PropTypes.object,
        toolbarText: PropTypes.string.isRequired,
        executeButtonText: PropTypes.string.isRequired
    })
}

const mapStateToProps = (state) => {
    return {
        keycloak: {
            token: state.keycloak.token
        },
        user: {
            _id: state.user._id
        }
    };
};

// export default connect(mapStateToProps, { setSnackbar })(AddVehicleDialog);
export default connect(mapStateToProps, null)(AddVehicleDialog);

