import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Avatar, Button, TextField, IconButton, Checkbox } from '@mui/material/';
import { PermIdentity, Phone, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material/';
import { faVenus, faMars, faGenderless } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setSnackbar } from '../../Actions/Index.js'
import { checkRequiredFields } from '../../util/validation_helper';
import './User.scss';

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePräfix: process.env.REACT_APP_REST_URL,
            imageFile: null,
            defaultImagePath: process.env.REACT_APP_DEFAULT_USER_IMAGE,
            user: this.props.user,
            acceptDsgvo: false,
            errors: {
                image: false,
                acceptDsgvo: false,
                phoneNumber: false,
                gender: false
            }
        };
    }

    handleDateChange = (newData) => {
        this.setState({
            user: {
                ...this.state.user,
                date: newData
            }
        });
    };

    changeGender = (newGender) => {
        this.setState({
            errors: {
                ...this.state.errors,
                gender: false
            },
            user: {
                ...this.state.user,
                gender: newGender
            }
        });
    }

    handleChange = name => event => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.value
            }
        });
    };

    handleChangePhonenumber = name => event => {
        if (event.target.value.match(/\D/g) !== null) {
            this.setState({
                user: {
                    ...this.state.user,
                    [name]: event.target.value.replace((/\D/g), "")
                }
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    phoneNumber: false
                },
                user: {
                    ...this.state.user,
                    [name]: event.target.value
                }
            });
        }
    };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size <= 10000000) {
                this.setState({
                    imageFile: event.target.files[0],
                    user: {
                        ...this.state.user,
                        profilImageId: {
                            ...this.state.user.profilImageId,
                            imagePath: URL.createObjectURL(event.target.files[0]),
                        }
                    },
                });
            } else {
                console.error("Image to large. Max image size is 10MB")
                this.props.setSnackbar("Bild ist zu groß!", true, "error")
            }
        }
    }


    insertImagePath() {
        if (this.state.user.profilImageId) {
            if (this.state.user.profilImageId.imagePath.includes("blob")) {
                return (this.state.user.profilImageId.imagePath)
            } else {
                return (process.env.REACT_APP_IMAGE_URL + this.state.user.profilImageId.imagePath)
            }
        }
        return (process.env.REACT_APP_IMAGE_URL + process.env.REACT_APP_DEFAULT_USER_IMAGE)
    }

    checkFirstLogin = () => {
        if (this.state.user.firstLogin) {
            return (!this.state.user.acceptDsgvo)
        } else {
            return (false)
        }
    }

    async handleSave() {
        let validationObject = [
            {
                type: "STRING",
                value: this.state.user.phoneNumber,
                errorIndex: 0
            },
            {
                type: "STRING",
                value: this.state.user.gender,
                errorIndex: 1
            }]
        if (this.props.initialLogin) {
            validationObject.push({
                type: "BOOLEANTRUE",
                value: this.state.user.acceptDsgvo,
                errorIndex: 2
            })
        }
        const validator = await checkRequiredFields(validationObject)
        if (validator.errorAvailable) {
            if (this.props.initialLogin) {
                this.setState({
                    errors: {
                        image: false,
                        acceptDsgvo: validator.errorArray[2],
                        phoneNumber: validator.errorArray[0],
                        gender: validator.errorArray[1]
                    }
                })
            } else {
                this.setState({
                    errors: {
                        image: false,
                        phoneNumber: validator.errorArray[0],
                        gender: validator.errorArray[1]
                    }
                })
            }
            this.props.setSnackbar("Bitte alle Felder ausfüllen.", true, "error")
        } else {
            this.setState({
                errors: {
                    image: false,
                    acceptDsgvo: false,
                    phoneNumber: false,
                    gender: false
                }
            })
            this.props.handler(this.state.user, this.state.imageFile)
        }

    }

    render() {
        const handleChange = name => event => {
            this.setState({
                errors: {
                    ...this.state.errors,
                    acceptDsgvo: false,
                },
                user: {
                    ...this.state.user,
                    [name]: event.target.checked
                }
            });
        };

        const handleIconColor = (genderField) => {
            if (this.state.user.gender === genderField)
                return "secondary"
            else {
                return "primary"
            }
        }

        return (
            <div className=" r2r-container-content r2r-container-content-flex-column">
                <label htmlFor="contained-button-file">
                    <div className="user-image r2r-contentContainer-element-margin user-container">
                        <Avatar style={{ height: 150, width: 150 }} className="createProfil-avatar user-img" alt="Profilbild" src={this.insertImagePath()} />
                        <div className="user-uploadtext">Bild hochladen</div>
                    </div >
                </label>
                <input
                    accept="image/png, image/jpeg"
                    onChange={this.onImageChange}
                    className="r2r-upload-label"
                    id="contained-button-file"
                    type="file" />
                <div className="user-flex-row">
                    <div className="user-flex-fields-icon">
                        <PermIdentity color="secondary" style={{ height: 30, width: 30 }} />
                    </div>
                    <div className="user-input-fields-required">
                        <TextField
                            id="user-input-firstName"
                            data-testid="user-firstname"
                            required
                            fullWidth
                            disabled
                            variant="standard"
                            className="user-textfield"
                            label="Vorname"
                            value={this.state.user.firstName}
                            onChange={this.handleChange('firstName')}
                            margin="normal"
                        />
                    </div>
                </div >

                <div className="user-flex-row">
                    <div style={{ height: 30, width: 30 }} className="user-flex-fields-icon">

                    </div>
                    <div className="user-input-fields-required">
                        <TextField
                            id="user-input-lastName"
                            data-testid="user-lastname"
                            className="user-textfield"
                            required
                            disabled
                            fullWidth
                            variant="standard"
                            label="Nachname"
                            value={this.state.user.lastName}
                            onChange={this.handleChange('lastName')}
                            margin="normal"
                        />
                    </div>
                </div >

                <div className="user-flex-row">
                    <div className="user-flex-fields-icon">
                        <Phone color="secondary" style={{ height: 30, width: 30 }} />
                    </div>
                    <div className="user-input-fields-required">
                        <TextField
                            id="user-input-phoneNumber"
                            data-testid="user-phonenumber"
                            className="user-textfield"
                            required
                            fullWidth
                            variant="standard"
                            label="Telefonnummer"
                            value={this.state.user.phoneNumber}
                            onChange={this.handleChangePhonenumber('phoneNumber')}
                            margin="normal"
                            error={this.state.errors.phoneNumber}
                        />
                    </div>
                </div >

                <React.Fragment>
                    <div className="user-flex-gender-icons">
                        <div className="user-flex-gender-icons-icon">
                            <IconButton
                                className={this.state.errors.gender ? "user-gender-error" : ""}
                                color={handleIconColor("männlich")}
                                aria-label="männlich"
                                onClick={() => { this.changeGender("männlich") }}
                                size="large">
                                <FontAwesomeIcon className="user-iconSize" icon={faMars} />
                            </IconButton>
                        </div>
                        <div className="user-flex-gender-icons-icon">
                            <IconButton
                                className={this.state.errors.gender ? "user-gender-error" : ""}
                                color={handleIconColor("weiblich")}
                                aria-label="weiblich"
                                onClick={() => { this.changeGender("weiblich") }}
                                size="large">
                                <FontAwesomeIcon className="user-iconSize" icon={faVenus} />
                            </IconButton>
                        </div>
                        <div className="user-flex-gender-icons-icon">
                            <IconButton
                                className={this.state.errors.gender ? "user-gender-error" : ""}
                                color={handleIconColor("divers")}
                                aria-label="divers"
                                onClick={() => { this.changeGender("divers") }}
                                size="large">
                                <FontAwesomeIcon className="user-iconSize" icon={faGenderless} />
                            </IconButton>
                        </div>
                    </div>
                    {this.props.initialLogin ?
                        <div className="user-checkbox">
                            <div >
                                <Checkbox
                                    className={this.state.errors.acceptDsgvo ? "user-checkbox-error" : ""}
                                    onChange={handleChange('acceptDsgvo')}
                                    required
                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    value={this.state.user.acceptDsgvo}
                                />
                            </div>
                            <div className={this.state.errors.acceptDsgvo ? "user-checkbox-text-error" : ""}>
                                {/* eslint-disable-next-line react/jsx-no-target-blank*/}
                                Ich habe die <a href={"https://www.hs-pforzheim.de/kontakt/datenschutzerklaerung"} target="_blank"> Datenschutzerklärung</a> der Hochschule Pforzheim gelesen und erkläre mich damit einverstanden. *
                            </div>
                        </div>
                        : null
                    }

                </React.Fragment>
                <div className="user-flex-button">
                    <Button fullWidth color="secondary" disabled={this.checkFirstLogin()} variant="contained" onClick={() => this.handleSave()} >
                        Speichern
                    </Button>
                </div >
            </div >
        );
    }
}

export default connect(null, { setSnackbar })(User);
