import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import { Button, Dialog, Divider, AppBar, Toolbar, IconButton, Slide, FormHelperText, FormControl, Select, MenuItem, Input, Fab, CardContent, Collapse, Typography, } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowRight, FilterList, Close, ExpandMore, ExpandLess } from '@mui/icons-material/';
import { getReceivedRatings, getRideByArriaval, getRideByDeparture } from '../../API/REST/index'
import MovingOffer from './MovingOffer';
import { setBottomNavState, setAppBarState, setSnackbar } from '../../Actions/Index.js'
import { formattedTime, formattedDate } from '../../util/formatting_helper'
import { CustomAppBar, LoadingTransparentBlocked, CustomGrid } from '../index';
import { Box } from '@mui/system';
import './ShowResults.scss';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
    },
    formControl: {
        minWidth: 200,
    },
    expand: {
        transform: 'rotate(0deg)',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

const classes = styles();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var ridesOldTimestamp = 0;
class ShowResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loading: true,
            rides: [],
            filterDialogOpen: false,
            filterDialogExpanded: false,
            filterDialogIcon: "expand",
            filterModus: "ALL",

            filterRating: 0,
            filterGender: "ALL",
            filterSmoking: "ALL",
            filterAllergy: false,

            departureStreet: this.props.match.params.departureStreet,
            departureHouseNumber: this.props.match.params.departureHouseNumber,
            departurePostalCode: this.props.match.params.departurePostalCode,
            departureCity: this.props.match.params.departureCity,
            arrivalStreet: this.props.match.params.arrivalStreet,
            arrivalHouseNumber: this.props.match.params.arrivalHouseNumber,
            arrivalPostalCode: this.props.match.params.arrivalPostalCode,
            arrivalCity: this.props.match.params.arrivalCity,
            departurearrival: this.props.match.params.departurearrival,
            departureArrivalTimestamp: this.props.match.params.departureArrivalTimestamp,

            departureFullAdress: this.props.match.params.departureFullAdress,
            arrivalFullAdress: this.props.match.params.arrivalFullAdress
        }
    }

    componentDidMount() {
        this.props.setBottomNavState(1)
        if (this.state.departurearrival === "departure") {
            this.getResultsByDeparture()
        } else {
            this.getResultsByArrival()
        }
    }

    async calculate(rides) {
        let data = Promise.all(rides.map(async ride => {
            ride.rating = await this.getRatings(ride.userId._id)
            return ride
        }))
        return data
    }

    async getRatings(userid) {
        const data = await getReceivedRatings(userid)
        switch (data.response.code) {
            case 200:
                if (data.result.data.length === 0) {
                    return 0
                } else {
                    var totalRating = 0
                    data.result.data.forEach(rating => {
                        totalRating = totalRating + rating.stars
                    })
                    return (totalRating / data.result.data.length).toFixed(2)
                }
            default:
                return 0
        }
    }

    /**
     * 
     */
    async getResultsByDeparture() {
        try {
            const data = await getRideByDeparture(this.props.user._id, this.props.match.params.departureLng, this.props.match.params.departureLat, this.props.match.params.arrivalLng, this.props.match.params.arrivalLat, this.props.match.params.departureArrivalTimestamp)
            switch (data.response.code) {
                case 200:
                    if (data.result.rides === null) {
                        this.setState({
                            rides: [],
                            loading: false
                        });
                    } else {
                        let r = await this.calculate(data.result.rides)
                        this.setState({
                            rides: r,
                            loading: false
                        });
                    }
                    break;
                default:
                    this.props.setSnackbar("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                    this.setState({
                        rides: [],
                        loading: false
                    });
                    break;
            }
        } catch (error) {
            this.props.setSnackbar("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
            this.setState({
                rides: [],
                loading: false
            });
            console.error(error)
        }
    }

    /**
     * 
     */
    async getResultsByArrival() {
        try {
            const data = getRideByArriaval(this.props.user._id, this.props.match.params.departureLng, this.props.match.params.departureLat, this.props.match.params.arrivalLng, this.props.match.params.arrivalLat, this.props.match.params.departureArrivalTimestamp)
            switch (data.response.code) {
                case 200:
                    if (data.result.rides === null) {
                        this.setState({
                            rides: [],
                        });
                    } else {

                        let r = await this.calculate(data.result.rides)
                        this.setState({
                            rides: r,
                            loading: false
                        });
                    }
                    break;
                default:
                    this.props.setSnackbar("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
                    this.setState({
                        rides: [],
                        loading: false
                    });
                    break;
            }
        } catch (error) {
            this.props.setSnackbar("Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal", true, "error")
            this.setState({
                rides: [],
                loading: false
            });
            console.error(error)
        }
    }

    /**
     * Method handles a redirection to search ride
     */
    handleRedirect = () => {
        this.setState({ redirect: true })
    }

    /**
     * Method handles the open state for the filter dialog
     */
    handleFilterDialogOpen = () => {
        this.setState({ filterDialogOpen: true });
    }

    /**
     * Methot handles the open state for the filter dialog
     */
    handleFilterDialogClose = () => {
        this.setState({ filterDialogOpen: false });
    }

    /**
     * 
     */
    handleChangeFilterModus = (event) => {
        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    /**
     * 
     */
    handleExpandClick = () => {
        this.setState({ filterDialogExpanded: !this.state.filterDialogExpanded })
    }

    /**
     * 
     */
    /*Hier Rückgabe des Styles für den Button "weitere Filter anzeigen". Das kleine V sollte sich um 180° drehen*/
    returnExpandClass = () => {
        if (this.state.filterDialogExpanded) {
            return (classes.expandOpen)
        }
        else {
            return (classes.expand)
        }
    }


    /**
     * 
     * @param {*} date 
     * @param {*} index 
     */
    checkDateRides(date, index) {
        if (index === 0) {
            ridesOldTimestamp = 0
        }
        var currentDate = new Date(date);
        var lastDate = new Date(ridesOldTimestamp);

        if (currentDate.getDate() === lastDate.getDate() && currentDate.getMonth() === lastDate.getMonth()) {
            ridesOldTimestamp = date;
            return false
        } else {
            ridesOldTimestamp = date;
            return true

        }
    }

    /**
     * 
     */
    handleDateLabel(date) {

        var today = new Date();
        var currentDate = new Date(date);
        if (today.getDate() === currentDate.getDate() && today.getMonth() === currentDate.getMonth()) {
            return "Heute"
        } else {
            return formattedDate(date)
        }
    }

    /**
     * Method that handles the ridetypes
     * @param {*} rideObject - Object of the ride for getting the ridetype
     */
    handleFilterModus(rideObject) {

        if (this.state.filterModus === 'ALL') {
            return true
        } else if (this.state.filterModus === "PRIVATE" && rideObject.filter.rideType === 'PRIVATE') {
            return true
        } else if (this.state.filterModus === "PUBLIC" && rideObject.filter.rideType === 'PUBLIC') {
            return true
        } else { return false }
    }
    /**
     * Method that calculates the average rating for the ride
     * @param {Object} rideObject - Object of the ride for getting the users ratings to calculate the average
     */
    calculateRating(rideObject) {
        return rideObject.rating
    }

    /**
     * Method handles the filter attribute for the gender
     * @param {Object} rideObject - Object of the ride to get the gender of the user
     */
    handleFilterGender(rideObject) {
        if (this.state.filterGender === 'ALL') {
            return true
        } else if (this.state.filterGender === "MALE" && rideObject.userId.gender === 'männlich') {
            return true
        } else if (this.state.filterGender === "FEMALE" && rideObject.userId.gender === 'weiblich') {
            return true
        } else { return false }
    }
    /**
     * Method handles the filter attribute for the rating
     * @param {Object} rideObject - Object of the ride to get the rating of the user
     */
    handleFilterRating(rideObject) {

        if (this.calculateRating(rideObject) >= this.state.filterRating) {
            return true
        } else {
            return false
        }
    }

    /**
     * Method handles the filter attribute for the smoking
     * @param {Object} rideObject - Object of the ride to get the smoking attribute of the ride
     */
    handleFilterSmoking(rideObject) {
        if (this.state.filterSmoking === 'ALL') {
            return true
        } else if (this.state.filterSmoking === 'SMOKER' && rideObject.filter.smoker === true) {
            return true
        } else if (this.state.filterSmoking === 'NON-SMOKER' && rideObject.filter.smoker === false) {
            return true
        } else { return false }
    }

    /**
     * Method handles the filter attribute for the allergy
     * @param {Object} rideObject - Object of the ride to get the allergy attribute of the ride
     */
    handleFilterAllergy(rideObject) {
        if (this.state.filterAllergy === false) {
            return true
        } else if (this.state.filterAllergy === true && rideObject.filter.allergy === true) {
            return true
        } else { return false }
    }

    renderAppBarHeader() {
        return (
            <CustomAppBar title="Fahrt suchen" backIconEnabled={true} backHandler={this.handleRedirect}>
                <div className="r2r-container-flex-grow" />
            </CustomAppBar>
        )
    }

    render() {
        const { classes } = this.props;
        const headingsVariant = "subtitle2";
        const headingsClasses = "r2r-typography-bold";

        const filterDialog = () => {
            return (
                <React.Fragment>
                    <Dialog fullScreen open={this.state.filterDialogOpen} onClose={this.handleFilterDialogClose} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleFilterDialogClose}
                                    aria-label="Close"
                                    size="large">
                                    <Close />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.title}>
                                    Fahrten filtern
                                </Typography>
                                <Button color="inherit" onClick={this.handleFilterDialogClose}> speichern </Button>
                            </Toolbar>
                        </AppBar>
                        <CustomGrid>
                            <form className="r2r-container-content" autoComplete="off">

                                <FormControl className="showResultsFormControl">
                                    <Typography variant={headingsVariant} className={headingsClasses}>Art der Fahrt?</Typography>
                                    <Select
                                        value={this.state.filterModus}
                                        onChange={this.handleChangeFilterModus}
                                        input={<Input name="filterModus" id="filterModus-helper" />} >
                                        <MenuItem value={"ALL"}> <em>Alle</em> </MenuItem>
                                        <MenuItem value={"PRIVATE"}>Nur Private</MenuItem>
                                        <MenuItem value={"PUBLIC"}>Nur Öffentliche</MenuItem>
                                    </Select>
                                    <FormHelperText>Welche Art der Verkehrsmittel bevorzugst du?</FormHelperText>
                                </FormControl>

                                <Divider />

                                <FormControl className="showResultsFormControl">
                                    <Typography variant={headingsVariant} className={headingsClasses}>Bewertung?</Typography>
                                    <Select
                                        value={this.state.filterRating}
                                        onChange={this.handleChangeFilterModus}
                                        input={<Input name="filterRating" id="filterRating-helper" />} >
                                        <MenuItem value={0}><em>0 Sterne</em></MenuItem>
                                        <MenuItem value={1}>1 Stern</MenuItem>
                                        <MenuItem value={2}>2 Sterne</MenuItem>
                                        <MenuItem value={3}>3 Sterne</MenuItem>
                                        <MenuItem value={4}>4 Sterne</MenuItem>
                                        <MenuItem value={5}>5 Sterne</MenuItem>
                                    </Select>
                                    <FormHelperText>Wie viele Sterne soll dein Fahrer mindestens haben?</FormHelperText>
                                </FormControl>

                                <Divider />
                                <Collapse in={this.state.filterDialogExpanded} timeout="auto" unmountOnExit>
                                    <CardContent className="showResults-remove-padding-left">
                                        <FormControl className="showResultsFormControl">
                                            <Typography variant={headingsVariant} className={headingsClasses}>Geschlecht?</Typography>
                                            <Select
                                                value={this.state.filterGender}
                                                onChange={this.handleChangeFilterModus}
                                                input={<Input name="filterGender" id="filterGender-helper" />} >
                                                <MenuItem value={"ALL"}> <em>Alle</em> </MenuItem>
                                                <MenuItem value={"MALE"}>Nur Männer</MenuItem>
                                                <MenuItem value={"FEMALE"}>Nur Frauen</MenuItem>
                                            </Select>
                                            <FormHelperText>Welche Fahrer*Innen möchtest du sehen?</FormHelperText>
                                        </FormControl>

                                        <Divider />

                                        <FormControl className="showResultsFormControl">
                                            <Typography variant={headingsVariant} className={headingsClasses}>Raucher?</Typography>
                                            <Select
                                                value={this.state.filterSmoking}
                                                onChange={this.handleChangeFilterModus}
                                                input={<Input name="filterSmoking" id="filterSmoking-helper" />} >
                                                <MenuItem value={"ALL"}> <em>Alle</em> </MenuItem>
                                                <MenuItem value={"NON-SMOKER"}>Nur Nichtraucherautos</MenuItem>
                                                <MenuItem value={"SMOKER"}>Nur Raucherautos</MenuItem>
                                            </Select>
                                            <FormHelperText>Wie stehst du zu Rauchen im Auto?</FormHelperText>
                                        </FormControl>

                                        <Divider />

                                        <FormControl className="showResultsFormControl">
                                            <Typography variant={headingsVariant} className={headingsClasses}>Allergie?</Typography>
                                            <Select
                                                value={this.state.filterAllergy}
                                                onChange={this.handleChangeFilterModus}
                                                input={<Input name="filterAllergy" id="filterAllergy-helper" />} >
                                                <MenuItem value={false}> <em>Keine Allergie</em> </MenuItem>
                                                <MenuItem value={true}>Allergie</MenuItem>
                                            </Select>
                                            <FormHelperText>Hast du eine Tierhaarallergie?</FormHelperText>
                                        </FormControl>
                                        <Divider />
                                    </CardContent>
                                </Collapse>

                                {/*Hier Expand Filter Menu*/}
                                <div className="r2r-display-flex showResults-expand-button">
                                    <Button
                                        onClick={this.handleExpandClick}
                                        aria-expanded={this.state.filterDialogExpanded}
                                        aria-label="Mehr zeigen" >
                                        Weitere Filtermöglichkeiten
                                        {this.state.filterDialogExpanded ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </div>

                            </form>
                        </CustomGrid>
                    </Dialog>
                </React.Fragment >
            );
        }

        const handleFilter = () => {
            return this.state.rides.filter((ride, index) =>
                ((this.state.filterModus === ride.filter.rideType) || this.state.filterModus === 'ALL') &&
                ((this.state.filterGender === "MALE" && ride.userId.gender === 'männlich') || (this.state.filterGender === "FEMALE" && ride.userId.gender === 'weiblich') || (this.state.filterGender === 'ALL')) &&
                ((this.state.filterSmoking === 'SMOKER' && ride.filter.smoker === true) || (this.state.filterSmoking === 'NON-SMOKER' && ride.filter.smoker === false) || (this.state.filterSmoking === 'ALL')) &&
                ((this.state.filterAllergy === ride.filter.allergy) || (this.state.filterAllergy === false)) &&
                ((this.calculateRating(ride) >= this.state.filterRating))
            )
        }

        const renderRideCards = (ride, index) => {
            // this.state.rides.map((rideObject, key) => (
            const filteredRides = handleFilter()

            if (filteredRides.length > 0) {
                return filteredRides.map((ride, index) => {
                    if (this.checkDateRides(ride.departureTimestamp, index)) {
                        return (
                            <React.Fragment key={index}>
                                <Typography className="r2r-content-title" variant="h6" >{this.handleDateLabel(ride.departureTimestamp)}</Typography>
                                <MovingOffer className="showResults-card-spacing" selectedRide={ride} />
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <MovingOffer className="showResults-card-spacing" selectedRide={ride} />
                            </React.Fragment>
                        )
                    }
                })

            }
            return (<div className="r2r-display-flex showResults-align-vertical">
                <Typography variant="h6">
                    Leider wurden keine passenden Fahrten gefunden.
                </Typography>
            </div>)

        }

        const renderShowResults = () => {
            return (
                <React.Fragment>
                    <div className="app-fab">
                        <Fab color="secondary" aria-label="Filter" className="" onClick={this.handleFilterDialogOpen}>
                            <FilterList />
                        </Fab>
                    </div>
                    {filterDialog()}
                    {/* <div className="r2r-container-header r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column"> */}
                    <div className=" r2r-display-flex r2r-container-backgroundcolor r2r-container-content-flex-column">
                        <CustomGrid className="r2r-container-header-margin">

                            <div className=" r2r-display-flex  showResults-headerPanel ">
                                <Box>
                                    <Typography style={{ color: 'white' }} className="r2r-typography-bold" variant="body1"> {this.state.departureStreet} {this.state.departureHouseNumber === "0" ? null : this.state.departureHouseNumber}<br /></Typography>
                                    <Typography style={{ color: 'white' }} className="" variant="body1">{this.state.departurePostalCode + " " + this.state.departureCity} <br /></Typography>
                                </Box>
                                <div className="showResults-arrow">
                                    <KeyboardArrowRight style={{ color: 'white' }} />
                                </div>
                                <Box>
                                    <Typography style={{ color: 'white' }} className="r2r-typography-bold" variant="body1">  {this.state.arrivalStreet} {this.state.arrivalHouseNumber === "0" ? null : this.state.arrivalHouseNumber}<br /> </Typography>
                                    <Typography style={{ color: 'white' }} className="" variant="body1">{this.state.departurePostalCode + " " + this.state.arrivalCity} <br /></Typography>
                                </Box>
                            </div>
                            <Typography component={'span'} color="secondary" className="" variant="body1"><div className="showResults-time">{this.state.departurearrival === "departure" ? "Abfahrt: " : "Ankunft: "}{formattedDate(parseInt(this.props.match.params.departureArrivalTimestamp)) + ", " + formattedTime(parseInt(this.props.match.params.departureArrivalTimestamp)) + " Uhr"}</div></Typography>

                        </CustomGrid>
                    </div>

                    <div className="showResults-content-background r2r-container-content">
                        <CustomGrid>
                            {this.state.loading ?
                                <LoadingTransparentBlocked /> :
                                <React.Fragment>
                                    {this.state.rides.length > 0 ?
                                        // this.state.rides.map((rideObject, key) => (
                                        //     renderRideCard(rideObject, key)
                                        // ))


                                        renderRideCards()
                                        :
                                        <div className="r2r-display-flex showResults-align-vertical">
                                            <Typography variant="h6">
                                                Leider wurden keine passenden Fahrten gefunden.
                                            </Typography>
                                        </div>}
                                </React.Fragment>}
                        </CustomGrid>
                    </div>
                </React.Fragment>
            )
        }

        if (this.state.redirect === true) {
            return (<Redirect to={{
                // pathname: "/searchRide/departureFullAdress=" + this.state.departureFullAdress + "&arrivalFullAdress=" + this.state.arrivalFullAdress + "&departurearrival=" + this.state.departurearrival + "&departureArrivalTimestamp=" + this.state.departureArrivalTimestamp,
                pathname: "/searchRide/departureFullAdress=0&arrivalFullAdress=0&departurearrival=0&departureArrivalTimestamp=0",
                state: this.state
            }} />)
        }

        return (
            <React.Fragment>
                {this.renderAppBarHeader()}
                {renderShowResults()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState, setSnackbar })(withStyles(styles)(ShowResults));
