import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()

const PATH = "routine"

/**
 * Method requests the API to add a new routine
 * @param {object} - data object of the routine
 * @returns {Object} result of the API request
 */
export async function addRoutine(data) {
    try {
        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + '/', data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}