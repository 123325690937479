import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import { Card, CardContent, CardHeader, Divider, List } from "@mui/material";
import { LoadingTransparentBlocked, NoRide, SectionTypography } from "../index";
import NextRideElement from "./NextRideElement";
import { getOwnRides, getAttendedRides } from "../../API/REST/index.js"
import { messages } from "../../util/toastMessages";

class NextRidesPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRides: [],
            rides: [],
            redirect: false,
            loading: true,
            attendedRides: [],
            clickedRide: {},
        };
    }

    componentDidMount() {
        this.requestData();
    }

    async requestData() {
        try {
            await this.getOwnRides(this.props.user._id);
            await this.getAttendedRides(this.props.user._id);
            this.getNextRides();
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Method to get all rides of the user from the API
     * @param {string} userId - id of a user
     */
    async getOwnRides(userId) {
        try {
            this.setState({ loading: true });
            const data = await getOwnRides(userId)

            switch (data.response.code) {
                case 200:
                    this.setState({
                        userRides: data.result.rides || [],
                        loading: false
                    });
                    break;
                default:
                    this.setState({
                        loading: false,
                        userRides: [],
                    });
                    break;
            }
        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            console.error(error)
            this.setState({
                loading: false,
                userRides: [],
            });
        }

    }

    async filterAttendStatus(rides) {

        return rides.filter((ride) => {
            return ride.attendee.some((attendee) => attendee.user === this.props.user._id && attendee.status === "ACCEPT")
        })
    }

    /**
     * Method get all rides of a user, on which he attend to
     * @param {string} userId - id of a user
     */
    async getAttendedRides(userId) {
        try {
            this.setState({ loading: true });
            const data = await getAttendedRides(userId)

            switch (data.response.code) {
                case 200:

                    this.setState({
                        attendedRides: data.result.rides || [],
                        loading: false
                    });
                    break;
                default:
                    this.setState({
                        attendedRides: [],
                        loading: false
                    });
                    break;
            }
        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            console.error(error)
            this.setState({
                attendedRides: [],
                loading: false
            });
        }

    }

    /**
    * Method filters all own rides and attended rides to get the three next rides
    */
    async getNextRides() {
        let accectedAttendedRides = await this.filterAttendStatus(this.state.attendedRides)
        let tempArray = accectedAttendedRides.concat(this.state.userRides)
        tempArray = tempArray.sort((a, b) => { return a.departureTimestamp - b.departureTimestamp })
        let slicedArray = tempArray.slice(0, 3)
        this.setState({ rides: slicedArray })
    }

    /**
     * Handler to see the ride details
     * @param {event} event - event which is fired when the ride gets clicked
     */
    showRideDetail(event) {
        this.setState({
            clickedRide: event,
            redirect: true,
        })
    }

    renderElements() {
        return this.state.rides.map((element, index) => {
            if (element.userId === this.props.user._id) {
                return (
                    <React.Fragment key={index}>
                        <NextRideElement
                            handleShowRide={(e) => this.showRideDetail(element)}
                            ownRide={true}
                            departure={element.placeDeparture.formattedAddress}
                            arrival={element.placeArrival.formattedAddress}
                            imagePath={this.props.user.profilImageId ? this.props.user.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE}
                            departureTimestamp={element.departureTimestamp} />
                        {index !== this.state.rides.length - 1 ? <Divider variant='inset' /> : null}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        <NextRideElement
                            handleShowRide={(e) => this.showRideDetail(element)}
                            ownRide={false}
                            departure={element.placeDeparture.formattedAddress}
                            arrival={element.placeArrival.formattedAddress}
                            imagePath={element.userId.profilImageId ? element.userId.profilImageId.imagePath : process.env.REACT_APP_DEFAULT_USER_IMAGE}
                            firstName={element.userId.firstName}
                            lastName={element.userId.lastName}
                            departureTimestamp={element.departureTimestamp} />
                        {index !== this.state.rides.length - 1 ? <Divider variant='inset' /> : null}
                    </React.Fragment>
                );
            }
        });
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={{
                    pathname: '/ridedetails/rideId=' + this.state.clickedRide._id,
                    state: { rideId: this.state.clickedRide._id }
                }} />)
        }
        if (this.state.loading) {
            return <LoadingTransparentBlocked />;
        }

        if (this.state.rides.length > 0) {
            return (
                <div className="r2r-contentContainer-element-margin">
                    <Card className="r2r-dashboard-card-margin">
                        <CardHeader title={<SectionTypography>Anstehende Fahrten</SectionTypography>} />
                        <CardContent>
                            <List>{this.renderElements()}</List>
                        </CardContent>
                    </Card>
                </div>
            );
        } else {
            return (
                <div className="r2r-contentContainer-element-margin">
                    <NoRide />
                </div>);
        }


    }
}

const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id,
            profilImageId: state.user.profilImageId
        }
    };
};
export default connect(mapStateToProps, null)(NextRidesPanel);
