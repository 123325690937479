import React, { useState } from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import CustomAlertDialog from '../Global/CustomAlertDialog'
import { Button, DialogContentText } from '@mui/material'
import { formattedDate, formattedTime } from '../../util/formatting_helper'
import { confTransaction } from '../../API/REST'

function ConfirmRideDialog(props) {
    const [dialogOpen, setDialogOpen] = useState(props.isOpen)

    const handleConfirm = async (confirmed) => {
        try {
            const data = await confTransaction(props.transactionid, confirmed)
            switch (data.response.code) {
                case 200:

                    break;

                default:
                    break;
            }
            handleDialogClose();
        } catch (error) {
            console.error(error)
            handleDialogClose();
        }

    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    return (
        <CustomAlertDialog dialogTitle={"Fahrt bestätigen"} dialogContent={
            <DialogContentText data-testid="rideview-menudialog-content" >
                Bist du am {formattedDate(props.departureTimestamp) + " " + formattedTime(props.departureTimestamp) + " Uhr"} bei {props.firstName + " " + props.lastName} mitgefahren?
            </DialogContentText>}
            dialogActions={
                <React.Fragment>
                    <Button color="primary" onClick={() => handleConfirm(false)}>
                        Nicht Bestätigen
                    </Button>
                    <Button color="primary" onClick={() => handleConfirm(true)}>
                        Bestätigen
                    </Button>
                </React.Fragment>
            }
            anchorEl={dialogOpen}
            open={dialogOpen}

            onClose={handleDialogClose}
        />
    )
}

ConfirmRideDialog.propTypes = {}

export default ConfirmRideDialog
