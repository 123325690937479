import React, { Component } from 'react';
import { connect } from 'react-redux'
import { List, ListItemText, Typography, IconButton, Dialog, Badge, Slide, Divider, ListItemButton } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { setBottomNavState, setAppBarState } from '../../Actions/Index.js'
import { getAllNotifications, readNotification } from '../../API/REST/index.js'
import { LoadingTransparentBlocked } from '../Global/Loading'
import { getNotificationCount } from '../../API/REST/index.js'
import CustomGrid from '../Global/CustomGrid.jsx';
import CustomDialogAppBar from '../Global/CustomDialogAppBar.jsx';
import { formattedDate, formattedTime } from '../../util/formatting_helper.js';
import './NotificationOverview.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Slide direction="up" ref={ref} {...props} />;
});

class NotificationOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notifications: [],
            notificationCount: 0,
            notificationOverviewOpen: false
        }
    }

    componentDidMount() {
        this.getNotificationCount()
        this.getNotifcations()
    }

    async getNotificationCount() {
        try {
            const data = await getNotificationCount(this.props.user._id)
            switch (data.response.code) {
                case 200:
                    this.setState({ notificationCount: data.result.data.count })
                    break;
                case 500:
                    this.setState({ notificationCount: 0 })
                    break;
                default:
                    this.setState({ notificationCount: 0 })
                    break;
            }
        } catch (error) {
            this.setState({ notificationCount: 0 })
            console.error(error);
        }
    }

    /**
     * Get the notifications from the API
     */
    async getNotifcations() {
        try {
            this.setState({ loading: true })
            const data = await getAllNotifications({ userId: this.props.user._id })
            switch (data.response.code) {
                case 200:
                    if (data.result.data.length) {
                        this.setState(prevState => ({
                            ...prevState,
                            loading: false,
                            notifications: data.result.data
                        }))
                    } else {
                        this.setState({
                            loading: false,
                            notifications: []
                        })
                    }
                    break;
                case 500:
                    this.setState({
                        loading: false,
                        notifications: []
                    })
                    break;
                default:
                    this.setState({
                        loading: false,
                        notifications: []
                    })
                    break;
            }

        } catch (error) {
            this.setState({
                loading: false,
                notifications: []
            })
            console.error(error);
        }

    }

    /**
     * Handles the readed status of the notification
     * @param {object} notificationObject - object of the notification
     * @param {number} index - index of the item in the arrayy
     */
    handleReadNotification(notificationObject, index) {
        readNotification(notificationObject._id).then(result => console.log(result))
        let newArray = this.state.notifications.slice()
        newArray[index].read = true
        this.setState(prevState => ({
            ...prevState,
            notificationCount: this.state.notificationCount - 1,
            notifications: newArray
        }))
    }

    /**
     * Method check the type of the notification and triggers the corresponding handler
     * @param {object} notificationObject - object of the notification
     * @param {number} index - index of the item in the arrayy
     */
    handleListItemClicked = (notificationObject, index) => {
        switch (notificationObject.type) {
            case 'GENERAL': {
                this.handleReadNotification(notificationObject, index)
                this.props.history.push("/")
                break;
            }
            case 'RIDE': {
                if (notificationObject.additionalInformation?.rideid) {
                    this.handleReadNotification(notificationObject, index)
                    this.props.history.push("/ridedetails/rideid=" + notificationObject.additionalInformation?.rideid)
                } else {
                    this.handleReadNotification(notificationObject, index)
                    this.props.history.push("/rides")
                }

                break;
            }
            case 'RATING': {
                this.handleReadNotification(notificationObject, index)
                this.props.history.push("/profil")
                break;
            }
            default: {
                break;
            }
        }
    }

    handleListButtonDisabled = (notificationObject) => {
        switch (notificationObject.type) {
            case 'GENERAL': {
                return true
            }
            case 'RIDE': {
                return false
            }
            case 'RATING': {
                return false
            }
            default: {
                return true
            }
        }
    }

    renderAppBarHeader() {
        return (
            <CustomDialogAppBar title="Benachrichtigungen" onClickHandler={() => this.handleNotificationOverviewOpenState(false)} />
        )
    }

    handleNotificationOverviewOpenState(open) {
        this.setState({
            notificationOverviewOpen: open
        })
    }

    render() {
        const renderNotiicationOVerview = () => {
            return (
                <React.Fragment>
                    <Dialog data-testid="notificationOverview-dialog" fullScreen open={this.state.notificationOverviewOpen} onClose={() => this.handleNotificationOverviewOpenState(false)} TransitionComponent={Transition}>
                        {this.renderAppBarHeader()}

                        <div className="notification-card">
                            {this.state.notifications.length === 0 ? (
                                <div className="r2r-display-flex r2r-justify-center notificationOverview-text">
                                    <Typography data-testid="notificationOverview-noContent" className="App  r2r-content-title" variant="h6">Du hast noch keine Benachrichtigungen erhalten</Typography>
                                </div>) : (
                                <CustomGrid>
                                    <List data-testid="notificationOverview-list" >
                                        {this.state.notifications.map((key, index) => (
                                            <React.Fragment key={key._id}>
                                                <ListItemButton disabled={this.handleListButtonDisabled(key)} key={key._id} onClick={() => this.handleListItemClicked(key, index)} alignItems="flex-start">
                                                    {/* <ListItemText className={key.read ? "" : "notification-unreadedMessage"} primary={key.title } secondary={key.message} /> */}
                                                    <ListItemText className={key.read ? "" : "notification-unreadedMessage"} primary={
                                                        <div className='r2r-display-flex r2r-justify-spaceBetween' >
                                                            <Typography className="" variant="h6" component="span">{key.title}</Typography>
                                                            <Typography className="notificationOverview-title-date" variant="body2" component="span" >{formattedDate(key.createdAt)} {formattedTime(key.createdAt)} Uhr</Typography>
                                                        </div>
                                                    } secondary={key.message} />
                                                </ListItemButton>
                                                {(index < this.state.notifications.length - 1) ? < Divider variant="fullWidth" component="li" /> : null}
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </CustomGrid>
                            )}
                        </div >
                    </Dialog>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.state.loading ? <LoadingTransparentBlocked /> :
                    <React.Fragment>
                        {renderNotiicationOVerview()}
                        <IconButton
                            id="notificationOverview-button"
                            data-testid="test-btn"
                            onClick={() => this.handleNotificationOverviewOpenState(true)}
                            color="inherit"
                            size="large">
                            <Badge id="notificationOverview-badge" data-testid="test-badge" badgeContent={this.state.notificationCount} color="secondary">
                                <Notifications />
                            </Badge>
                        </IconButton>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: {
            _id: state.user._id
        }
    };
};

export default connect(mapStateToProps, { setBottomNavState, setAppBarState })(NotificationOverview);