import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import deLocale from 'date-fns/locale/de';
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import { TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

function CustomDateTimePicker(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale} >
            <DateTimePicker
                {...props}
                label="Datum und Uhrzeit"
                ampm={false}
                value={props.value}
                onChange={props.onChange()}
                mask="__.__.____ __:__"

                renderInput={(params) => <TextField {...params} error={props.error} required variant="outlined" margin="normal" />}
            />
        </LocalizationProvider>
    )
}

CustomDateTimePicker.propTypes = {}

export default CustomDateTimePicker
