import HttpService from '../../../util/HttpService'
let _axios = HttpService.getAxiosClient()
const PATH = "user"

/**
 *Method requests the API to check the Mailadress of a user
 * @param {string} mail - mail adress of a user
 * @returns {Object} result of the API request
 */
export async function checkInitalMailadress(mail) {
    try {
        const response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/userByEmail?email=" + mail);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}


/**
 * Method requests the API th get all data of a user
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getUserData(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/userById/?id=" + userId)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get the ranking information of the user
 * @returns {Object} result of the API request
 */
export async function getRanking() {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/getStatRanking/")
        return response.data
    } catch (error) {
        console.error(error)
    }
}


/**
 * Method requests the API to get the stats of a user
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getUserStats(userId) {
    try {
        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/getUserStats/?userId=" + userId)
        return response.data
    } catch (error) {
        console.error(error)
    }
}


/**
 * Method requests the API to add a new user
 * @param {*} data 
 * @returns {Object} result of the API request
 */
export async function addUser(data) {
    try {

        let response = await _axios.post(process.env.REACT_APP_REST_URL + PATH + "/", data);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to change the acceptance state of the data protection guidelines
 * @param {string} userId - id of a user
 * @param {} acceptDsgvo - state of the dsgvo acceptance
 * @returns {Object} result of the API request
 */
export async function updateProtectionGuidelines(userId, acceptDsgvo) {
    try {
        const data = {
            userId: userId,
            acceptDsgvo: acceptDsgvo
        }

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/updateDataProtectionGuidelines', data);
        return response.data
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method requests the API to change the stats of a user
 * @param {string} userId - id of a user
 * @param {Number} newCo2Saveing - new co2 score
 * @returns {Object} result of the API request
 */
export async function updateStats(userId, newCo2Saveing, takenPersons, drivenWith) {
    try {
        let data = {
            userId: userId,
            co2Saving: newCo2Saveing,
            numberOfTakenPersons: takenPersons,
            numberOfDrivenWith: drivenWith,
        }

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/updateStats", data)
        return response.data
    } catch (error) {
        console.error(error)
    }

}

/**
 * Method requests the API to change data of a user
 * @param {string} userId - id of a user
 * @param {Object} data - object of changed data
 * @returns {Object} result of the API request
 */
export async function changeUserData(userId, data) {
    try {
        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + '/?id=' + userId, data)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to get all chats of a user
 * @param {string} userId - id of a user
 * @returns {Object} result of the API request
 */
export async function getChatsbyUserId(userId) {
    try {

        let response = await _axios.get(process.env.REACT_APP_REST_URL + PATH + "/chats/?id=" + userId)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

/**
 * Method requests the API to update the customizing of the user
 * @param {string} userId - id of a user
 * @param {string[]} panelIds - array of the panels ids
 * @returns {Object} result of the API request
 */
export async function udpateCustomizing(userId, panelIds) {
    try {

        let data = {
            userId: userId,
            panelIds: panelIds
        }

        let response = await _axios.put(process.env.REACT_APP_REST_URL + PATH + "/updateCustomizing", data)
        return response.data
    } catch (error) {
        console.error(error)
    }
}