import React from 'react'
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

function SectionTypography(props) {
    return (
        <Typography className={props.className + " r2r-dashboardpanel-title"} variant="h6">
            {props.children}
        </Typography>
    )
}

SectionTypography.propTypes = {

}



export default SectionTypography

