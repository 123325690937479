import React, { Component } from 'react';
import { connect } from 'react-redux'
import { SpaOutlined } from '@mui/icons-material/';
import { setSnackbar } from '../../Actions/Index.js'
import { Typography, Card, CardContent, CardHeader } from '@mui/material';
import { getUserStats } from '../../API/REST/index'
import SectionTypography from '../Global/sectionTypography/sectionTypography';
import './EnvironmentalPanel.scss';
import { messages } from '../../util/toastMessages.js';

function CardContentTypography(props) {
    return (
        <div className="environmentalPanel-cardcontent-font">
            <Typography id={props.id} variant="body1" {...props} >{props.children}</Typography>
        </div >
    )
}

class EnvironmentalPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            loading: true
        }
    }

    componentDidMount() {
        this.requestData()
    }

    async requestData() {
        try {
            let data = await getUserStats(this.props.userId)
            switch (data.response.code) {
                case 200:
                    this.setState({
                        loading: false,
                        stats: data.result.data.stats
                    })
                    break;
                default:
                    this.setState({
                        loading: false,
                        stats: {
                            co2Saving: 0,
                            numberOfDrivenWith: 0,
                            numberOfTakenPersons: 0,
                            points: 0
                        }
                    })
                    break;
            }
        } catch (error) {
            this.props.setSnackbar(messages.error, true, "error")
            console.error(error)
            this.setState({
                loading: false,
                stats: {
                    co2Saving: 0,
                    numberOfDrivenWith: 0,
                    numberOfTakenPersons: 0,
                    points: 0
                }
            })
        }
    }

    /**
     * Method calculates the total score
     */
    calculatePoints() {
        return ((this.state.stats.numberOfTakenPersons * 10 * 0.1) + (this.state.stats.numberOfDrivenWith * 20 * 0.3) + (this.state.stats.co2Saving * 0.6))
    }

    render() {
        return (
            <div className="r2r-contentContainer-element-margin">
                <Card className="r2r-dashboard-card-margin">
                    <CardHeader title={<SectionTypography>Dein Umweltbericht</SectionTypography>} />
                    <CardContent >
                        <div className="r2r-container-content-flex-row r2r-container-center-align">
                            <SpaOutlined className="environmentalPanel-eco-icon" />
                            {/* <SavingsOutlined className="environmentalPanel-eco-icon" /> */}
                            <Typography id="environmentalPanel-points" data-testid="test-points" className="" variant="h4">{Math.trunc(this.state.stats.points) + " Punkte"}</Typography>
                        </div>
                        <div className="r2r-display-flex r2r-justify-spaceBetween environmentalPanel-alignContent-center environmentalPanel-cardcontent-margin">
                            <CardContentTypography id="environmentalPanel-numberOfTakenPersons" data-testid="test-numberOfTakenPersons">{this.state.stats.numberOfTakenPersons + " Personen mitgenommen"}</CardContentTypography>
                            <CardContentTypography id="environmentalPanel-co2Saving" >{this.state.stats.co2Saving + "g CO2/km gespart!"}</CardContentTypography>
                        </div>
                        <div className="r2r-display-flex r2r-justify-spaceBetween environmentalPanel-cardcontent-flex-center ">
                            <CardContentTypography id="environmentalPanel-numberOfDrivenWith" data-testid="test-numberOfDrivenWith">{this.state.stats.numberOfDrivenWith + " mal mitgefahren"}</CardContentTypography>
                            <CardContentTypography> {/*<Typography variant="body1" >{"Rang 100"}</Typography>*/}</CardContentTypography>
                        </div>
                    </CardContent>
                    {/* <Button onClick={() => { this.props.setSnackbar("TEST", true, "success") }}>asdasd</Button> */}
                    {/* <CardActions className="environmentalPanel-cardaction-link">
                        <Link
                            size="small"
                            component={Button}
                            className="r2r-color-secondary"
                            color="secondary"
                            underline={"none"}
                            target="_blank"
                            variant="body2"
                            href={"https://www.hs-pforzheim.de/"} >
                            Punkte einlösen
                        </Link>
                    </CardActions> */}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.user._id
    };
};

export default connect(mapStateToProps, { setSnackbar })(EnvironmentalPanel);