import React, { Component } from 'react';
import { Typography, LinearProgress, Card, CardContent, CardHeader } from '@mui/material';
import { Warning } from '@mui/icons-material/';
import './ParkingSpotPanel.scss';
import SectionTypography from '../Global/sectionTypography/sectionTypography';

class ParkingSpotPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressValue: 19,
        }
    }

    render() {
        return (
            <div className="r2r-contentContainer-element-margin">
                <Card className="r2r-dashboard-card-margin">
                    <CardHeader title={<SectionTypography>Parksituation an der Hochschule</SectionTypography>} />
                    <CardContent  >
                        <div className="parkingSpotPanel-progressBar-container">
                            <LinearProgress className="parkingSpotPanel-progressBar" variant="determinate" color="primary" value={this.state.progressValue} />
                        </div>

                        <div className="r2r-display-flex r2r-justify-center">
                            <div className="parkingSpotPanel-cardcontent-font">
                                <Typography style={{ color: 'white' }} variant="body1" >{this.state.progressValue <= 20 ? <Warning className="parkingSpotPanel-warning-icon" /> : this.state.progressValue <= 50 ? "Weniger als 50 Parkplätze frei" : this.state.progressValue <= 75 ? "Zwischen 50 und 75 Parkplätze frei" : "Mehr als 75 Parkplätze frei"}</Typography>
                            </div>
                            <div className="parkingSpotPanel-cardcontent-font">
                                <Typography style={{ color: 'red' }} variant="body1" >{this.state.progressValue <= 20 ? "Weniger als 20 Parkplätze frei" : this.state.progressValue <= 50 ? "Weniger als 50 Parkplätze frei" : this.state.progressValue <= 75 ? "Zwischen 50 und 75 Parkplätze frei" : "Mehr als 75 Parkplätze frei"}</Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default ParkingSpotPanel;
