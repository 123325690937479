export const setKeycloak = (data) => {
    return {
        type: 'SETKEYCLOAK',
        payload: data
    }
}

export const setUser = (data) => {
    return {
        type: 'SETUSER',
        payload: data
    }
}

export const setDataProtection = (dsgvoAccept) => {
    return {
        type: 'SETDATAPROTECTION',
        payload: dsgvoAccept
    }
}

export const setBottomNavState = (navState) => {
    return {
        type: 'SETBOTTOMNAV',
        payload: navState
    }
}

export const setAppBarState = (appBarValue, additionalData) => {
    return {
        type: 'SETAPPBARSTATE',
        payload: { appBarValue, additionalData }
    }
}

export const setSnackbar = (message, open, severity) => {
    return {
        type: 'SETSNACKBAR',
        payload: { message, open, severity }
    }
}

